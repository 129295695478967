import { Component, Input } from '@angular/core';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { ProfileSubscriptionElement } from 'projects/core/src/lib/mappers/subscription.mapper';

@Component({
  selector: 'lib-further-subscriptions',
  templateUrl: './further-subscriptions.component.html',
})
export class FurtherSubscriptionsComponent {
  @Input() fields: ProfileSubscriptionElement[];

  readonly ICONS = {
    circleExclamation: faCircleExclamation,
  };

  get activeFields(): ProfileSubscriptionElement[] {
    return this.fields?.filter((field) => field.active) || [];
  }

  get inactiveFields(): ProfileSubscriptionElement[] {
    return this.fields?.filter((field) => !field.active) || [];
  }
}
