import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { RenderTextMode } from 'ng2-pdf-viewer';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { LanguageService } from 'projects/core/src/lib/services/language.service';
import { loadPdfViewer } from 'projects/core/src/lib/utils/external-libs-loader.utils';

@Component({
  selector: 'lib-terms-of-service-viewer',
  templateUrl: './terms-of-service-viewer.component.html',
  styleUrls: ['./terms-of-service-viewer.component.scss'],
  host: { class: 'like-ion-content large-modal' },
})
export class TermsOfServiceViewerComponent implements OnInit {
  @Input() showHeader = false;
  @Output() loading = new EventEmitter<boolean>();
  isLoading = true;
  renderTextMode: typeof RenderTextMode = RenderTextMode;
  zoomProperties = {
    backgroundColor: 'rgba(0,0,0,0)',
  };
  url: string;
  consolidatedAvb = false;

  icons = {
    close: faXmark,
  };

  constructor(
    private clientService: ClientConfigService,
    private modalController: ModalController,
    private languageService: LanguageService,
  ) {}

  async ngOnInit() {
    await loadPdfViewer();
    const clientConfig: ClientConfig = this.clientService.get();
    if (clientConfig) {
      this.url = clientConfig.termsOfServiceConfig.getI18nPdfUrl(
        this.languageService.getCurrentLanguageCode(),
      );
      this.consolidatedAvb = clientConfig.consolidatedAvb;
    }
  }

  getRowAmount(): any[] {
    const offsetHeader = 42;
    const rowHeight = 29;
    const rowAmount = Math.ceil((window.innerHeight - offsetHeader) / rowHeight);
    return new Array(rowAmount);
  }

  afterLoadComplete(): void {
    this.isLoading = false;
    this.loading.emit(this.isLoading);
  }

  async close(): Promise<boolean> {
    return this.modalController.dismiss({}, 'close');
  }
}
