import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { mergeMap, of, throwError } from 'rxjs';
import { AlertMessagesData } from '../data/alert-messages.data';
import { ServerCheckerError } from '../data/errors.data';
import { AlertMessage } from '../models/alert.model';
import { ObjectType, ServerCheckerResult } from '../models/sdapi-object.model';
import { AlertService } from '../services/alert.service';

export const ServerCheckerInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const alertService: AlertService = inject(AlertService);
  return next(req).pipe(
    mergeMap((response: any) => {
      if (response.body?.['t'] === ObjectType.serverCheckerResult) {
        const serverCheckerResult: ServerCheckerResult = response.body as ServerCheckerResult;
        const messages = serverCheckerResult?.items?.map((item) => item.msg).join('\n');
        const alertMessage: AlertMessage = {
          header: AlertMessagesData.validationError.header,
          message: messages,
        };
        console.error('ServerCheckerInterceptor ServerCheckerResult', serverCheckerResult);
        void alertService.presentAlert(alertMessage).catch(console.error);
        return throwError(() => new ServerCheckerError(serverCheckerResult));
      }
      return of(response);
    }),
  );
};
