@if (isModalSheet) {
  <lib-modal-header
    [titleIcon]="icons.filter"
    [title]="'general.filter' | translate"
    [style]="'view'"
    [hasVerticalPadding]="false">
  </lib-modal-header>
}

<lib-data-organizer-toolbox
  class="stretch-self"
  [filterSegmentCollection]="filterSegmentCollection">
  <lib-horizontal-sorting-section
    horizontalSorting
    [rowState]="rowState"
    [header]="table.sortedHeader"
    [headerReordering]="headerReordering"></lib-horizontal-sorting-section>

  <lib-vertical-sorting-section verticalSorting [table]="table"></lib-vertical-sorting-section>
</lib-data-organizer-toolbox>
