@if (isModalSheet) {
  <lib-modal-header
    [titleIcon]="'filter'"
    [title]="'general.filter' | translate"
    [style]="'view'"
    [hasVerticalPadding]="false"></lib-modal-header>
}

<lib-data-organizer-toolbox class="stretch-self" [filterSegmentCollection]="segmentCollection">
  <lib-laboratory-results-vertical-sorting
    verticalSorting
    [tableData]="tableData"></lib-laboratory-results-vertical-sorting>

  <lib-laboratory-results-filtering
    filtering
    [tableData]="tableData"></lib-laboratory-results-filtering>
</lib-data-organizer-toolbox>
