import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Invoker, InvokerReferences } from 'projects/core/src/lib/models/invoker-body.model';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { BehaviorSubject, firstValueFrom, Observable, of, throwError, zip } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { DynamicFormMapper } from '../mappers/dynamic-form.mapper';
import { ProfileResourceMapper } from '../mappers/profile.mapper';
import { SDAPIObjectMapper } from '../mappers/sdapi-object.mapper';
import {
  ProfileSubscriptionElement,
  SubscriptionResourceMapper,
} from '../mappers/subscription.mapper';
import { DynamicDataField, DynamicForm } from '../models/form.model';
import { InvokerMethods } from '../models/invoker-body.model';
import { Profile, ProfileFieldKey } from '../models/profile.model';
import { TieFormObject } from '../models/sdapi-form-object.model';
import { SDAPIMenuObject } from '../models/sdapi-object.model';
import { TieTableObjectList } from '../models/sdapi-table-object.model';
import { ClientConfigService } from './client-config.service';
import { ProfileFormService } from './profile-form.service';
import { SDAPIService } from './sdapi.service';

@Injectable()
export class ProfileService {
  private readonly refreshCurrentProfile = new BehaviorSubject<boolean>(false);

  profileSubscriptionElements: ProfileSubscriptionElement[];

  constructor(
    private http: HttpClient,
    private dynamicFormMapper: DynamicFormMapper,
    private sdapiService: SDAPIService,
    private clientConfigService: ClientConfigService,
    private profileFormService: ProfileFormService,
  ) {}

  public getCurrentProfile(): Observable<Profile> {
    return this.refreshCurrentProfile.pipe(switchMap(() => this.fetchProfile()));
  }

  public getProfileEditForm(): Observable<DynamicForm> {
    return this.getUserObjId().pipe(
      concatMap((id: number) =>
        this.sdapiService.getInvokerByMethodName(
          id.toString(),
          InvokerMethods.objectAttributesEdit,
        ),
      ),
      concatMap((invoker: Invoker) =>
        zip(this.http.put<TieFormObject>(invoker.activityURL, invoker.invoker), of(invoker)),
      ),
      map(([response, invoker]) => {
        const activityURL: string = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
        return this.dynamicFormMapper.mapDynamicFormResource(response, activityURL);
      }),
    );
  }

  public async getMappedAndFilteredProfileEditForm(
    fieldKeys: ProfileFieldKey[],
    groupIndex: number,
  ): Promise<DynamicForm> {
    const form: DynamicForm = await firstValueFrom(this.getProfileEditForm());
    const filteredForm: DynamicForm = this.getFilteredForm(form, fieldKeys, groupIndex);
    const mappedForm = this.mapFieldGridValues(filteredForm);
    return mappedForm;
  }

  private mapFieldGridValues(form: DynamicForm): DynamicForm {
    form.body.forEach((group: DynamicDataField) => {
      group.fieldGroup.forEach((field: DynamicDataField, index) => {
        field.rowNum = index + 2;
        field.colSpan = 1;
        field.colNum = 1;
      });
    });
    return form;
  }

  private getFilteredForm(form: DynamicForm, fieldKeys, groupIndex): DynamicForm {
    form.body = this.profileFormService.filterFormFields(form, fieldKeys, groupIndex);
    form.body = this.removeEmptyFieldGroups(form);
    return form;
  }

  private removeEmptyFieldGroups(form: DynamicForm): DynamicDataField[] {
    return form.body.filter((group: DynamicDataField) => group.fieldGroup.length);
  }

  public getUserDeactivationForm(): Observable<DynamicForm> {
    return this.getUserObjId().pipe(
      concatMap((id: number) =>
        this.sdapiService.getInvokerByMethodName(id.toString(), InvokerMethods.userDeactivate),
      ),
      concatMap((invoker: Invoker) =>
        zip(this.http.put<TieFormObject>(invoker.activityURL, invoker.invoker), of(invoker)),
      ),
      map(([response, invoker]) => {
        const activityURL: string = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
        return this.dynamicFormMapper.mapDynamicFormResource(response, activityURL);
      }),
      catchError(() => of(undefined)),
    );
  }

  private fetchProfile(): Observable<Profile> {
    return this.getUserObjId().pipe(
      concatMap((id: number) => {
        if (id === -1) {
          return of(new Profile());
        } else {
          return this.getProfileDetails(id);
        }
      }),
      catchError((error) => {
        console.error(`Could not fetch current profile`, error);
        return throwError(() => error);
      }),
    );
  }

  private getUserMenu(): Observable<SDAPIMenuObject> {
    return this.http.get<SDAPIMenuObject>('/objects/USER');
  }

  public getUserObjId(): Observable<number> {
    return this.getUserMenu().pipe(
      map((response: SDAPIMenuObject) => response.subMenus[0].invokers[0].objId),
    );
  }

  private getProfileDetails(id: number): Observable<Profile> {
    return this.sdapiService
      .getInvokerByMethodName(id.toString(), InvokerMethods.objectAttributesView)
      .pipe(
        concatMap((invoker: Invoker) =>
          zip(this.http.put<TieFormObject>(invoker.activityURL, invoker.invoker), of(invoker)),
        ),
        map(([response, invoker]) => {
          const activityURL: string = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
          const profileForm: DynamicForm = this.dynamicFormMapper.mapDynamicFormResource(
            response,
            activityURL,
            false,
            true,
          );
          return ProfileResourceMapper.mapResource(profileForm, id);
        }),
      );
  }

  async getSubscriptionManagementInvoker(): Promise<Invoker> {
    const userId = await firstValueFrom(this.getUserObjId());
    const invoker = await firstValueFrom(
      this.sdapiService.getInvokerByMethodName(
        userId.toString(),
        InvokerMethods.userManageSubscriptions,
      ),
    );
    return invoker;
  }

  async getSubscriptionManagementForm(invoker: Invoker): Promise<TieFormObject> {
    const subscriptionManagementForm: TieFormObject = await firstValueFrom(
      this.http.put<TieFormObject>(invoker.activityURL, invoker.invoker, {
        headers: CustomHttpHeaders.XNoCacheHeaders,
      }),
    );
    return subscriptionManagementForm;
  }

  async getProfileSubscriptionElements(): Promise<ProfileSubscriptionElement[]> {
    try {
      const invoker = await this.getSubscriptionManagementInvoker();
      const subscriptionManagementForm: TieFormObject =
        await this.getSubscriptionManagementForm(invoker);
      return SubscriptionResourceMapper.mapResource(subscriptionManagementForm);
    } catch (error) {
      console.warn('Could not fetch profile subscription elements', error);
    }
    return [];
  }

  async updateProfileSubscriptionElements(): Promise<void> {
    this.profileSubscriptionElements = await this.getProfileSubscriptionElements();
  }

  async saveSubscriptionManagementForm(profileSubscriptionElements: ProfileSubscriptionElement[]) {
    const invoker = await this.getSubscriptionManagementInvoker();
    const subscriptionManagementForm: TieFormObject =
      await this.getSubscriptionManagementForm(invoker);
    const invokerToSave = SubscriptionResourceMapper.mapProfileSubscriptionElementsToInvoker(
      invoker,
      subscriptionManagementForm,
      profileSubscriptionElements,
    );
    const activityURL = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
    await firstValueFrom(
      this.http.put<TieTableObjectList>(`${activityURL}/step`, invokerToSave.invoker),
    );
  }

  async fetchRequestProfileChangeForm(): Promise<DynamicForm> {
    try {
      const invokers: Invoker[] = await firstValueFrom(
        this.sdapiService.extractInvokerFromUserMenuByReference('USER', [
          InvokerReferences.requestProfileChange,
        ]),
      );
      if (!invokers.length) {
        return undefined;
      }
      const invoker = invokers[0];
      const response = await firstValueFrom(
        this.http.put<TieFormObject>(invoker.activityURL, invoker.invoker),
      );
      const activityURL: string = SDAPIObjectMapper.mapActivityPath(invoker.invoker);
      return this.dynamicFormMapper.mapDynamicFormResource(response, activityURL);
    } catch (error) {
      console.error('Failed to fetch profile change request form:', error);
      return undefined;
    }
  }
}
