<ng-template #sectionTitle>
  @if (shouldShowSectionTitle) {
    <lib-auto-layout class="section-title" direction="vertical" [padding]="[10, 20]">
      <h3 class="ion-no-margin">{{ 'shared.treatments.documents' | translate }}</h3>
    </lib-auto-layout>
  }
</ng-template>

@if (!this.isLoading && treatment) {
  @if (treatment.documents.length) {
    <cdk-virtual-scroll-viewport
      #scrollViewPort
      itemSize="58"
      [minBufferPx]="virtualScrollService.getBufferPx('min')"
      [maxBufferPx]="virtualScrollService.getBufferPx('max')"
      class="stretch-flex stretch-self">
      <ng-container *ngTemplateOutlet="sectionTitle"></ng-container>

      @if (treatment.documents.length) {
        <div *cdkVirtualFor="let document of treatment.documents" class="document-row">
          <lib-document-row
            [document]="document"
            [hasExtendedView]="false"
            (documentListReload)="documentListReload.emit()">
          </lib-document-row>
        </div>
      }
    </cdk-virtual-scroll-viewport>
  }

  @if (!treatment.documents.length) {
    <ng-container *ngTemplateOutlet="sectionTitle"></ng-container>
    <lib-notification-box-component
      [grow]="1"
      [message]="'shared.treatments.no-documents' | translate">
    </lib-notification-box-component>
  }

  @if (treatment.hasDocumentUpload) {
    <div class="upload-section">
      <ion-button
        (click)="loadAndOpenUploadModal()"
        color="primary"
        class="p3"
        expand="block"
        class="ion-no-margin">
        {{ 'shared.treatments.upload-documents' | translate }}
        <fa-icon slot="end" [icon]="icons.upload"></fa-icon>
      </ion-button>
    </div>
  }
} @else {
  <ng-container *ngTemplateOutlet="sectionTitle"></ng-container>
  <lib-documents-skeleton
    class="skeleton-animation"
    [lines]="maxRowAmountOfDocumentSection"
    [hasExtendedView]="false">
  </lib-documents-skeleton>
}
