import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';

export const patternMappings = {
  de: {
    shortFullDate: 'dd.MM.yyyy',
  },
  en: {
    shortFullDate: 'dd/MM/yyyy',
  },
  fr: {
    shortFullDate: 'dd/MM/yyyy',
  },
  it: {
    shortFullDate: 'dd/MM/yyyy',
  },
};
@Pipe({
  name: 'localeDate',
  pure: false,
})
export class LocaleDatePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private datePipe: DatePipe,
  ) {}

  transform(value: any, pattern: string = 'mediumDate') {
    if (!value) {
      return null;
    }
    let mappedPattern = patternMappings[this.translateService.currentLang]?.[pattern] ?? pattern;

    const tzValue = dayjs(value).tz().format('YYYY-MM-DDTHH:mm:ss');

    const timezonedDateFormatted = this.datePipe.transform(
      tzValue,
      mappedPattern,
      undefined,
      this.translateService.currentLang,
    );

    return timezonedDateFormatted;
  }
}
