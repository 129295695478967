<ion-content>
  <ion-grid
    class="content fill-height stretch-container"
    [fixed]="true"
    [class.full-width]="fullWidth">
    <ion-row class="stretch-flex stretch-container">
      <ion-col class="stretch-flex-force stretch-container">
        <div class="stretch-flex stretch-container card-transparent">
          <lib-patient-details-header [viewPatient]="patient"></lib-patient-details-header>

          <ng-content></ng-content>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
