import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PatientService } from 'projects/core/src/lib/services/patient.service';
import { VirtualScrollService } from 'projects/core/src/lib/services/virtual-scroll.service';
import { MenuItem } from 'projects/shared/src/lib/models/menu.model';
import { PatientMenuService } from 'projects/shared/src/lib/services/patient-menu.service';
import { PatientMenuKeys } from './patient-menu.model';

@Component({
  selector: 'lib-patient-summary-list',
  templateUrl: './patient-summary-list.component.html',
  styleUrls: ['./patient-summary-list.component.scss'],
})
export class PatientSummaryListComponent implements OnInit {
  readonly rowHeight: number = 70;

  patientID: string;
  isLoading = true;
  menuItems: MenuItem[];

  constructor(
    public virtualScrollService: VirtualScrollService,
    public patientMenuService: PatientMenuService,
    private router: Router,
    private patientService: PatientService,
  ) {}

  async ngOnInit() {
    this.patientID = await this.patientService.getCurrentPatientId();
    this.menuItems = await this.patientMenuService.constructPatientMenu(this.patientID);
    this.isLoading = false;
  }

  async navigateToMenuItem(item: MenuItem): Promise<void> {
    if (item.idSuffix === PatientMenuKeys.setShow) {
      await this.patientMenuService.openPatientAccessGrantingModal(this.patientID);
    } else {
      await this.router.navigate([item.url]);
    }
  }
}
