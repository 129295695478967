<lib-modal-header
  [titleIcon]="icons.edit"
  [title]="'shared.profile.further-subscriptions' | translate"
  [style]="modalHeaderStyle"></lib-modal-header>

<div class="content-container">
  <ion-list class="card-list items">
    <ng-container>
      @for (field of profileSubscriptionElements; track field.internalValue) {
        <ion-item class="item-line padding-top-bottom-10" lines="none">
          <ion-toggle [(ngModel)]="field.active">{{ field.displayValue }}</ion-toggle>
        </ion-item>
      }
    </ng-container>
  </ion-list>
</div>

<ion-footer>
  <lib-auto-layout horizontal="end" [padding]="[10]" class="button-section">
    <ion-button
      expand="block"
      color="primary"
      class="p3"
      [class.stretch-self]="breakpoint.isBelow('md')"
      (click)="saveFurtherSubscriptions()">
      <fa-icon size="lg" slot="start" [icon]="icons.save"></fa-icon>
      <span>{{ 'general.save' | translate }}</span>
    </ion-button>
  </lib-auto-layout>
</ion-footer>
