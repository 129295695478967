<ion-list class="popover-item-list">
  @for (item of menu; track $index) {
    <ion-item
      button
      lines="none"
      (click)="handleMenuItemSelection(item)"
      [routerLink]="item.url"
      routerDirection="root"
      routerLinkActive="active-link">
      <div class="icon-container">
        <fa-icon slot="start" [icon]="item.icon"></fa-icon>
      </div>

      <p class="p">
        <strong>{{ item.title }}</strong>
      </p>
    </ion-item>
  }
</ion-list>
