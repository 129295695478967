<lib-modal-header
  [titleIcon]="ICONS.title"
  [title]="'shared.appointment-booking.appointment-booking' | translate"></lib-modal-header>

<ion-content *ngIf="bookingQueue">
  <div class="content-container">
    <div class="form-container">
      <form #f="ngForm">
        <ng-container *ngFor="let step of bookingQueue.past; let index = index; let first = first">
          <booking-modal-result-row
            [step]="step"
            [index]="index"
            [bookingQueue]="bookingQueue"
            [isFirst]="first"
            [editable]="stepIsEditable(step)"></booking-modal-result-row>
        </ng-container>

        <booking-modal-form-input [bookingQueue]="bookingQueue">
          <div class="button-section">
            <ng-container *ngTemplateOutlet="navigationButtons"></ng-container>
          </div>
        </booking-modal-form-input>
      </form>
    </div>

    <div class="spacer ion-hide-md-up" *ngIf="!bookingQueue.isLast">
      <booking-modal-timeline-indicator [isSpacer]="true"></booking-modal-timeline-indicator>
    </div>

    <booking-modal-upcomming-list [bookingQueue]="bookingQueue"></booking-modal-upcomming-list>
  </div>
</ion-content>

<ng-template #navigationButtons>
  <lib-button-container>
    <ion-button
      color="tertiary"
      expand="full"
      (click)="bookingQueue.back()"
      [disabled]="!bookingQueue.past">
      <fa-icon [icon]="ICONS.back" slot="start"></fa-icon>
      {{ 'general.back' | translate }}
    </ion-button>
    <ion-button
      color="primary"
      expand="full"
      (click)="bookingQueue.isLast ? sendAppointment() : bookingQueue.next()"
      [disabled]="!requiredFulfilled">
      @if (bookingQueue.isLast) {
        {{ 'general.send' | translate }}
      } @else {
        {{ 'general.continue' | translate }}
        <fa-icon [icon]="ICONS.next" slot="end"></fa-icon>
      }
    </ion-button>
  </lib-button-container>
</ng-template>
