import { Component, Input, OnDestroy } from '@angular/core';
import {
  dynamicTableFilterSegments,
  FilterSegmentCollection,
  horizontalSortingSegment,
  TableHeaderItem,
  TableList,
  TableRowState,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { TableDataService } from 'projects/core/src/lib/services/table-data.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-data-organizer-trigger-button',
  templateUrl: './data-organizer-trigger-button.component.html',
  styleUrl: './data-organizer-trigger-button.component.scss',
})
export class DataOrganizerTriggerButtonComponent implements OnDestroy {
  @Input({ required: true }) table: TableList;
  @Input({ required: true }) rowState: TableRowState;
  @Input() isLoading: boolean;
  @Input() isSearchActive: boolean;

  private modal: HTMLIonModalElement;
  private popover: HTMLIonPopoverElement;
  private headerReordering: BehaviorSubject<TableHeaderItem[]>;

  constructor(private readonly tableDataService: TableDataService) {}

  ngOnDestroy(): void {
    this.tableDataService.dismissActiveModalSheet(this.rowState, this.modal);
    this.tableDataService.dismissActivePopover(this.rowState, this.popover);
  }

  async toggleFilterSheet(): Promise<void> {
    this.headerReordering = new BehaviorSubject(this.table.sortedHeader);
    await this.handleFilterPopupDisplay();
    this.headerReordering.subscribe((headerReordered: TableHeaderItem[]) => {
      this.onHeaderReordering(headerReordered, this.table.sortedHeader);
    });
  }

  async handleFilterPopupDisplay(): Promise<void> {
    if (this.rowState.isDesktopView) {
      void this.presentPopover();
    } else {
      void this.presentModalSheet();
    }
  }

  private async presentModalSheet(): Promise<void> {
    this.modal = await this.tableDataService.presentDataOrganizerModalSheet(
      this.table,
      this.rowState,
      this.headerReordering,
      this.filterSegmentCollection,
    );
    await this.modal.present();
  }

  private async presentPopover(): Promise<void> {
    this.popover = await this.tableDataService.presentDataOrganizerPopover(
      this.table,
      this.rowState,
      this.headerReordering,
      this.filterSegmentCollection,
    );
    this.popover.keepContentsMounted = true;
    await this.popover.present();
  }

  private onHeaderReordering(headerReordered: TableHeaderItem[], header: TableHeaderItem[]): void {
    if (headerReordered !== header) {
      this.table.sortedHeader = headerReordered;
      this.storeUpdatedHeaderValuesAndSortColumns(headerReordered);
    }
  }

  private async storeUpdatedHeaderValuesAndSortColumns(header: TableHeaderItem[]): Promise<void> {
    this.table.sortColumnsHorizontally(header);
    this.tableDataService.saveColumnPreferences(header, this.table);
  }

  private get filterSegmentCollection(): FilterSegmentCollection[] {
    return this.isSearchActive ? horizontalSortingSegment : dynamicTableFilterSegments;
  }
}
