<div
  class="form-input toolbar-item"
  [ngClass]="{
    'dirty-input': isInputDirty,
    'card-layout': hasCardLayout,
  }">
  <div class="search-field" [grow]="1">
    <input
      #searchInput
      type="text"
      [placeholder]="placeholder || ('shared.search-toolbar.please-enter' | translate)"
      [attr.title]="placeholder || ('shared.search-toolbar.please-enter' | translate)"
      [attr.aria-label]="'shared.search-toolbar.please-enter' | translate"
      [(ngModel)]="searchParams.criterions"
      (ngModelChange)="onCriterionChange(searchParams.criterions)"
      [disabled]="disabled && !searchInputDirty" />

    @if (searchParams.criterions) {
      <fa-icon class="icon-delete" (click)="deleteSearchInput()" [icon]="icons.delete"></fa-icon>
    }
  </div>

  <button
    type="button"
    class="search-button"
    [disabled]="disabled && !searchInputDirty"
    [attr.title]="'shared.search-toolbar.search' | translate"
    [attr.aria-label]="'shared.search-toolbar.search' | translate">
    <fa-icon class="icon-search" [icon]="icons.search"></fa-icon>
  </button>

  <ng-content select="[filterButton]"></ng-content>
</div>
