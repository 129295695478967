<ion-content scrollY="true">
  <div class="filter-container stretch-container">
    <div class="segment-buttons-container stretch-container">
      <lib-auto-layout [padding]="[10]">
        <ion-segment [(ngModel)]="activeFilterSection">
          @for (segment of segmentCollection; track $index) {
            <ion-segment-button [value]="segment.value">
              <ion-label>{{ segment.labelKey | translate }}</ion-label>
            </ion-segment-button>
          }
        </ion-segment>
      </lib-auto-layout>
    </div>

    <ng-container [ngSwitch]="activeFilterSection">
      <ng-container *ngSwitchCase="filterSectionType.horizontalSorting">
        <ng-content select="[horizontalSorting]"></ng-content>
      </ng-container>
      <ng-container *ngSwitchCase="filterSectionType.verticalSorting">
        <ng-content select="[verticalSorting]"></ng-content>
      </ng-container>
      <ng-container *ngSwitchCase="filterSectionType.filtering">
        <ng-content select="[filtering]"></ng-content>
      </ng-container>
    </ng-container>
  </div>
</ion-content>
