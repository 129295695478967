import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, concatMap, map, mergeMap, Observable, of, throwError, zip } from 'rxjs';
import { APIError } from '../data/errors.data';
import { DataFormMapper } from '../mappers/data-form.mapper';
import { DynamicFormMapper } from '../mappers/dynamic-form.mapper';
import { FormMapper } from '../mappers/form.mapper';
import { AutocompleteOptions, DynamicDataField, DynamicForm } from '../models/form.model';
import {
  Invoker,
  InvokerBody,
  InvokerMethods,
  InvokerReferences,
  InvokerTypes,
} from '../models/invoker-body.model';
import { TieFormObject } from '../models/sdapi-form-object.model';
import { SDAPIMenuObject, SDAPIResponseObject } from '../models/sdapi-object.model';
import {
  AutocompleteOptionsUpdateBody,
  OptionsUpdate,
  OptionsUpdateBody,
} from '../models/sdapi-options.model';
import { TieTableObjectList } from '../models/sdapi-table-object.model';
import { SDAPIObjectMapper } from './../mappers/sdapi-object.mapper';
import { CustomHttpHeaders } from './custom-http-headers';
import { FormService } from './form.service';

@Injectable()
export class SDAPIService {
  constructor(
    private http: HttpClient,
    private formService: FormService,
    private dynamicFormMapper: DynamicFormMapper,
    private dataFormMapper: DataFormMapper,
  ) {}

  public getInvokerByMethodName(
    objectName: string,
    method: InvokerMethods,
    httpHeaders: HttpHeaders = new HttpHeaders(),
  ): Observable<Invoker> {
    return this.http
      .get<SDAPIMenuObject>(`/objects/${objectName}`, { headers: httpHeaders })
      .pipe(
        map(
          (response: SDAPIMenuObject): Invoker =>
            this.retrieveConstructedInvokerFromMenuByMethod(method, response),
        ),
      );
  }

  public extractInvokerFromUserMenuByReference(
    objectName: string,
    references: InvokerReferences[],
    httpHeaders: HttpHeaders = new HttpHeaders(),
  ): Observable<Invoker[]> {
    return this.http
      .get<SDAPIMenuObject>(`/objects/${objectName}`, { headers: httpHeaders })
      .pipe(
        map((response: SDAPIMenuObject): Invoker[] =>
          this.retrieveConstructedInvokerFromMenuByReference(references, response),
        ),
      );
  }

  public retrieveConstructedInvokerFromMenuByReference(
    reference: InvokerReferences[],
    response: SDAPIMenuObject,
  ): Invoker[] {
    const invokerBody: InvokerBody[] = SDAPIObjectMapper.getInvokerBodyByReferenceName(
      reference,
      response,
    );
    return invokerBody.map((invoker) => this.getConstructedInvoker(invoker));
  }

  public checkForInvokerByMethodName(
    objectName: string,
    method: InvokerMethods,
    headers?: HttpHeaders,
  ): Observable<boolean> {
    const options: { headers?: HttpHeaders } = {};
    if (headers) {
      options.headers = headers;
    }
    return this.http.get<SDAPIMenuObject>(`/objects/${objectName}`, options).pipe(
      map(
        (response: SDAPIMenuObject): Invoker =>
          this.retrieveConstructedInvokerFromMenuByMethod(method, response),
      ),
      map(() => true),
      catchError(() => of(false)),
    );
  }

  public getInvokerListByMethodName(
    objectName: string,
    method: InvokerMethods,
  ): Observable<Invoker[]> {
    return this.http.get<SDAPIMenuObject>(`/objects/${objectName}`).pipe(
      map((response: SDAPIMenuObject): Invoker[] => {
        const invokerBodyList: InvokerBody[] = SDAPIObjectMapper.getInvokerBodiesByMethodName(
          method,
          response,
        );
        return invokerBodyList.map((invokerBody: InvokerBody) =>
          this.getConstructedInvoker(invokerBody),
        );
      }),
    );
  }

  public retrieveConstructedInvokerFromMenuByMethod(
    method: InvokerMethods,
    response: SDAPIMenuObject,
  ): Invoker {
    const invokerBody: InvokerBody = SDAPIObjectMapper.getInvokerBodyByMethodName(method, response);
    return this.getConstructedInvoker(invokerBody);
  }

  public retrieveConstructedInvokersIfPresentFromMenu(
    methods: InvokerMethods[],
    response: SDAPIMenuObject,
    references: InvokerReferences[],
  ): Invoker[] {
    const invokerBodies: InvokerBody[] = methods.flatMap((method: InvokerMethods) =>
      this.tryGetInvokerBodiesByMethodName(method, response),
    );
    const invokerBodiesByReference: InvokerBody[] = references.flatMap(
      (reference: InvokerReferences) =>
        SDAPIObjectMapper.getInvokerBodyByReferenceName([reference], response),
    );
    const irrelevantInvokers: InvokerBody[] = response.path?.invokers;
    const relevantInvokers: InvokerBody[] = this.filterIrrelevantInvokers(
      invokerBodies,
      irrelevantInvokers,
    );
    return relevantInvokers
      .concat(invokerBodiesByReference)
      .map((invokerBody: InvokerBody) => this.getConstructedInvoker(invokerBody));
  }

  /**
   * @deprecated: A temporary solution for filtering out the invokers for an action bar
   */
  private filterIrrelevantInvokers(
    invokerBodies: InvokerBody[],
    irrelevantInvokers: InvokerBody[],
  ): InvokerBody[] {
    if (!irrelevantInvokers?.length) {
      return invokerBodies;
    }

    return invokerBodies.filter(
      (invokerBody: InvokerBody) =>
        !this.getSetOfIrrelevantInvokerPaths(irrelevantInvokers).has(
          `${invokerBody.objId}-${invokerBody.activityId}-${invokerBody.parentId}`,
        ),
    );
  }

  /**
   * @deprecated: To be removed alongside filterIrrelevantInvokers method
   */
  private getSetOfIrrelevantInvokerPaths(irrelevantInvokers: InvokerBody[]): Set<string> {
    return new Set(
      irrelevantInvokers.map((invoker: InvokerBody) =>
        SDAPIObjectMapper.getInvokerBodyIdentifier(invoker),
      ),
    );
  }

  private tryGetInvokerBodiesByMethodName(
    invokerMethod: InvokerMethods,
    response: SDAPIMenuObject,
  ): InvokerBody[] {
    try {
      return SDAPIObjectMapper.getInvokerBodiesByMethodName(invokerMethod, response);
    } catch (error) {
      return [];
    }
  }

  public getConstructedInvoker(invokerBody: InvokerBody): Invoker {
    return {
      type: InvokerTypes.INVOKE_METHOD,
      activityURL: '/invoke-method',
      invoker: invokerBody,
    } as Invoker;
  }

  public invokeMethod<T>(invoker: InvokerBody, headers?: HttpHeaders): Observable<T> {
    return this.http.put<T>('/invoke-method', invoker, { headers });
  }

  public getPreferredInvokerOrDefault(
    objectName: number,
    preferredMethod: InvokerMethods,
    defaultMethod: InvokerMethods,
  ): Observable<Invoker> {
    return this.http.get<SDAPIMenuObject>(`/objects/${objectName}`).pipe(
      map((response: SDAPIMenuObject): Invoker => {
        const invokerBody: InvokerBody = SDAPIObjectMapper.retrievePreferredInvokerBodyOrDefault(
          preferredMethod,
          defaultMethod,
          response,
        );
        return this.getConstructedInvoker(invokerBody);
      }),
    );
  }

  public getResponseFromStepInvoker<T>(invoker: Invoker, headers?: HttpHeaders): Observable<T> {
    if (!invoker.activityURL || invoker.activityURL?.includes('undefined')) {
      throw new APIError("Can't resolve Invoker URL.");
    }

    return this.http.put<T>(`${invoker.activityURL}/step`, invoker.invoker, { headers });
  }

  public getFormByMethodFromObjectMenu(
    invokerMethod: InvokerMethods,
    objectMenuUrl: string,
    isPublicRequest?: boolean,
    skipInvokers?: boolean,
  ): Observable<DynamicForm> {
    let headers: HttpHeaders;
    if (isPublicRequest) {
      headers = CustomHttpHeaders.XPublicUserRequestHeaders;
    } else {
      headers = new HttpHeaders();
    }
    return this.http.get<SDAPIMenuObject>(objectMenuUrl, { headers }).pipe(
      map((response: SDAPIMenuObject) =>
        SDAPIObjectMapper.getInvokerBodyByMethodName(invokerMethod, response),
      ),
      concatMap((invoker) => zip(of(invoker), this.invokeMethod<TieFormObject>(invoker, headers))),
      map(([invoker, response]) => {
        const isInteractive: boolean = invokerMethod !== InvokerMethods.objectAttributesView;
        return this.dynamicFormMapper.mapDynamicFormResource(
          response,
          SDAPIObjectMapper.mapActivityPath(invoker),
          isInteractive,
          skipInvokers,
          isPublicRequest,
        );
      }),
      catchError((error) => throwError(() => error)),
    );
  }

  getOptionsForAutoCompleteField(
    autocompleteOptions: AutocompleteOptions,
    currentSearch: string = '',
    isPublicRequest: boolean = false,
  ): Observable<OptionsUpdate> {
    const headersArray = [CustomHttpHeaders.XNoCache];
    if (isPublicRequest) {
      headersArray.push(CustomHttpHeaders.XPublicUserRequest);
    }
    const headers = CustomHttpHeaders.build(...headersArray);

    const body: AutocompleteOptionsUpdateBody = {
      t: 'GetAutocompleteOptionsUpdate',
      currentDisplayValue: currentSearch,
      stmtId: autocompleteOptions.popupObjId,
      maxOptions: autocompleteOptions.maxoptions ?? 1000,
      minPrefixLength: autocompleteOptions.minprefixlength,
      params: autocompleteOptions.optionsUpdateParameter ?? {},
    };

    return this.http.put<OptionsUpdate>(
      `${autocompleteOptions.activityUrl}/autocompleteOptions`,
      body,
      {
        headers,
      },
    );
  }

  public getOptionsUpdate(
    popUpId: number,
    parameter: any,
    activityURL: string,
    isPublicRequest?: boolean,
  ): Observable<HttpEvent<OptionsUpdate>> {
    let headers;
    if (isPublicRequest) {
      headers = {
        headers: CustomHttpHeaders.XPublicUserRequestHeaders,
      };
    }
    const body: OptionsUpdateBody = {
      t: 'GetOptionsUpdate',
      stmtId: popUpId,
      maxOptions: 1000,
      params: parameter,
    };
    return this.http.put<OptionsUpdate>(`${activityURL}/options`, body, headers).pipe(
      map((response) => response),
      catchError((error) => throwError(() => error)),
    );
  }

  public getIdOfFirstObjlistItemByStepInvoker(dynamicForm: DynamicForm): Observable<number> {
    const invoker = dynamicForm.invoker.find((i: Invoker) =>
      [InvokerTypes.SAVE, InvokerTypes.STEP0, InvokerTypes.SEARCH].includes(i.type),
    );
    let headers: CustomHttpHeaders;
    if (dynamicForm.isPublicAccessed) {
      headers = {
        headers: CustomHttpHeaders.XPublicUserRequestHeaders,
      };
    }
    if (invoker) {
      return this.http
        .put<TieTableObjectList>(
          `${invoker.activityURL}/step`,
          FormMapper.insertDynamicFormItemsToInvokerBody(dynamicForm.body, invoker),
          headers,
        )
        .pipe(map((result: TieTableObjectList) => result.items[0].objId));
    } else {
      throw new APIError(`No StepInvoker of type 'SAVE', 'STEP0' or 'SEARCH'.`);
    }
  }

  public sendByStepInvoker(dynamicForm: DynamicForm): Observable<SDAPIResponseObject> {
    const invoker = this.getStepInvoker(dynamicForm);
    return this.http.put<SDAPIResponseObject>(
      `${invoker.activityURL}/step`,
      FormMapper.insertDynamicFormItemsToInvokerBody(dynamicForm.body, invoker),
    );
  }

  public getStepInvoker(dynamicForm: DynamicForm): Invoker {
    try {
      return dynamicForm.invoker.find((invoker: Invoker) =>
        [InvokerTypes.SAVE, InvokerTypes.STEP0, InvokerTypes.SEARCH].includes(invoker.type),
      );
    } catch (error) {
      throw new APIError('No StepInvoker of type SAVE, STEP0 or SEARCH.', error);
    }
  }

  public getMetaFinderForm(
    invoker: Invoker,
    httpHeaders: HttpHeaders = new HttpHeaders(),
  ): Observable<DynamicForm> {
    return this.http
      .put<TieFormObject>(invoker.activityURL, invoker.invoker, { headers: httpHeaders })
      .pipe(
        map(
          (tieFormObject: TieFormObject): DynamicForm =>
            this.dataFormMapper.mapDataFormResource(
              tieFormObject,
              SDAPIObjectMapper.mapActivityPath(invoker.invoker),
            ),
        ),
      );
  }

  public fillDynamicFormAndGetResponseObjectList<T>(
    dynamicForm: DynamicForm,
    dataMap: Map<string, string>,
    httpHeaders: HttpHeaders = new HttpHeaders(),
  ): Observable<T> {
    dynamicForm.body.forEach(
      (field: DynamicDataField) =>
        (field.value.value = dataMap.get(field.identifier.originalValue)),
    );
    return this.formService.saveForm<T>(dynamicForm, false, httpHeaders);
  }

  public findDataObjectWithMetaFinder<T>(
    objectName: string,
    invokerMethod: InvokerMethods,
    dataMap: Map<string, string>,
    httpHeaders: HttpHeaders = new HttpHeaders(),
  ): Observable<T> {
    return this.getInvokerByMethodName(objectName, invokerMethod, httpHeaders).pipe(
      concatMap((invoker: Invoker) => this.getMetaFinderForm(invoker, httpHeaders)),
      concatMap((form: DynamicForm) =>
        this.fillDynamicFormAndGetResponseObjectList<T>(form, dataMap, httpHeaders),
      ),
    );
  }

  public constructListOfSupportedActionInvokers(response: SDAPIMenuObject): Invoker[] {
    const methods: InvokerMethods[] = [
      InvokerMethods.objectAttributesEdit,
      InvokerMethods.objectAttributesView,
      InvokerMethods.objectConstraintObjectList,
      InvokerMethods.objectCreate,
      InvokerMethods.objectCreateAutoImport,
      InvokerMethods.objectDelete,
      InvokerMethods.objectObjectList,
      InvokerMethods.objectView,
      InvokerMethods.projectDelete,
      InvokerMethods.objectCommit,
      InvokerMethods.objectDistribute,
      InvokerMethods.objectUpdate,
    ];
    const references: InvokerReferences[] = [InvokerReferences.signProcessStart];
    return this.retrieveConstructedInvokersIfPresentFromMenu(methods, response, references);
  }

  public fetchPrimaryAndActionInvokers(
    id: string,
    invoker?: Invoker,
    invokerMethod?: InvokerMethods,
  ): Observable<[Invoker, Invoker[]]> {
    return this.http.get<SDAPIMenuObject>(`/objects/${id}`).pipe(
      mergeMap((response: SDAPIMenuObject) => {
        const primaryInvoker: Invoker =
          invoker ?? this.retrieveConstructedInvokerFromMenuByMethod(invokerMethod, response);
        const actionInvokers: Invoker[] = this.retrieveActionInvokerList(response, primaryInvoker);
        return zip(of(primaryInvoker), of(actionInvokers));
      }),
    );
  }

  public retrieveActionInvokerList(response: SDAPIMenuObject, primaryInvoker: Invoker): Invoker[] {
    const actionInvokers: Invoker[] = this.constructListOfSupportedActionInvokers(response);
    return this.getFilteredActionInvokerList(primaryInvoker, actionInvokers);
  }

  public getFilteredActionInvokerList(
    primaryInvoker: Invoker,
    actionInvokers: Invoker[],
  ): Invoker[] {
    const primaryInvokerIdentifier: string = SDAPIObjectMapper.getInvokerBodyIdentifier(
      primaryInvoker.invoker,
    );
    return actionInvokers?.filter((actionInvoker: Invoker) => {
      const actionInvokerIdentifier: string = SDAPIObjectMapper.getInvokerBodyIdentifier(
        actionInvoker.invoker,
      );
      return actionInvokerIdentifier !== primaryInvokerIdentifier;
    });
  }
}
