import { AttributeValue } from './sdapi-form-object.model';

export interface Invoker {
  type: InvokerTypes;
  activityURL?: string;
  invoker: InvokerBody;
}

export interface InvokerBody {
  t: 'StepInvoker' | 'Invoker';
  parameters?: FormAttributeCollection;
  methodName?: InvokerMethods;
  objId?: number;
  activityId?: number;
  methodId?: number;
  parentId?: number;
  reference?: InvokerReferences;
  presentation?: InvokerPresentation;
  [key: string]: any;
}

export interface FormAttributeCollection {
  [key: string]: AttributeValue;
}

export interface InvokerPresentation {
  t: 'ImageSource';
  label?: string | null;
  tooltip?: string | null;
  italic?: boolean | null;
  uri?: string | null;
  overlayUri?: string | null;
}

export enum InvokerTypes {
  SAVE = 'Speichern',
  SEND = 'Senden',
  SEARCH = 'Suchen',
  STEP0 = 'Step0',
  INVOKE_METHOD = 'Invoke-Method',
}

export enum InvokerMethods {
  constraintObjectList = 'Constraint.Objlist()',
  objectConstraintObjectList = 'Object.ConstraintObjList()',
  objectCreate = 'Object.Create()',
  objectAttributesView = 'Object.Attributes.View()',
  objectAttributesEdit = 'Object.Attributes.Edit()',
  objectView = 'Object.View()',
  objectFindInObjectList = 'Object.FindInObjlist()',
  objectCreateAutoImport = 'Object.CreateAutoImport()',
  objectSetShow = 'Object.SetShow()',
  objectCommit = 'Object.Commit()',
  visitCreate = 'Visit.Create()',
  objectDistribute = 'Object.Distribute()',
  finderReport = 'Finder.Report()',
  objectObjectList = 'Object.ObjList()',
  appointmentCreate = 'Appointment.Create()',
  userDeactivate = 'User.Deactivate()',
  objectUpdate = 'Object.Update()',
  objectOpenUrl = 'Object.OpenURL()',
  userPropertiesView = 'User.Properties.View()',
  userPropertiesEdit = 'User.Properties.Edit()',
  objectDelete = 'Object.Delete()',
  projectEdit = 'Project.Edit()',
  projectDelete = 'Project.Delete()',
  medicalDocumentCreate = 'MedicalDocument.Create()',
  patientCreate = 'Patient.Create()',
  patientObservationResultsView = 'Patient.ObservationResultView()',
  userManageSubscriptions = 'User.ManageSubscriptions()',
  serviceCall = 'Service.Call()',
}

export enum InvokerReferences {
  restDocImport = 'RESTDOCIMPORT',
  documentsList = 'SHOW_DOCS',
  notesList = 'NOTESHOW',
  createNote = 'NOTECREATE',
  portalIdentStart = 'PORTAL_IDENT_START',
  portalIdentRestart = 'PORTAL_IDENT_RESTART',
  portalIdentPending = 'PORTAL_IDENT_PENDING',
  signProcessStart = 'SIGN_PROCESS_START',
  requestProfileChange = 'REQUEST_PROFILE_CHANGE',
}
