import { Component, OnInit } from '@angular/core';
import { faArrowRight, faFile } from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AccessDeniedError } from 'projects/core/src/lib/data/errors.data';
import { DocumentDetails } from 'projects/core/src/lib/models/documents.model';
import { DocumentsService } from 'projects/core/src/lib/services/documents.service';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'lib-documents-widget',
  templateUrl: './documents.component.html',
})
@UntilDestroy()
export class DocumentsWidgetComponent implements OnInit {
  readonly desktopView: boolean = false;
  documents: DocumentDetails[] = [];
  skeletonItemsNumber: number = 3;
  isLoading: boolean = true;
  isDataAccessRestricted: boolean = false;

  private filterTranslations: Object;

  icons = {
    document: faFile,
    arrow: faArrowRight,
  };

  constructor(
    private readonly documentsService: DocumentsService,
    private readonly translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.filterTranslations = await this.getFilterTranslations();
    this.initializeDocumentsList();
  }

  private async getFilterTranslations(): Promise<any> {
    return await firstValueFrom(this.translateService.get('shared.documents.filter-names'));
  }

  public initializeDocumentsList(): void {
    this.isLoading = true;
    this.documentsService
      .getDocumentsList(this.filterTranslations)
      .pipe(untilDestroyed(this), take(1))
      .subscribe({
        next: (documentsList: DocumentDetails[]) => {
          documentsList.sort(
            (a: DocumentDetails, b: DocumentDetails) => b.date.getTime() - a.date.getTime(),
          );
          this.documents = documentsList.slice(0, 3);
        },
        error: (error) => {
          this.isDataAccessRestricted = error instanceof AccessDeniedError;
        },
      })
      .add(() => {
        this.isLoading = false;
      });
  }
}
