import { Injectable } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { PatientTableDetailsViewerComponent } from 'projects/shared/src/lib//components/patient-data/patient-table-details-viewer/patient-table-details-viewer.component';
import { BookingModalComponent } from 'projects/shared/src/lib/components/appointment-booking/booking-modal/booking-modal.component';
import { PublicBookingModalComponent } from 'projects/shared/src/lib/components/appointment-booking/public-booking-modal/public-booking-modal.component';
import { DataExchangeFormComponent } from 'projects/shared/src/lib/components/data-exchange-form/data-exchange-form.component';
import { DynamicFormFlexibleLayoutComponent } from 'projects/shared/src/lib/components/form/dynamic-form-flexible-layout/dynamic-form-flexible-layout.component';
import { LaboratoryNotesModalComponent } from 'projects/shared/src/lib/components/laboratory-results/laboratory-results-table/laboratory-notes/laboratory-notes-modal.component';
import { LanguageSwitchComponent } from 'projects/shared/src/lib/components/language-switch/language-switch.component';
import { AutoIdentIframeComponent } from 'projects/shared/src/lib/components/modals/auto-ident-iframe/auto-ident-iframe.component';
import { AutoIdentInformationComponent } from 'projects/shared/src/lib/components/modals/auto-ident-information/auto-ident-information.component';
import { DocumentViewerComponent } from 'projects/shared/src/lib/components/modals/document-viewer/document-viewer.component';
import {
  DynamicTableConfiguration,
  DynamicTableSelectComponent,
} from 'projects/shared/src/lib/components/modals/dynamic-table-select/dynamic-table-select.component';
import { FurtherSubscriptionsUpdateComponent } from 'projects/shared/src/lib/components/modals/further-subscriptions-update/further-subscriptions-update.component';
import { GenericTableComponent } from 'projects/shared/src/lib/components/modals/generic-table/generic-table.component';
import { GraphDataViewerComponent } from 'projects/shared/src/lib/components/modals/graph-data-viewer/graph-data-viewer.component';
import { RequiredActionInfoOverviewComponent } from 'projects/shared/src/lib/components/modals/required-action-info-overview/required-action-info-overview.component';
import { RequiredActionSwitchComponent } from 'projects/shared/src/lib/components/modals/required-action-switch/required-action-switch.component';
import { TableSelectComponent } from 'projects/shared/src/lib/components/modals/table-select/table-select.component';
import { TermsOfServiceViewerComponent } from 'projects/shared/src/lib/components/terms-of-service-viewer/terms-of-service-viewer.component';
import { firstValueFrom, throwError } from 'rxjs';
import { ProfileSubscriptionElement } from '../mappers/subscription.mapper';
import { DataField } from '../models/data-exchange.model';
import { DocumentDetails } from '../models/documents.model';
import { DynamicButton } from '../models/dynamic-button.model';
import { TableList } from '../models/dynamic-table.model';
import { DynamicForm, DynamicFormConfiguration, DynamicFormType } from '../models/form.model';
import { GraphDataSet } from '../models/graph.model';
import { Invoker } from '../models/invoker-body.model';
import {
  DocumentTranslationKeys,
  TranslationOptions,
  TreatmentCreationTranslationKeys,
  TreatmentSharingTranslationKeys,
  UserDeactivationTranslationKeys,
} from '../models/modal-action.model';
import { PatientDataField } from '../models/patient.model';
import { RequiredActionsDetails } from '../models/required-actions.model';
import { ObjectType } from '../models/sdapi-object.model';
import { AutoIdentService } from './auto-ident.service';
import { LoadingService } from './loading.service';
import { SDAPIService } from './sdapi.service';

@Injectable()
export class PopupService {
  constructor(
    private modalController: ModalController,
    private popoverController: PopoverController,
    private alertController: AlertController,
    private loadingService: LoadingService,
    private sdapiService: SDAPIService,
    private autoIdentService: AutoIdentService,
  ) {}

  async showFurtherSubscriptionsUpdateModal(
    profileSubscriptionElements: ProfileSubscriptionElement[],
  ): Promise<OverlayEventDetail> {
    try {
      await this.loadingService.stop();
      PopupService.addBrowserHistoryEntry();
      const modal = await this.modalController.create({
        component: FurtherSubscriptionsUpdateComponent,
        componentProps: { profileSubscriptionElements },
        backdropDismiss: false,
        cssClass: 'dynamic-modal',
      });
      await modal.present();

      const eventDetail: OverlayEventDetail = await modal.onDidDismiss();
      PopupService.removeBrowserHistoryEntry();
      return eventDetail;
    } finally {
      await this.loadingService.stop();
    }
  }

  async showAutoIdentInformationModal(autoIdentInvoker: Invoker): Promise<void> {
    PopupService.addBrowserHistoryEntry();

    const modal = await this.modalController.create({
      component: AutoIdentInformationComponent,
      backdropDismiss: false,
      cssClass: 'dynamic-modal',
    });

    await modal.present();
    const informationModalResult: OverlayEventDetail = await modal.onDidDismiss();
    PopupService.removeBrowserHistoryEntry();

    if (informationModalResult.role === OverlayEventRole.activityChange) {
      const result: any = await firstValueFrom(
        this.sdapiService.invokeMethod(autoIdentInvoker.invoker),
      );
      if (result.t === ObjectType.bulkServerResponse) {
        const url = result.bulk.find((item: any) => item.t === ObjectType.urlView)?.url;
        if (url) {
          await this.showAutoIdentIframeModal(url);
        }
      } else {
        await this.showAutoIdentIframeModal(result.url);
      }
    }
  }

  async showAutoIdentIframeModal(url: string) {
    PopupService.addBrowserHistoryEntry();
    const modal = await this.modalController.create({
      component: AutoIdentIframeComponent,
      backdropDismiss: false,
      cssClass: 'dynamic-modal',
      componentProps: {
        url,
      },
    });
    await modal.present();
    const dismissalResult = await modal.onDidDismiss();
    const modalClosedWithHardwareButton = !dismissalResult.role;
    if (modalClosedWithHardwareButton) {
      await this.autoIdentService.setAutoIdentStatusUpdating();
    }
    PopupService.removeBrowserHistoryEntry();
  }

  async showNewTreatmentModal(viewLink: string): Promise<OverlayEventDetail> {
    const translationOptions: TranslationOptions = {
      keys: TreatmentCreationTranslationKeys,
      successMessageKey: 'create-completion',
      actionInProgressKey: 'saving-in-progress',
    };
    return this.showDynamicFormModal(
      new DynamicFormConfiguration({
        type: DynamicFormType.TREATMENT_CREATE,
        activityURL: viewLink,
        translationOptions,
      }),
    );
  }

  async showUserDeactivationModal(form: DynamicForm): Promise<OverlayEventDetail> {
    const translationOptions: TranslationOptions = {
      keys: UserDeactivationTranslationKeys,
      successMessageKey: 'save-completion',
      actionInProgressKey: 'saving-in-progress',
    };
    return this.showDynamicFormModal(
      new DynamicFormConfiguration({
        type: DynamicFormType.USER_DEACTIVATION,
        dynamicForm: form,
        translationOptions,
      }),
    );
  }

  async showUploadModal(form: DynamicForm): Promise<any> {
    const translationOptions: TranslationOptions = {
      keys: DocumentTranslationKeys,
      successMessageKey: 'upload-completion',
      actionInProgressKey: 'upload-in-progress',
    };
    return this.showDynamicFormModal(
      new DynamicFormConfiguration({
        type: DynamicFormType.DOCUMENT_UPLOAD,
        dynamicForm: form,
        translationOptions,
      }),
    );
  }

  async showShareTreatmentWithDoctorModal(viewLink: string): Promise<any> {
    const translationOptions: TranslationOptions = {
      keys: TreatmentSharingTranslationKeys,
      successMessageKey: 'save-completion',
      actionInProgressKey: 'saving-in-progress',
    };
    return this.showDynamicFormModal(
      new DynamicFormConfiguration({
        type: DynamicFormType.TREATMENT_CREATE,
        activityURL: viewLink,
        translationOptions,
      }),
    );
  }

  async showDocumentModal(documentDetails: DocumentDetails): Promise<OverlayEventDetail> {
    PopupService.addBrowserHistoryEntry();
    await this.loadingService.stop();
    if (!documentDetails.viewLink && !documentDetails.externalOpenLink) {
      throw new Error(
        `Document with id ${documentDetails.id} does not have a viewLink or externalOpenLink.`,
      );
    }
    const documentModal = await this.modalController.create({
      component: DocumentViewerComponent,
      componentProps: {
        document: documentDetails,
      },
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'dynamic-modal',
    });
    return new Promise((resolve) => {
      documentModal
        .onDidDismiss()
        .then((response: OverlayEventDetail) => resolve(response))
        .catch((error) => throwError(() => new Error(error)));
      documentModal.present();
    });
  }

  /** @deprecated */
  async showDataExchangeModal(list: DataField[]): Promise<DataField[]> {
    PopupService.addBrowserHistoryEntry();

    const dataExchangeModal = await this.modalController.create({
      component: DataExchangeFormComponent,
      componentProps: { list },
      backdropDismiss: false,
      cssClass: 'modal-appointment',
    });

    dataExchangeModal.onDidDismiss().then(PopupService.removeBrowserHistoryEntry);

    await dataExchangeModal.present();

    const { data, role } = await dataExchangeModal.onWillDismiss();
    if (role === 'confirm') {
      return data;
    } else {
      return [];
    }
  }

  async showDynamicFormModal(configuration: DynamicFormConfiguration): Promise<OverlayEventDetail> {
    await this.loadingService.stop();
    PopupService.addBrowserHistoryEntry();

    const dynamicFormModal = await this.modalController.create({
      component: DynamicFormFlexibleLayoutComponent,
      componentProps: {
        configuration,
      },
      backdropDismiss: false,
      cssClass: 'dynamic-modal',
    });
    await dynamicFormModal.present();
    const response: OverlayEventDetail = await dynamicFormModal.onDidDismiss();
    return response;
  }

  async showTableSelectModal(
    tableList: TableList,
    title: string = 'Auswahl',
    actionButton?: DynamicButton,
  ): Promise<OverlayEventDetail> {
    await this.loadingService.stop();
    PopupService.addBrowserHistoryEntry();
    const modal = await this.modalController.create({
      component: TableSelectComponent,
      componentProps: {
        tableList,
        title,
        actionButton,
      },
      backdropDismiss: false,
      cssClass: 'dynamic-modal',
    });
    await modal.present();
    const response: OverlayEventDetail = await modal.onDidDismiss();
    if (response.role !== OverlayEventRole.actionButtonEvent) {
      PopupService.removeBrowserHistoryEntry();
    }
    return response;
  }

  async showDynamicTableSelectModal(
    dynamicTableConfiguration: DynamicTableConfiguration,
  ): Promise<OverlayEventDetail> {
    await this.loadingService.stop();
    return new Promise((resolve, reject) => {
      PopupService.addBrowserHistoryEntry();

      const showTableSelect = async () => {
        const modal = await this.modalController.create({
          component: DynamicTableSelectComponent,
          componentProps: {
            dynamicTableConfiguration,
          },
          backdropDismiss: false,
          cssClass: 'dynamic-modal',
        });
        modal
          .onDidDismiss()
          .then((response: OverlayEventDetail) => {
            resolve(response);
            PopupService.removeBrowserHistoryEntry();
          })
          .catch(reject);
        return modal.present();
      };

      showTableSelect();
    });
  }

  async showTableDataModal(table: TableList): Promise<OverlayEventDetail> {
    await this.loadingService.stop();
    PopupService.addBrowserHistoryEntry();
    const modal = await this.modalController.create({
      component: GenericTableComponent,
      componentProps: { table },
      backdropDismiss: false,
      cssClass: 'dynamic-modal',
    });
    return new Promise((resolve, reject) => {
      modal
        .onDidDismiss()
        .then((response: OverlayEventDetail) => resolve(response))
        .catch(reject);
      modal.present();
    });
  }

  async showRequiredActionSwitchModal(
    requiredAction: RequiredActionsDetails,
  ): Promise<OverlayEventDetail> {
    PopupService.addBrowserHistoryEntry();

    const modal = await this.modalController.create({
      component: RequiredActionSwitchComponent,
      componentProps: { requiredAction },
      backdropDismiss: false,
      cssClass: 'dynamic-modal',
    });
    return new Promise((resolve, reject) => {
      modal
        .onDidDismiss()
        .then((response: OverlayEventDetail) => resolve(response))
        .catch(reject);
      modal.present();
    });
  }

  async showRequiredActionInfoOverviewModal(
    requiredAction: RequiredActionsDetails,
  ): Promise<OverlayEventDetail> {
    PopupService.addBrowserHistoryEntry();

    const requiredActionModal = await this.modalController.create({
      component: RequiredActionInfoOverviewComponent,
      componentProps: {
        requiredAction,
      },
      backdropDismiss: false,
      cssClass: 'dynamic-modal',
    });

    return new Promise((resolve, reject) => {
      requiredActionModal
        .onDidDismiss()
        .then((response: OverlayEventDetail) => resolve(response))
        .catch(reject);
      requiredActionModal.present();
    });
  }

  async showTosModal(): Promise<void> {
    PopupService.addBrowserHistoryEntry();

    const tosModal = await this.modalController.create({
      component: TermsOfServiceViewerComponent,
      componentProps: {
        showHeader: true,
      },
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'dynamic-modal',
    });
    tosModal
      .onDidDismiss()
      .then(PopupService.removeBrowserHistoryEntry)
      .catch((error) => throwError(() => new Error(error)));
    return tosModal.present();
  }

  async showPatientData(list: PatientDataField[]): Promise<void> {
    PopupService.addBrowserHistoryEntry();
    const modal = await this.modalController.create({
      component: PatientTableDetailsViewerComponent,
      componentProps: {
        tableList: list,
      },
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'small-modal',
    });
    modal
      .onDidDismiss()
      .then(PopupService.removeBrowserHistoryEntry)
      .catch((error) => throwError(() => new Error(error)));
    return modal.present();
  }

  async showAppointmentBookingModal(
    form: DynamicForm,
    backOnDismiss: boolean = true,
    createId?: string,
  ): Promise<OverlayEventDetail> {
    PopupService.addBrowserHistoryEntry();
    await this.loadingService.stop();
    const modal = await this.modalController.create({
      component: BookingModalComponent,
      componentProps: {
        form,
        createId,
      },
      backdropDismiss: false,
      cssClass: 'dynamic-modal',
    });
    return new Promise((resolve, reject) => {
      modal
        .onDidDismiss()
        .then((response: OverlayEventDetail) => {
          if (backOnDismiss) {
            PopupService.removeBrowserHistoryEntry();
          }
          return resolve(response);
        })
        .catch(reject);
      modal.present();
    });
  }

  async showPublicAppointmentBookingModal(
    form: DynamicForm,
    backOnDismiss: boolean = true,
  ): Promise<OverlayEventDetail> {
    PopupService.addBrowserHistoryEntry();
    await this.loadingService.stop();
    const modal = await this.modalController.create({
      component: PublicBookingModalComponent,
      componentProps: {
        form,
      },
      backdropDismiss: false,
      cssClass: 'dynamic-modal',
    });
    return new Promise((resolve, reject) => {
      modal
        .onDidDismiss()
        .then((response: OverlayEventDetail) => {
          if (backOnDismiss) {
            PopupService.removeBrowserHistoryEntry();
          }
          return resolve(response);
        })
        .catch(reject);
      modal.present();
    });
  }

  async showAppointmentUpdateModal(
    configuration: DynamicFormConfiguration,
  ): Promise<OverlayEventDetail> {
    await this.loadingService.stop();

    PopupService.addBrowserHistoryEntry();
    const modal = await this.modalController.create({
      component: DynamicFormFlexibleLayoutComponent,
      componentProps: {
        configuration,
      },
      backdropDismiss: true,
      cssClass: 'dynamic-modal',
    });
    return new Promise((resolve, reject) => {
      modal
        .onDidDismiss()
        .then((response) => resolve(response))
        .catch(reject);
      modal.present();
    });
  }

  async showLaboratoryNotesPopover(notes: string[]): Promise<void> {
    await this.loadingService.stop();

    PopupService.addBrowserHistoryEntry();
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: LaboratoryNotesModalComponent,
      componentProps: {
        notes,
      },
      backdropDismiss: false,
      cssClass: 'dynamic-modal',
    });
    await modal.present();
    await modal.onDidDismiss();
  }

  async showGraphModal(dataSet: GraphDataSet, title: string): Promise<void> {
    await this.loadingService.stop();

    PopupService.addBrowserHistoryEntry();
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: GraphDataViewerComponent,
      componentProps: { dataSet, title },
      backdropDismiss: true,
      cssClass: 'dynamic-modal',
    });

    await modal.present();
    await modal.onDidDismiss();
  }

  async showLanguageSwitchPopover(): Promise<string> {
    const popover = await this.popoverController.create({
      component: LanguageSwitchComponent,
      trigger: `language-switch-trigger`,
      cssClass: 'language-switch-popover',
      arrow: false,
      alignment: 'center',
    });

    await popover.present();

    return (await popover.onDidDismiss()).data;
  }

  private static addBrowserHistoryEntry() {
    if (!history.state?.modal) {
      const modalState = { modal: true };
      history.pushState(modalState, null);
    }
  }

  private static removeBrowserHistoryEntry() {
    if (history.state?.modal) {
      history.back();
    }
  }

  async dismissTopOverlayModal(): Promise<void> {
    try {
      const modalElement = await this.modalController.getTop();
      if (modalElement) {
        await modalElement.dismiss();
        return;
      }
    } catch (error) {
      console.warn('Modal could not be dismissed.', error);
    }
  }

  async dismissTopOverlayPopover(): Promise<void> {
    try {
      const popoverElement = await this.popoverController.getTop();
      if (popoverElement) {
        await popoverElement.dismiss();
        return;
      }
    } catch (error) {
      console.warn('Popover could not be dismissed.', error);
    }
  }

  async dismissTopOverlayAlert(): Promise<void> {
    try {
      const alertElement = await this.alertController.getTop();
      if (alertElement) {
        await alertElement.dismiss();
        return;
      }
    } catch (error) {
      console.warn('Alert could not be dismissed.', error);
    }
  }
}

export enum OverlayEventRole {
  save = 'save',
  commit = 'commit',
  activityChange = 'activityChange',
  actionButtonEvent = 'actionButtonEvent',
  cancel = 'cancel',
}
