import { ServerCheckerResult } from './../models/sdapi-object.model';

export class APIError extends Error {
  parentError?: any;

  constructor(message?: string, parentError?: any) {
    super(message || 'API-, Mapping- oder Authentifikationsfehler.');
    this.name = 'API Error';
    this.parentError = parentError;
  }
}

export class UIError extends Error {
  parentError?: any;

  constructor(message?: string, parentError?: any) {
    super(message || 'UI Error.');
    this.name = 'UI Error';
    this.parentError = parentError;
  }
}

export class UserError extends Error {
  parentError?: any;

  constructor(message?: string, parentError?: any) {
    super(message || 'User Error.');
    this.name = 'User Error';
    this.parentError = parentError;
  }
}

export class ProcessError extends Error {
  parentError?: any;

  constructor(message?: string, parentError?: any) {
    super(message || 'Process Error.');
    this.name = 'Process Error';
    this.parentError = parentError;
  }
}

export class PostMappingError extends Error {
  parentError?: any;

  constructor(message?: string, parentError?: any) {
    super(message || 'PostMapping Error.');
    this.name = 'PostMapping Error';
    this.parentError = parentError;
  }
}

export class ServerCheckerError extends Error {
  serverCheckerResult: ServerCheckerResult;

  constructor(serverCheckerResult: ServerCheckerResult) {
    super('ServerChecker Error.');
    this.name = 'ServerChecker Error';
    this.serverCheckerResult = serverCheckerResult;
  }
}

export class AccessDeniedError extends Error {
  parentError?: any;

  constructor(message?: string, parentError?: any) {
    super(message || 'Patient data not available or data access restricted.');
    this.name = 'AccessDenied Error';
    this.parentError = parentError;
  }
}
