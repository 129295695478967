<lib-modal-header [title]="table.title"></lib-modal-header>

<ion-content [scrollY]="false">
  <lib-auto-layout direction="vertical" vertical="stretch" horizontal="stretch" class="fill-height">
    <lib-auto-layout direction="horizontal" vertical="stretch" horizontal="stretch" [grow]="1">
      @if (table.header?.length) {
        @if (isExpandedView) {
          <lib-auto-layout
            direction="vertical"
            [padding]="[10, 20]"
            class="information-section"
            [ngClass]="informationSideBarClass">
            <ng-container *ngTemplateOutlet="information"></ng-container>
          </lib-auto-layout>
        } @else {
          <div class="stretch-self stretch-container">
            <lib-auto-layout [padding]="[10, 10, 0, 10]" class="segment-button-container">
              <ion-segment [(ngModel)]="activeSegment" swipe-gesture="false">
                <ion-segment-button [value]="segment.list">
                  <ion-label class="p3">{{ 'shared.tables.list' | translate }}</ion-label>
                </ion-segment-button>
                <ion-segment-button [value]="segment.information">
                  <ion-label class="p3">{{ 'shared.tables.information' | translate }}</ion-label>
                </ion-segment-button>
              </ion-segment>
            </lib-auto-layout>

            <ng-container [ngSwitch]="activeSegment">
              <ng-container *ngSwitchCase="segment.list">
                <ng-container *ngTemplateOutlet="list"></ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="segment.information">
                <lib-auto-layout
                  direction="vertical"
                  [padding]="[10, 20]"
                  class="information-section">
                  <ng-container *ngTemplateOutlet="information"></ng-container>
                </lib-auto-layout>
              </ng-container>
            </ng-container>
          </div>
        }
      }

      @if ((table.header?.length && isExpandedView) || !table.header?.length) {
        <ng-container *ngTemplateOutlet="list"></ng-container>
      }
    </lib-auto-layout>

    @if (buttonSetConfiguration) {
      <lib-action-buttons-toolbar
        [configuration]="buttonSetConfiguration"
        (actionTrigger)="dismissModalWithActionEvent($event)">
      </lib-action-buttons-toolbar>
    }
  </lib-auto-layout>
</ion-content>

<ng-template #list>
  <lib-auto-layout direction="vertical" horizontal="stretch" [grow]="1">
    <lib-generic-table-container
      class="fill-height"
      [data]="data"
      [isRowDataLoading]="isRowDataLoading"
      [isCard]="false"
      (eventMessage)="resolveEventMessage($event)">
    </lib-generic-table-container>
  </lib-auto-layout>
</ng-template>

<ng-template #information>
  <form #f="ngForm">
    @for (item of table.header; track item.identifier) {
      @if (isDisplayableField(item)) {
        <lib-field-container [item]="item" [preview]="true"></lib-field-container>
      }
    }
  </form>
</ng-template>
