import { APIError } from '../data/errors.data';
import { DynamicButton } from '../models/dynamic-button.model';
import {
  Invoker,
  InvokerBody,
  InvokerMethods,
  InvokerReferences,
} from '../models/invoker-body.model';
import {
  DataFieldValueType,
  FormatOptionIdentifier,
  FormItem,
  ShowType,
  TieFormObject,
} from '../models/sdapi-form-object.model';
import {
  ObjectCreate,
  ObjectType,
  SAPISubMenuObject,
  SDAPIMenuObject,
  SDAPIObject,
  SDAPIResponseObject,
} from '../models/sdapi-object.model';
import { AttributeNameIdentifier } from '../models/shared.model';

export class SDAPIObjectMapper {
  public static mapCreatedObjId(resource: SDAPIResponseObject): number {
    try {
      const objectCreate: ObjectCreate = resource.bulk.find(
        (bulkResponseObject: SDAPIObject<ObjectType>) =>
          bulkResponseObject.t === ObjectType.objectCreate,
      ) as ObjectCreate;
      return objectCreate.newObjId;
    } catch (error) {
      throw new APIError(`Mapping failed. 'newObjID' not present on response.`, error);
    }
  }

  public static mapInvokersFromMenu(resource: SDAPIMenuObject): InvokerBody[] {
    try {
      return SDAPIObjectMapper.extractInvokers(resource);
    } catch (error) {
      throw new APIError(`Mapping failed. No Invoker present on ${resource.objId}`, error);
    }
  }

  static extractInvokers(resource: SDAPIMenuObject): InvokerBody[] {
    return [
      ...(resource.subMenus?.map((subMenu: SAPISubMenuObject) => subMenu.invokers).flat() || []),
      ...(resource.invokers || []),
    ].filter((invoker: InvokerBody) => invoker);
  }

  public static mapImperativeInvoker(resource: SDAPIMenuObject): InvokerBody {
    try {
      return resource.imperativeInvoker;
    } catch (error) {
      throw new APIError(
        `Mapping failed. No ImperativeInvoker present on ${resource.objId}`,
        error,
      );
    }
  }

  public static getInvokerBodyByMethodName(
    invokerMethod: InvokerMethods,
    menuObject: SDAPIMenuObject,
  ): InvokerBody {
    const invokers: InvokerBody[] = this.getInvokerBodiesByMethodName(invokerMethod, menuObject);
    return invokers[0];
  }

  public static getInvokerBodyByReferenceName(
    invokerReferences: InvokerReferences[],
    menuObject: SDAPIMenuObject,
  ): InvokerBody[] {
    const invokers: InvokerBody[] = this.mapInvokersFromMenu(menuObject).filter((i: InvokerBody) =>
      invokerReferences.includes(i.reference),
    );

    return invokers;
  }

  public static getInvokerBodyByBehaviorInvokerNameAndId(
    behaviorInvokers: InvokerBody[],
    menuObject: SDAPIMenuObject,
  ): InvokerBody {
    const menuInvokers: InvokerBody[] = this.mapInvokersFromMenu(menuObject);
    const matchedInvoker: InvokerBody = this.findInvokerBodyByMethodNameAndId(
      behaviorInvokers,
      menuInvokers,
    );

    if (!matchedInvoker) {
      this.throwBehaviorInvokerNotFoundInMenuError(behaviorInvokers);
    }

    return matchedInvoker;
  }

  public static getInvokerBodiesByMethodName(
    invokerMethod: InvokerMethods,
    menuObject: SDAPIMenuObject,
  ): InvokerBody[] {
    const invokers: InvokerBody[] = this.mapInvokersFromMenu(menuObject).filter(
      (invoker: InvokerBody) => invoker.methodName === invokerMethod,
    );

    if (!invokers.length) {
      throw new APIError(
        `SDAPIObjectMapper failed. InvokerMethod: '${invokerMethod}' is not present on object '${menuObject.objId}'.`,
      );
    }

    return invokers;
  }

  public static retrievePreferredInvokerBodyOrDefault(
    preferredMethod: InvokerMethods,
    defaultMethod: InvokerMethods,
    menuObject: SDAPIMenuObject,
  ): InvokerBody {
    try {
      return this.getInvokerBodyByMethodName(preferredMethod, menuObject);
    } catch {
      return this.getInvokerBodyByMethodName(defaultMethod, menuObject);
    }
  }

  public static mapActivityPath(resource: SDAPIMenuObject | InvokerBody): string {
    try {
      return `/${resource.objId}/${resource.activityId}/${resource.methodId}`;
    } catch (error) {
      throw new APIError(
        `Mapping failed for Response Object '${resource.objId}'. No 'activityId' or 'methodId' present.`,
        error,
      );
    }
  }

  public static getTableRowValueByItemIdentifier(
    attributeNames: string[],
    values: string[],
    attributeName: string,
  ): string {
    const value = SDAPIObjectMapper.getOptionalTableRowValueByItemIdentifier(
      attributeNames,
      values,
      attributeName,
    );
    if (value) {
      return value;
    } else {
      const errorMesssage = `Mapping failed. No attribute with the name '${attributeName}' is present in the attribute profile.`;
      console.error(errorMesssage);
      throw new APIError(errorMesssage);
    }
  }

  public static getOptionalTableRowValueByItemIdentifier(
    attributeNames: string[],
    values: string[],
    attributeName: string,
  ): string | undefined {
    const targetAttributeName = new AttributeNameIdentifier(attributeName);
    const matchingName: string = this.findMatchingAttributeName(
      attributeNames,
      targetAttributeName,
    );
    if (matchingName) {
      return values[attributeNames.indexOf(targetAttributeName.normalizedValue)];
    } else {
      return undefined;
    }
  }

  public static mapShowTypesAsTableRowIdentifiers(showType: ShowType): string[] {
    return showType.items.map((item: FormItem) => {
      const attributeName = new AttributeNameIdentifier(item.attributeName);
      return attributeName.normalizedValue;
    });
  }

  public static mapShowTypesAsTableRowTypes(showType: ShowType): Map<string, DataFieldValueType> {
    return new Map(
      showType.items.map((item: FormItem) => {
        const attributeName = new AttributeNameIdentifier(item.attributeName);
        return [attributeName.normalizedValue, item.attrType] as [string, DataFieldValueType];
      }),
    );
  }

  public static mapShowTypesAsTableRowNames(showType: ShowType): Map<string, string> {
    return new Map(
      showType.items.map((item: FormItem) => {
        const attributeName = new AttributeNameIdentifier(item.attributeName);
        return [attributeName.normalizedValue, item.displayName];
      }),
    );
  }

  public static mapShowTypesAsTableRowFormats(
    showType: ShowType,
  ): Map<string, FormatOptionIdentifier> {
    return new Map(
      showType.items.flatMap((item: FormItem) => {
        if (item.format) {
          const attributeName = new AttributeNameIdentifier(item.attributeName);
          return [[attributeName.normalizedValue, item.format] as [string, FormatOptionIdentifier]];
        } else {
          return [];
        }
      }),
    );
  }

  public static mapAttributeNamesAsTableRowIdentifiers(names: string[]): string[] {
    return names.map((name: string) => new AttributeNameIdentifier(name).normalizedValue);
  }

  public static mapInvokersToDynamicButtons(invokers: Invoker[]): DynamicButton[] {
    return invokers.map(
      (invoker: Invoker) =>
        new DynamicButton({ extended: invoker.invoker.presentation.label }, invoker),
    );
  }

  public static findObjectInBulkServerResponse<T>(
    response: SDAPIResponseObject,
    objectType: ObjectType,
  ): T {
    const object: SDAPIObject<ObjectType> = response.bulk.find(
      (bulkResponseObject: SDAPIObject<ObjectType>) => bulkResponseObject.t === objectType,
    );

    if (object) {
      return object as T;
    } else {
      throw new APIError(`SDAPIObjectMapper failed. Object with ${objectType} type not found.`);
    }
  }

  public static getFormObjectFromBulkServerResponse(response: SDAPIResponseObject): TieFormObject {
    try {
      return SDAPIObjectMapper.findObjectInBulkServerResponse<TieFormObject>(
        response,
        ObjectType.form,
      );
    } catch (error) {
      throw new APIError(
        `SDAPIObjectMapper failed. Form object not present in BulkServerResponse.`,
        error,
      );
    }
  }

  public static retrieveNewObjectIdFromBulkServerResponse(response: SDAPIResponseObject): string {
    try {
      const objectCreate: ObjectCreate =
        SDAPIObjectMapper.findObjectInBulkServerResponse<ObjectCreate>(
          response,
          ObjectType.objectCreate,
        );
      return objectCreate.newObjId.toString();
    } catch (error) {
      throw new APIError(
        `SDAPIObjectMapper failed. 'newObjID' not present in BulkServerResponse.`,
        error,
      );
    }
  }

  public static getInvokerBodyIdentifier(invokerBody: InvokerBody): string {
    return `${invokerBody.objId}-${invokerBody.activityId}-${invokerBody.parentId}`;
  }

  private static findInvokerBodyByMethodNameAndId(
    invokerBodies: InvokerBody[],
    menuInvokers: InvokerBody[],
  ): InvokerBody {
    for (const invokerBody of invokerBodies) {
      const matchedInvoker = menuInvokers.find(
        (invoker: InvokerBody) =>
          invoker.methodName === invokerBody.methodName &&
          invoker.methodId === invokerBody.methodId,
      );
      if (matchedInvoker) {
        return matchedInvoker;
      }
    }
    return null;
  }

  private static throwBehaviorInvokerNotFoundInMenuError(invokerBodies: InvokerBody[]): void {
    const invokerNames: string = invokerBodies
      .map((invoker: InvokerBody) => invoker.methodName)
      .join(', ');

    throw new APIError(
      `SDAPIObjectMapper failed. None of the provided behavior invokers with method names '${invokerNames}' are present on the menu object.`,
    );
  }

  private static findMatchingAttributeName(
    attributeNames: string[],
    targetAttributeName: AttributeNameIdentifier,
  ): string {
    return attributeNames.find((name: string) => {
      const comparisonAttributeName = new AttributeNameIdentifier(name);
      return targetAttributeName.isEqualTo(comparisonAttributeName);
    });
  }
}
