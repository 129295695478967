import { Component, Input } from '@angular/core';
import { TreatmentDoctorContact } from 'projects/core/src/lib/models/treatment.model';

@Component({
  selector: 'lib-doctor-contacts',
  templateUrl: './doctor-contacts.component.html',
  styleUrl: './doctor-contacts.component.scss',
})
export class DoctorContactsComponent {
  @Input({ required: true }) doctorContacts: TreatmentDoctorContact[];

  hasCommunicationChannels(contact: TreatmentDoctorContact): boolean {
    return !!contact.email?.value.value || !!contact.phone?.value.value;
  }
}
