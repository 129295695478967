<lib-auto-layout direction="vertical" [padding]="[10]">
  <lib-information-card
    [style]="'info'"
    [icon]="'user-doctor'"
    [title]="'shared.treatments.personnel' | translate"
    class="stretch-self">
    <lib-auto-layout
      infoCardContent
      class="doctor-contacts"
      direction="vertical"
      horizontal="stretch"
      [gap]="[5]"
      [padding]="[0, 5, 5, 5]">
      @for (contact of doctorContacts; track $index; let i = $index) {
        <lib-auto-layout
          [id]="'contact-menu-popup' + i"
          direction="vertical"
          horizontal="stretch"
          [padding]="[7, 10]"
          [class.interactive]="hasCommunicationChannels(contact)">
          <lib-auto-layout
            horizontal="space-between"
            [gap]="[10]"
            class="contact-item contact-name">
            <lib-auto-layout>
              @if (contact.firstName || contact.lastName) {
                <span class="h3">
                  {{ contact.title?.value.value }}
                  {{ contact.lastName?.value.value }},
                  {{ contact.firstName?.value.value }}
                </span>
              } @else {
                <span class="h3">{{ 'shared.treatments.contact-name-unknown' | translate }}</span>
              }
            </lib-auto-layout>

            <lib-auto-layout>
              @if (contact.role) {
                <span class="p ion-text-right">{{ contact.role.value.value }}</span>
              }
            </lib-auto-layout>
          </lib-auto-layout>
          @if (contact.phone) {
            <lib-auto-layout horizontal="space-between" [gap]="[10]" class="contact-item">
              <lib-auto-layout>
                <span class="p">{{ contact.phone.label }}</span>
              </lib-auto-layout>
              <lib-auto-layout>
                <span class="p ion-text-right">{{ contact.phone.value.value }}</span>
              </lib-auto-layout>
            </lib-auto-layout>
          }
          @if (contact.email) {
            <lib-auto-layout horizontal="space-between" [gap]="[10]" class="contact-item">
              <lib-auto-layout>
                <span class="p">{{ contact.email.label }}</span>
              </lib-auto-layout>
              <lib-auto-layout>
                <span class="p ion-text-right">{{ contact.email.value.value }}</span>
              </lib-auto-layout>
            </lib-auto-layout>
          }
          @if (contact.address) {
            <lib-auto-layout horizontal="space-between" [gap]="[10]" class="contact-item">
              <lib-auto-layout>
                <span class="p">{{ contact.address.label }}</span>
              </lib-auto-layout>

              <lib-auto-layout direction="vertical" horizontal="end">
                <span class="p ion-text-right">{{ contact.address.value.value }}</span>
              </lib-auto-layout>
            </lib-auto-layout>
          }
          @if (contact.address) {
            <lib-auto-layout horizontal="space-between" [gap]="[10]" class="contact-item">
              <lib-auto-layout></lib-auto-layout>
              <lib-auto-layout direction="vertical" horizontal="end">
                <span class="p ion-text-right">
                  @if (contact.zip) {
                    {{ contact.zip.value.value }}
                  }
                  @if (contact.city) {
                    {{ contact.city.value.value }}
                  }
                </span>
                @if (contact.country) {
                  <span class="p ion-text-right">{{ contact.country.value.value }}</span>
                }
              </lib-auto-layout>
            </lib-auto-layout>
          } @else {
            @if (contact.zip) {
              <lib-auto-layout horizontal="space-between" [gap]="[10]" class="contact-item">
                <lib-auto-layout>
                  <span class="p">{{ contact.zip.label }}</span>
                </lib-auto-layout>

                <lib-auto-layout direction="vertical" horizontal="end">
                  <span class="p ion-text-right">{{ contact.zip.value.value }}</span>
                </lib-auto-layout>
              </lib-auto-layout>
            }
            @if (contact.city) {
              <lib-auto-layout horizontal="space-between" [gap]="[10]" class="contact-item">
                <lib-auto-layout>
                  <span class="p">{{ contact.city.label }}</span>
                </lib-auto-layout>

                <lib-auto-layout direction="vertical" horizontal="end">
                  <span class="p ion-text-right">{{ contact.city.value.value }}</span>
                </lib-auto-layout>
              </lib-auto-layout>
            }
            @if (contact.country) {
              <lib-auto-layout horizontal="space-between" [gap]="[10]" class="contact-item">
                <lib-auto-layout>
                  <span class="p">{{ contact.country.label }}</span>
                </lib-auto-layout>

                <lib-auto-layout direction="vertical" horizontal="end">
                  <span class="p ion-text-right">{{ contact.country.value.value }}</span>
                </lib-auto-layout>
              </lib-auto-layout>
            }
          }
        </lib-auto-layout>

        @if (hasCommunicationChannels(contact)) {
          <ion-popover
            [dismissOnSelect]="true"
            [trigger]="'contact-menu-popup' + i"
            alignment="center"
            animated="true"
            arrow="false"
            side="bottom"
            cssClass="contact-menu-popup">
            <ng-template>
              <ion-list class="popover-item-list">
                @if (contact.phone?.value.value) {
                  <ion-item class="p" lines="none" href="tel:{{ contact.phone?.value.value }}">
                    <ion-label>{{ 'shared.treatments.call' | translate }}</ion-label>
                    <fa-icon slot="end" icon="phone"></fa-icon>
                  </ion-item>
                }
                @if (contact.email?.value.value) {
                  <ion-item class="p" lines="none" href="mailto:{{ contact.email?.value.value }}">
                    <ion-label>{{ 'shared.treatments.write-email' | translate }}</ion-label>
                    <fa-icon slot="end" icon="envelope"></fa-icon>
                  </ion-item>
                }
              </ion-list>
            </ng-template>
          </ion-popover>
        }
      }
    </lib-auto-layout>
  </lib-information-card>
</lib-auto-layout>
