import { Component, HostBinding, Input } from '@angular/core';
import { IconDefinition, IconName, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'lib-notification-box-component',
  template: `
    <lib-auto-layout
      [gap]="[20]"
      [padding]="padding"
      direction="vertical"
      vertical="center"
      horizontal="center"
      class="stretch-self"
      [style.background]="backgroundColor">
      <fa-icon [icon]="icon" size="lg"></fa-icon>
      <h3 class="ion-no-margin">{{ message }}</h3>
      <ng-content select="[notificationBoxButtonCollection]"></ng-content>
    </lib-auto-layout>
  `,
  styles: [
    `
      h3 {
        font-weight: bold;
        text-align: center;
      }
    `,
  ],
})
export class NotificationBoxComponentComponent {
  @HostBinding('style.width') width = '100%';

  @Input({ required: true }) message: string;
  @Input() icon?: IconDefinition | IconName = faCircleInfo;
  @Input() padding: number[] = [20];
  @Input() background: NotificationBoxBackground = 'white';

  get backgroundColor(): string {
    if (this.background === 'tertiary') {
      return 'var(--color-greyish-4)';
    }
    return 'var(--color-white)';
  }
}

type NotificationBoxBackground = 'white' | 'tertiary';
