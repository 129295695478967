<lib-auto-layout direction="vertical" [gap]="[20]" class="fill-height" horizontal="stretch">
  <lib-search-input
    [disabled]="isLoading || !requiredActions?.length"
    [searchParams]="searchParams"
    (searchParamsChanged)="refreshViewList()">
  </lib-search-input>

  <lib-auto-layout direction="vertical" vertical="stretch" [grow]="1" class="shape-card">
    <lib-auto-layout direction="vertical" vertical="stretch" [grow]="1" id="task-list-container">
      @if (isLoading) {
        <lib-tasks-browser-skeleton [grow]="1" id="initial-skeleton"></lib-tasks-browser-skeleton>
      } @else if (hasActions) {
        <lib-tasks-list [grow]="1" [requiredActionsList]="viewList" data-api-id="tasks-list">
        </lib-tasks-list>
      } @else {
        <lib-notification-box-component class="no-result" [message]="noResultMessage | translate">
        </lib-notification-box-component>
      }
    </lib-auto-layout>
  </lib-auto-layout>
</lib-auto-layout>
