import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { OverlayEventDetail } from '@ionic/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ProfileSubscriptionElement } from 'projects/core/src/lib/mappers/subscription.mapper';
import {
  DynamicForm,
  DynamicFormConfiguration,
  DynamicFormType,
} from 'projects/core/src/lib/models/form.model';
import {
  ProfileChangeRequestTranslationKeys,
  ProfileTranslationKeys,
  TranslationOptions,
} from 'projects/core/src/lib/models/modal-action.model';
import { ProfileFieldKey } from 'projects/core/src/lib/models/profile.model';
import { LanguageService } from 'projects/core/src/lib/services/language.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { OverlayEventRole, PopupService } from 'projects/core/src/lib/services/popup.service';
import { ProfileService } from 'projects/core/src/lib/services/profile.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-profile-card-wrapper',
  templateUrl: './profile-card-wrapper.component.html',
  styleUrls: ['./profile-card-wrapper.component.scss'],
})
@UntilDestroy()
export class ProfileCardWrapperComponent {
  @Output() profileDataUpdate = new EventEmitter<string>();

  @Input() title: string;
  @Input() isLoading = true;
  @Input() fieldKeys: ProfileFieldKey[];
  @Input() groupIndex: number;
  @Input() hasEditableFields: boolean;
  @Input() requestProfileChangeForm: DynamicForm;

  readonly icon = {
    edit: faPenToSquare,
  };

  readonly furtherSubscriptionsGroupIndex = 3;

  constructor(
    private profileService: ProfileService,
    private popupService: PopupService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private languageService: LanguageService,
  ) {}

  async showRequestProfileChangeForm(): Promise<void> {
    await this.popupService.showDynamicFormModal(
      new DynamicFormConfiguration({
        type: DynamicFormType.DIRECT_SAVE,
        dynamicForm: this.requestProfileChangeForm,
        translationOptions: {
          keys: ProfileChangeRequestTranslationKeys,
          successMessageKey: 'save-completion',
          actionInProgressKey: 'saving-in-progress',
        },
      }),
    );
  }

  async openEditProfileModal(): Promise<void> {
    try {
      await this.loadingService.load(
        await firstValueFrom(this.translateService.get('shared.profile.opening-form')),
      );
      if (this.groupIndex === this.furtherSubscriptionsGroupIndex) {
        await this.showFurtherSubscriptionsUpdateModal();
      } else {
        const mappedForm = await this.profileService.getMappedAndFilteredProfileEditForm(
          this.fieldKeys,
          this.groupIndex,
        );
        await this.showFormModalAndUpdateProfileOnSave(mappedForm);
      }
    } finally {
      await this.loadingService.stop();
    }
  }

  async showFurtherSubscriptionsUpdateModal(): Promise<void> {
    const profileSubscriptionElements: ProfileSubscriptionElement[] =
      await this.profileService.getProfileSubscriptionElements();
    const event: OverlayEventDetail = await this.popupService.showFurtherSubscriptionsUpdateModal(
      profileSubscriptionElements,
    );
    if (event.role === OverlayEventRole.save) {
      this.profileDataUpdate.emit();
    }
  }

  private async showFormModalAndUpdateProfileOnSave(filteredForm: DynamicForm): Promise<void> {
    await this.popupService
      .showDynamicFormModal(this.getDynamicFormConfiguration(filteredForm))
      .then(async (response) => {
        if (response.role === OverlayEventRole.save) {
          this.configureLanguage(filteredForm);
          this.profileDataUpdate.emit();
        }
        await this.loadingService.stop();
      });
  }

  private configureLanguage(form: DynamicForm): void {
    this.languageService.syncCurrentLanguageWithProfileForm(form);
  }

  private getDynamicFormConfiguration(form: DynamicForm): DynamicFormConfiguration {
    const translationOptions: TranslationOptions = {
      keys: ProfileTranslationKeys,
      successMessageKey: 'save-completion',
      actionInProgressKey: 'saving-in-progress',
    };
    return new DynamicFormConfiguration({
      type: DynamicFormType.DIRECT_SAVE,
      dynamicForm: form,
      translationOptions,
    });
  }
}
