import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EChartsOption } from 'echarts';
import { GraphDataSet } from 'projects/core/src/lib/models/graph.model';
import { LaboratoryResultsService } from 'projects/core/src/lib/services/laboratory-results.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-graph-data-viewer',
  templateUrl: './graph-data-viewer.component.html',
  host: { class: 'large-modal' },
})
export class GraphDataViewerComponent implements OnInit {
  @Input({ required: true }) dataSet: GraphDataSet;
  @Input({ required: true }) title: string;
  chartOptions: EChartsOption;

  constructor(
    private readonly translateService: TranslateService,
    private readonly laboratoryResultsService: LaboratoryResultsService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.initializeChartConfiguration();

  }

  private async initializeChartConfiguration(): Promise<void> {
    const valueTranslation: string = await firstValueFrom(
      this.translateService.get('general.value'),
    );
    this.chartOptions = await this.laboratoryResultsService.constructChartOptions(
      this.dataSet,
      valueTranslation,
    );
  }
}
