import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-laboratory-notes-modal',
  templateUrl: './laboratory-notes-modal.component.html',
  styleUrl: './laboratory-notes-modal.component.scss',
  host: { class: 'large-modal' },
})
export class LaboratoryNotesModalComponent {
  @Input() notes: string[];
}
