<lib-modal-header
  [title]="'shared.laboratory-results.data-details' | translate"
  [style]="'view'"></lib-modal-header>

<ion-content scrollX="true" scrollY="true">
  <lib-auto-layout class="content-container stretch-self">
    @for (note of notes; track $index) {
      <div [innerHTML]="note | safeHtml" class="notes-content"></div>
    }
  </lib-auto-layout>
</ion-content>
