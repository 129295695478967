import { IconDefinition } from '@fortawesome/angular-fontawesome';

export enum PatientMenuKeys {
  appointments = 'appointments',
  treatments = 'treatments',
  documents = 'documents',
  orders = 'orders',
  aftercare = 'aftercare',
  iheViewer = 'iheViewer',
  setShow = 'setShow',
  patientData = 'patient-data',
  laboratory = 'laboratory',
}

export interface PatientMenuItem {
  key: PatientMenuKeys;
  icon: IconDefinition;
  url: string;
  active: boolean;
  getDetails?: () => Promise<string>;
}
