import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_CONFIG, ApiConfig } from 'projects/core/src/lib/models/api-config.model';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { firstValueFrom } from 'rxjs';
import { About, HealthEngineVersion } from '../models/about.model';
import { EncoderUtils } from '../utils/encoder.utils';

@Injectable()
export class AboutService {
  private about: About = new About();

  constructor(
    private http: HttpClient,
    @Inject(APP_BASE_HREF) private baseHref: string,
    @Inject(API_CONFIG) private readonly apiConfig: ApiConfig,
  ) {}

  async initialize(): Promise<void> {
    await Promise.all([this.loadAbout(), this.loadHealthEngineVersion()]);
  }

  async loadAbout(): Promise<void> {
    try {
      const about: About = await firstValueFrom(
        this.http.get<About>(`${this.baseHref}shared/assets/config/dynamic/about.json`, {
          headers: CustomHttpHeaders.XNoApiBaseUrlInterceptionHeaders,
        }),
      );
      this.about.appVersion = about.appVersion;
    } catch (error) {
      console.warn('Could not load about appVersion', error);
    }
  }

  async loadHealthEngineVersion(): Promise<void> {
    try {
      if (!this.apiConfig.healthEngineVersionPath) {
        return null;
      }
      const healthEngineVersion: HealthEngineVersion = await firstValueFrom(
        this.http.get<HealthEngineVersion>(this.apiConfig.healthEngineVersionPath, {
          headers: CustomHttpHeaders.build(
            CustomHttpHeaders.XNoApiBaseUrlInterception,
            CustomHttpHeaders.XNoAlertInterceptor,
          ),
        }),
      );
      if (healthEngineVersion) {
        EncoderUtils.initialize(healthEngineVersion);
        this.about.healthEngineVersion = healthEngineVersion;
      }
    } catch (error) {
      console.warn('Could not load about healthEngineVersion', error);
    }
  }

  get(): About {
    return this.about;
  }

  isDemo(): boolean {
    return (
      window.location.hostname.includes('localhost') ||
      window.location.hostname.includes('swcode.io') ||
      window.location.hostname.includes('portal.tie.ch')
    );
  }
}
