import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import MiniSearch from 'minisearch';
import {
  TableConfiguration,
  TableHeaderItem,
  TableList,
  TableListItem,
  TableRowView,
} from '../models/dynamic-table.model';
import { SearchParams } from '../models/shared.model';

@Injectable()
export class TableOrganizerService {
  public static sortHorizontally(
    initialHeader: TableHeaderItem[],
    sortedHeader: TableHeaderItem[],
    initialRows: TableListItem[],
    viewRows: TableListItem[],
  ): TableListItem[] {
    const arrayOfIndices: number[] = this.getArrayOfIndicesOfInitialHeaderValues(
      initialHeader,
      sortedHeader,
    );
    return this.sortRowValuesAccordingToIndices(arrayOfIndices, initialRows, viewRows);
  }

  private static getArrayOfIndicesOfInitialHeaderValues(
    initialHeader: TableHeaderItem[],
    sortedHeader: TableHeaderItem[],
  ): number[] {
    return sortedHeader.map((item: TableHeaderItem) =>
      initialHeader.findIndex(
        (headerItem) => headerItem.value === item.value && headerItem.type === item.type,
      ),
    );
  }

  private static sortRowValuesAccordingToIndices(
    arrayOfIndices: number[],
    initialRows: TableListItem[],
    viewRows: TableListItem[],
  ): TableListItem[] {
    viewRows.forEach((sortedRow: TableListItem) => {
      sortedRow.columns = arrayOfIndices.map(
        (i: number) =>
          initialRows.find((initialRow: TableListItem) => initialRow.id === sortedRow.id).columns[
            i
          ],
      );
    });
    return viewRows;
  }

  public getInverseViewportTopPosition(viewPort: CdkVirtualScrollViewport): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (!viewPort?._renderedContentTransform) {
      return '0px';
    }
    return `-${viewPort.getOffsetToRenderedContentStart()}px`;
  }

  public updateTableAfterSearch(
    searchHandler: MiniSearch<TableListItem>,
    searchParams: SearchParams,
    table: TableList,
  ): void {
    if (!table) {
      return;
    }

    const hasSearchCriterions: boolean = !!searchParams.criterions;
    table.organizeRowsAfterSearch(searchHandler, searchParams, hasSearchCriterions);
  }

  public static getColumnNumberByTableWidth(
    data: TableConfiguration,
    widthInPixels: number,
  ): number {
    return Math.floor(widthInPixels / data.columnWidth);
  }

  public static resolveRowViewByTableWidth(
    data: TableConfiguration,
    widthInPixels: number,
  ): number {
    return widthInPixels < data.expandedViewThreshold ? TableRowView.mobile : TableRowView.desktop;
  }
}
