<ion-card color="light" class="no-margin widget-card">
  <lib-auto-layout vertical="center" [gap]="[10]" [padding]="[15, 20, 10]" class="widget-header">
    <h2 data-api-id="appointment-list" [grow]="1" class="no-margin">
      {{ 'appointments.your-upcoming-appointments' | translate }}
    </h2>
  </lib-auto-layout>

  <ion-card-content>
    <ng-container *ngIf="mappedAppointmentGroups">
      <ng-container *ngFor="let appointmentGroup of mappedAppointmentGroups.future">
        <lib-appointment-date-divider
          [date]="appointmentGroup.date"
          [mappedAppointmentGroups]="mappedAppointmentGroups">
        </lib-appointment-date-divider>
        <lib-appointment-item
          [widgetView]="true"
          [appointmentGroup]="appointmentGroup"
          [mappedAppointmentGroups]="mappedAppointmentGroups"
          (onSelectAppointment)="navigateToAppointment($event)">
        </lib-appointment-item>
      </ng-container>
    </ng-container>

    <div *ngIf="!mappedAppointmentGroups">
      <div class="skeleton-item" *ngFor="let item of [0]">
        <div class="appointments-date">
          <ion-skeleton-text animated="true" class="rounded-small"></ion-skeleton-text>
        </div>
        <div class="appointments-info">
          <ion-skeleton-text animated="true" class="rounded-small"></ion-skeleton-text>
        </div>
      </div>
    </div>

    @if (isDataAccessRestricted) {
      <lib-auto-layout direction="vertical" [grow]="1">
        <lib-notification-box-component
          [grow]="1"
          [icon]="'user-lock'"
          [message]="'shared.appointment-calendar.no-appointments-access' | translate"
          [background]="'tertiary'"
          [padding]="[40]">
        </lib-notification-box-component>
      </lib-auto-layout>
    }

    @if (showNoAppointmentsMessage && !isDataAccessRestricted) {
      <lib-auto-layout direction="vertical" [grow]="1" class="no-results">
        <lib-notification-box-component [message]="noAppointmentsMessage" [padding]="[40]">
        </lib-notification-box-component>
      </lib-auto-layout>
    }

    <div class="button-section">
      @if (showRequestNewAppointment) {
        <ion-button
          id="newAppointment"
          class="p3"
          color="primary"
          expand="block"
          [disabled]="isDataAccessRestricted"
          (click)="newAppointment()"
          data-api-id="appointment-creation">
          <fa-icon size="sm" slot="start" [icon]="icons.appointment"></fa-icon>
          {{ 'appointments.new-appointment-request' | translate }}
        </ion-button>
      }

      @if (!isDataAccessRestricted) {
        <ion-button
          class="appointments-page-link p3"
          color="medium"
          fill="clear"
          expand="block"
          (click)="navigateToAllAppointments()">
          {{ 'appointments.show-all-appointments' | translate }}
        </ion-button>
      }
    </div>
  </ion-card-content>
</ion-card>
