<label class="label-input" for="multi-chosen-container">
  {{ multiChosenData.collector.title }}
</label>
<lib-auto-layout
  id="multi-chosen-container"
  class="shape-field multi-chosen-container"
  direction="vertical"
  [gap]="[1]">
  <lib-auto-layout [grow]="1" vertical="center" class="multi-chosen-row">
    <div class="multi-chosen-row-label text-transform-uppercase p" [grow]="1">
      <strong>
        {{ multiChosenData.collector.header[0] }}
      </strong>
    </div>
  </lib-auto-layout>
  @for (row of multiChosenData.collector.collection; track $index) {
    <lib-auto-layout [grow]="1" [gap]="[1]" vertical="stretch" class="multi-chosen-row">
      <div class="multi-chosen-row-label p trim-text-inline" [grow]="1">
        {{ row[0].value.options[0].value }}
      </div>
      <button
        class="shape-square multi-chosen-popover-row-icon color-grey"
        (click)="multiChosenData.collector.removeRow($index)">
        <fa-icon icon="circle-xmark"></fa-icon>
      </button>
    </lib-auto-layout>
  }
  <button
    [grow]="1"
    [disabled]="!hasOptionsLeft"
    class="color-primary multi-chosen-menu-button"
    id="multi-chosen-menu-button">
    <div class="p multi-chosen-menu-button-label" [grow]="1">
      <strong>
        {{ multiChosenData.options.name }}
      </strong>
      <fa-icon icon="circle-plus" size="sm"></fa-icon>
    </div>
  </button>
</lib-auto-layout>

<ion-popover
  #choseMenu
  trigger="multi-chosen-menu-button"
  side="bottom"
  triggerAction="click"
  class="multi-chosen-popover">
  <ng-template>
    <lib-auto-layout
      class="multi-chosen-popover-container"
      direction="vertical"
      horizontal="stretch"
      [gap]="[1]">
      @for (option of options; track $index) {
        <button [grow]="1" class="multi-chosen-popover-row" (click)="addRow(option)">
          <lib-auto-layout [grow]="1" vertical="center">
            <div class="p multi-chosen-popover-row-label" [grow]="1">
              {{ option.value }}
            </div>
            <div class="shape-square multi-chosen-popover-row-icon">
              <fa-icon icon="circle-plus"></fa-icon>
            </div>
          </lib-auto-layout>
        </button>
      }
    </lib-auto-layout>
  </ng-template>
</ion-popover>
