<ion-list class="card-list items">
  <ng-container>
    @if (activeFields.length > 0) {
      @for (activeField of activeFields; track activeField.internalValue) {
        <ion-item class="item-line static" lines="none">
          <p>{{ activeField.displayValue }}</p>
        </ion-item>
      }
    } @else {
      <ion-item class="item-line static" lines="none">
        <p>
          <fa-icon slot="start" [icon]="ICONS.circleExclamation"></fa-icon
          >{{ 'shared.profile.no-active-subscriptions' | translate }}
        </p>
      </ion-item>
    }
    @if (inactiveFields.length > 0) {
      <ion-item class="item-line list-divider" lines="none">
        <h3 slot="start" class="no-margin">{{ 'shared.profile.inactive' | translate }}</h3>
      </ion-item>
      @for (inactiveField of inactiveFields; track inactiveField.internalValue) {
        <ion-item class="item-line static" lines="none">
          <p>{{ inactiveField.displayValue }}</p>
        </ion-item>
      }
    }
  </ng-container>
</ion-list>
