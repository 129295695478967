import { Location } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { faCalendar, faCalendarPlus } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowLeft,
  faArrowRight,
  faCalendarDays,
  faChevronRight,
  faInfoCircle,
  faPhone,
  faStethoscope,
  faVideo,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { IonDatetime } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AppointmentDetails,
  AppointmentType,
  AppointmentViewType,
  CalendarEvent,
  TemplateAppointmentsGroup,
  ViewAppointmentsGroup,
} from 'projects/core/src/lib/models/appointments.model';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';
import { DynamicDataField, DynamicForm } from 'projects/core/src/lib/models/form.model';
import { AppointmentBookingService } from 'projects/core/src/lib/services/appointment-booking.service';
import { AppointmentsService } from 'projects/core/src/lib/services/appointments.service';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { PageHeaderService } from 'projects/core/src/lib/services/page-header.service';
import { OverlayEventRole, PopupService } from 'projects/core/src/lib/services/popup.service';
import { StorageService } from 'projects/core/src/lib/services/storage.service';
import { HeaderConfiguration } from 'projects/theme/src/lib/components/header/header.model';
import { LocaleDatePipe } from 'projects/theme/src/lib/pipes/locale-date.pipe';
import { firstValueFrom, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AppointmentBookingData, FieldTransferData } from '../appointment-booking/storage.model';

@Component({
  selector: 'lib-appointments-overview',
  templateUrl: './appointments-overview.component.html',
  styleUrls: ['./appointments-overview.component.scss'],
  host: { class: 'stretch-flex' },
})
export class AppointmentsOverviewComponent implements AfterContentChecked, OnInit, OnDestroy {
  @Input() set appointments(appointments: AppointmentDetails[]) {
    this.processRetrievedAppointments(appointments);
  }
  @Input() isDoctor: boolean;
  @Input() isReadyToShowAppointments = true;
  @Input() patientID: string;
  @Input() isDataAccessRestricted: boolean;

  @Output() appointmentsReload = new EventEmitter<void>();
  @Output() appointmentsReloadAfterCreation = new EventEmitter<string>();

  @ViewChild('monthCalendar') monthCalendar: FullCalendarComponent;
  @ViewChild('detailsCalendar') detailsCalendar: IonDatetime;

  private translations: string[];
  private unregisterUrlChangeListener: VoidFunction;

  appointmentIdFromUrl: string;
  showRequestNewAppointment: boolean;
  shouldReloadAppointmentsAfterCreation: boolean;

  selectedView: AppointmentViewType = AppointmentViewType.OVERVIEW;
  selectedAppointment: AppointmentDetails;
  selectedDate: Date = new Date();
  selectedDateAppointments: TemplateAppointmentsGroup;

  mappedAppointmentGroups: ViewAppointmentsGroup;

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: false,
    events: [],
    eventClick: this.onEventClick.bind(this),
    locale: 'de',
    firstDay: 1,
    themeSystem: 'bootstrap5',
  };

  readonly icons = {
    appointment: faCalendarPlus,
    arrowLeft: faArrowLeft,
    arrowRight: faArrowRight,
    chevronRight: faChevronRight,
    date: faCalendar,
    info: faInfoCircle,
    video: faVideo,
    calendar: faCalendarDays,
  };

  constructor(
    private popupService: PopupService,
    private clientService: ClientConfigService,
    private localeDatePipe: LocaleDatePipe,
    private breakpointService: BreakpointService,
    private changeDetector: ChangeDetectorRef,
    private loadingService: LoadingService,
    private bookingService: AppointmentBookingService,
    private translateService: TranslateService,
    private destroyRef: DestroyRef,
    private appointmentService: AppointmentsService,
    private location: Location,
    private pageHeaderService: PageHeaderService,
    private storageService: StorageService,
    private route: ActivatedRoute,
  ) {
    this.unregisterUrlChangeListener = this.location.onUrlChange((url: string) => {
      this.updateSelectedAppointment(url);
    });
  }

  async ngOnInit() {
    await this.setTranslationData();
    await this.checkIfShouldOpenAppointmentBookingModal();
    this.configureClientSettings();
    this.calendarOptions.locale = this.translateService.currentLang;
  }

  private async checkIfShouldOpenAppointmentBookingModal(): Promise<void> {
    const shouldShowPublicAppointmentBookingInstantly =
      this.route.snapshot.queryParamMap.get('showPublicAppointmentBookingModal') === 'true';
    if (shouldShowPublicAppointmentBookingInstantly) {
      await this.newAppointment();
    }
  }

  private get appointmentListViewPath(): string {
    return this.patientID
      ? `/portal/patients/${this.patientID}/appointments/`
      : '/portal/appointments/';
  }

  ngOnDestroy(): void {
    this.unregisterUrlChangeListener();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  private updateSelectedAppointment(url: string): void {
    this.appointmentIdFromUrl = this.getCurrentAppointmentIdFromUrl(url);
    if (!this.appointmentIdFromUrl) {
      this.selectedAppointment = undefined;
      return;
    }
    if (!this.isIdFromUrlEqualSelectedAppointmentId) {
      this.selectAppointmentById(this.appointmentIdFromUrl);
    }
  }

  private async setTranslationData(): Promise<void> {
    this.translations = await firstValueFrom(
      this.translateService.get('shared.appointment-overview'),
    );
  }

  private configureClientSettings(): void {
    const clientConfig: ClientConfig = this.clientService.get();
    if (clientConfig) {
      this.showRequestNewAppointment = clientConfig.activeModules.requestAppointment;
      this.shouldReloadAppointmentsAfterCreation =
        clientConfig.dataReloadConfig.reloadTillNewAppointmentPresent;
    }
  }

  private async processRetrievedAppointments(appointments: AppointmentDetails[]): Promise<void> {
    if (appointments) {
      this.prepareAppointmentsForCalendarView(appointments);
      this.updateSelectedDateAppointments();
      this.appointmentIdFromUrl = this.getCurrentAppointmentIdFromUrl(this.location.path());
      if (this.appointmentIdFromUrl) {
        this.selectAppointmentById(this.appointmentIdFromUrl);
      }
      await this.checkForCreateAppointmentData();
    }
    this.updatePageHeaderForCompactCalendarView();
  }

  private async checkForCreateAppointmentData(): Promise<void> {
    const prefillData: AppointmentBookingData =
      await this.storageService.getObject<AppointmentBookingData>('bookingData');
    if (prefillData) {
      await this.storageService.remove('bookingData');
      await this.newAppointment(AppointmentBookingData.fromPlainObject(prefillData));
    }
  }

  private prepareAppointmentsForCalendarView(appointments: AppointmentDetails[]): void {
    this.mappedAppointmentGroups =
      this.appointmentService.groupAppointmentsByDateForView(appointments);

    this.calendarOptions.events = this.generateCalendarEventsFromAppointments(
      this.mappedAppointmentGroups,
    );
  }

  private updateSelectedDateAppointments(): void {
    this.selectedDateAppointments = [
      ...this.mappedAppointmentGroups.future,
      ...this.mappedAppointmentGroups.past,
    ].find((ma) => ma.date.toDateString() == this.selectedDate.toDateString());
  }

  private getCurrentAppointmentIdFromUrl(url: string): string {
    const pathSegments: string[] = url.split('/');
    const lastSegment: string = pathSegments[pathSegments.length - 1];
    const idMatchResult: RegExpMatchArray = lastSegment.match(/^\d+/);
    return idMatchResult ? idMatchResult[0] : undefined;
  }

  private selectAppointmentById(id: string): void {
    const appointment: AppointmentDetails = this.findAppointmentById(
      this.mappedAppointmentGroups,
      id,
    );
    this.selectedAppointment = appointment;
  }

  private findAppointmentById(
    mappedAppointments: ViewAppointmentsGroup,
    appointmentId: string,
  ): AppointmentDetails {
    return [...mappedAppointments.past, ...mappedAppointments.future]
      .flatMap((group: TemplateAppointmentsGroup) => group.appointments)
      .find((appointment: AppointmentDetails) => appointment.id === Number(appointmentId));
  }

  private updatePageHeaderForCompactCalendarView(): void {
    if (this.isMobile && this.selectedAppointment) {
      this.pageHeaderService.updateHeader(this.appointmentDetailsHeaderConfiguration);
    }
  }

  get isLoading(): boolean {
    return typeof this.mappedAppointmentGroups === 'undefined' || !this.isReadyToShowAppointments;
  }

  get currentViewTitle(): string {
    switch (this.selectedView) {
      case AppointmentViewType.OVERVIEW:
        return `${this.translations['current']} / ${this.translations['past']}`;
      case AppointmentViewType.MONTH:
        return this.monthCalendar?.getApi().getCurrentData().viewTitle || '';
      case AppointmentViewType.DAY:
      default:
        return this.localeDatePipe.transform(this.selectedDate, 'dd. MMMM yyyy');
    }
  }

  get isMobile(): boolean {
    return this.breakpointService.isBelow('md');
  }

  get isAppointmentListInMobileView(): boolean {
    return this.isMobile && !this.selectedAppointment;
  }

  get isIdFromUrlEqualSelectedAppointmentId(): boolean {
    return this.appointmentIdFromUrl === this.selectedAppointment?.id.toString();
  }

  get hasPatientView(): boolean {
    return !!this.patientID;
  }

  private get appointmentDetailsHeaderConfiguration(): HeaderConfiguration {
    return {
      title: { extended: 'shared.header.title.short.appointment-details' },
      icon: this.icons.calendar,
      backNavigation: {
        text: {
          extended: 'shared.header.title.short.appointments',
        },
        path: this.appointmentListViewPath,
      },
    } as HeaderConfiguration;
  }

  private get appointmentListHeaderConfiguration(): HeaderConfiguration {
    return {
      title: { extended: 'shared.header.title.short.appointments' },
      icon: this.icons.calendar,
    } as HeaderConfiguration;
  }

  isToday(date?: Date): boolean {
    if (date) {
      return date.toDateString() === new Date().toDateString();
    } else {
      return this.selectedDate.toDateString() === new Date().toDateString();
    }
  }

  async newAppointment(prefillData?: AppointmentBookingData): Promise<void> {
    await this.loadingService.load(this.translations['loading-appointment-bookings']);
    this.bookingService
      .getBookingForm()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        take(1),
        catchError((error) => {
          this.loadingService
            .stop()
            .catch((nestedError) => throwError(() => new Error(nestedError)));
          return throwError(() => error);
        }),
      )
      .subscribe({
        next: (form: DynamicForm) => {
          this.showAppointmentCreationModal(form, prefillData);
        },
      });
  }

  private showAppointmentCreationModal(
    form: DynamicForm,
    prefillData?: AppointmentBookingData,
  ): void {
    if (prefillData) {
      this.updateFormFieldsWithPrefillData(form, prefillData);
    }
    this.processAppointmentCreation(form, prefillData);
  }

  private updateFormFieldsWithPrefillData(
    form: DynamicForm,
    prefillData: AppointmentBookingData,
  ): void {
    form.getAllFieldItems().forEach((field: DynamicDataField) => {
      const preField: FieldTransferData = prefillData.typeFormData.find((f: FieldTransferData) =>
        f.identifier.isEqualTo(field.identifier),
      );
      if (preField) {
        if (preField.value) {
          field.value.defaultValue = preField.value;
        }
        if (preField.options) {
          field.value.options = preField.options;
        }
        if (preField.value) {
          field.value.value = preField.value;
        }
      }
    });
  }

  private processAppointmentCreation(form: DynamicForm, prefillData: AppointmentBookingData): void {
    this.popupService
      .showAppointmentBookingModal(form, false, prefillData?.createId)
      .then((result: OverlayEventDetail) => {
        if (result.role === OverlayEventRole.save && result.data) {
          this.handleReloadOnAppointmentCreation(result.data);
        }
      });
  }

  private handleReloadOnAppointmentCreation(appointmentId: string): void {
    if (this.appointmentsReloadAfterCreation.observed) {
      this.appointmentsReloadAfterCreation.emit(appointmentId);
    } else {
      console.warn(
        'Failed to trigger reload after appointment creation. Attempted to use an event emitter on a component that has already been destroyed.',
      );
    }
  }

  onPrevClick(): void {
    if (this.monthCalendar) {
      this.monthCalendar.getApi().prev();
    }

    delete this.selectedAppointment;
    this.selectedDate.setDate(this.selectedDate.getDate() - 1);
    this.selectedDateAppointments = [
      ...this.mappedAppointmentGroups.future,
      ...this.mappedAppointmentGroups.past,
    ].find((ma) => ma.date.toDateString() == this.selectedDate.toDateString());
  }

  onNextClick(): void {
    if (this.monthCalendar) {
      this.monthCalendar.getApi().next();
    }

    delete this.selectedAppointment;
    this.selectedDate.setDate(this.selectedDate.getDate() + 1);
    this.selectedDateAppointments = [
      ...this.mappedAppointmentGroups.future,
      ...this.mappedAppointmentGroups.past,
    ].find((ma) => ma.date.toDateString() == this.selectedDate.toDateString());
  }

  onTodayClick(): void {
    if (this.monthCalendar) {
      this.monthCalendar.getApi().today();
    }

    delete this.selectedAppointment;
    this.selectedDate = new Date();
    this.selectedDateAppointments = [
      ...this.mappedAppointmentGroups.future,
      ...this.mappedAppointmentGroups.past,
    ].find((ma) => ma.date.toDateString() == this.selectedDate.toDateString());
  }

  private onEventClick(clickArgs: EventClickArg) {
    this.onAppointmentClick(clickArgs.event.extendedProps['appointment'], true).then(() => {
      this.selectedView = AppointmentViewType.DAY;
    });
  }

  async onAppointmentClick(
    appointment: AppointmentDetails,
    preventDeselection: boolean = false,
  ): Promise<void> {
    if (this.selectedAppointment === appointment && !preventDeselection) {
      this.deselectAppointment();
      return;
    }
    this.handleAppointmentSelection(appointment);
    this.updatePageHeaderForCompactCalendarView();
  }

  private deselectAppointment(): void {
    this.selectedAppointment = undefined;
    this.location.go(this.appointmentListViewPath);
  }

  private async handleAppointmentSelection(appointment: AppointmentDetails): Promise<void> {
    this.location.go(`${this.appointmentListViewPath}${appointment.id}`);
    this.selectedAppointment = appointment;
    this.selectedDate = appointment.startDate;
    this.updateSelectedDateAppointments();
    if (this.detailsCalendar) {
      await this.detailsCalendar.reset(
        this.localeDatePipe.transform(this.selectedAppointment.startDate, 'yyyy-MM-dd'),
      );
    }
  }

  private generateCalendarEventsFromAppointments(
    mappedAppointmentGroups: ViewAppointmentsGroup,
  ): CalendarEvent[] {
    return [...mappedAppointmentGroups.future, ...mappedAppointmentGroups.past].flatMap(
      (group: TemplateAppointmentsGroup) =>
        group.appointments.map((mappedAppointment: AppointmentDetails) => ({
          title: mappedAppointment.title,
          date: mappedAppointment.startDate,
          appointment: mappedAppointment,
          icon: this.getAppointmentIconByType(mappedAppointment),
          statusColor: mappedAppointment.status
            ? mappedAppointment.status.hexColor
            : 'var(--color-greyish-100)',
        })),
    );
  }

  private getAppointmentIconByType(appointment: AppointmentDetails): IconDefinition {
    if (appointment.type === AppointmentType.REMOTE) {
      return faPhone;
    } else {
      return faStethoscope;
    }
  }

  activateMonthOverview(value: AppointmentViewType): void {
    this.deselectAppointment();
    this.pageHeaderService.updateHeader(this.appointmentListHeaderConfiguration);
    this.selectedView = value;
  }
}
