import { TableDataType } from './dynamic-table.model';
import { AttributeFieldType } from './sdapi-form-object.model';
import { AttributeNameIdentifier } from './shared.model';

export class Patient {
  pid: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
  gender: string;
  currentCase: string;
  masterPatientRelation: string;
  masterPatientRelationOid: string;
  sourceSystem: string;
  sourceSystemOid: string;
  street: string;
  zipCode: string;
  location: string;
  phoneNumber: string;
  email: string;
  patientID: string;
  avatarUrl: string;

  constructor(profileData?: any) {
    if (profileData) {
      const {
        pid,
        firstName,
        lastName,
        birthDate,
        gender,
        currentCase,
        masterPatientRelation,
        masterPatientRelationOid,
        sourceSystem,
        sourceSystemOid,
        street,
        zipCode,
        location,
        phoneNumber,
        email,
        patientID,
        avatarUrl,
      } = profileData;
      this.pid = pid;
      this.firstName = firstName;
      this.lastName = lastName;
      this.birthDate = birthDate;
      this.gender = gender;
      this.currentCase = currentCase;
      this.masterPatientRelation = masterPatientRelation;
      this.masterPatientRelationOid = masterPatientRelationOid;
      this.sourceSystem = sourceSystem;
      this.sourceSystemOid = sourceSystemOid;
      this.street = street;
      this.zipCode = zipCode;
      this.location = location;
      this.phoneNumber = phoneNumber;
      this.email = email;
      this.patientID = patientID;
      this.avatarUrl = avatarUrl;
    }
  }

  getInitials(): string {
    const firstInitial = this.firstName ? this.firstName[0] : '';
    const lastInitial = this.lastName ? this.lastName[0] : '';
    return `${firstInitial}${lastInitial}`;
  }

  getFullName(): string {
    const firstName = this.firstName || '';
    const lastName = this.lastName || '';
    return `${firstName} ${lastName}`.trim();
  }

  get age(): number {
    const ageInYears = this.getAgeInYears();
    return ageInYears === 0 ? this.getAgeInMonths() : ageInYears;
  }

  getAgeInYears(): number {
    return Math.floor(
      Math.abs(Date.now() - new Date(this.birthDate).getTime()) / (1000 * 3600 * 24) / 365.25,
    );
  }

  getAgeInMonths(): number {
    return Math.floor(
      Math.abs(Date.now() - new Date(this.birthDate).getTime()) / (1000 * 3600 * 24) / 30.437,
    );
  }
}

export class PatientAttributeNames {
  public static readonly pid = 'SYS_PATIENT.PID';
  public static readonly firstName = 'SYS_PATIENT.PATIENTS_GIVEN_NAME';
  public static readonly lastName = 'SYS_PATIENT.PATIENTS_FAMILY_NAME';
  public static readonly birthDate = 'SYS_PATIENT.PATIENTS_BIRTH_DATE';
  public static readonly gender = 'SYS_PATIENT.PATIENTS_SEX';
  public static readonly currentCase = 'CURRENT_VISIT_INFORMATION';
  public static readonly masterPatientRelation = 'MASTER_PATIENT_INFORMATION';
  public static readonly masterPatientRelationOid = 'MASTER_PATIENT_INFORMATION_OID';
  public static readonly sourceSystem = 'SOURCE_SYSTEM';
  public static readonly sourceSystemOid = 'SOURCE_SYSTEM_OID';
  public static readonly street = 'SYS_PATIENT.PATIENTS_ADDRESS_STREET';
  public static readonly zipCode = 'SYS_PATIENT.PATIENTS_ADDRESS_ZIP_CODE';
  public static readonly location = 'SYS_PATIENT.PATIENTS_ADDRESS_DOMICILE';
  public static readonly phoneNumber = 'SYS_PATIENT.PHONE1';
  public static readonly email = 'SYS_PATIENT.EMAIL1';
  public static readonly patientID = 'SYS_OBJECT.OBJ_ID';
  public static readonly avatarUrl = 'SYS_PATIENT.IMAGE_URL';
}

export class PrioritizedPatientDataAttributes {
  public static readonly salutation = new AttributeNameIdentifier('TEMP.TITLE');
  public static readonly firstName = new AttributeNameIdentifier('TEMP.GIVEN_NAME');
  public static readonly lastName = new AttributeNameIdentifier('TEMP.FAMILY_NAME');
}

export class PatientDataField {
  name: string;
  value: string;
  type: TableDataType | AttributeFieldType;
}
