import { Component, Input } from '@angular/core';
import { FilterSegmentCollection } from 'projects/core/src/lib/models/dynamic-table.model';
import { LaboratoryResultTable } from 'projects/core/src/lib/models/laboratory-results.model';

@Component({
  selector: 'lib-laboratory-filter-popup',
  templateUrl: './laboratory-filter-popup.component.html',
})
export class LaboratoryFilterPopupComponent {
  @Input() isModalSheet: boolean;
  @Input() segmentCollection: FilterSegmentCollection[];
  @Input() tableData: LaboratoryResultTable;
}
