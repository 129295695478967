import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OverlayEventDetail } from '@ionic/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { DocumentDetails } from 'projects/core/src/lib/models/documents.model';
import { Invoker } from 'projects/core/src/lib/models/invoker-body.model';
import {
  DocumentTranslationKeys,
  ModalResult,
} from 'projects/core/src/lib/models/modal-action.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { DocumentsService } from 'projects/core/src/lib/services/documents.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { ModalActionService } from 'projects/core/src/lib/services/modal-action.service';
import { FileSizePipe } from 'projects/theme/src/lib/pipes/file-size.pipe';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-document-row',
  templateUrl: './document-row.component.html',
  styleUrls: ['./document-row.component.scss'],
})
@UntilDestroy()
export class DocumentRowComponent {
  @Output() documentListReload = new EventEmitter<void>();

  @Input({ required: true }) document: DocumentDetails;
  @Input({ required: false }) hasExtendedView = true;
  @Input({ required: false }) hasNavigationIcon = true;

  constructor(
    private breakPointService: BreakpointService,
    private documentsService: DocumentsService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private fileSizePipe: FileSizePipe,
    private modalActionService: ModalActionService,
  ) {}

  async viewDocument(documentDetails: DocumentDetails): Promise<void> {
    await this.loadingService.load(
      await firstValueFrom(this.translateService.get('shared.documents.requesting-document')),
    );
    const invoker: Invoker = await firstValueFrom(
      this.documentsService.retrievePrimaryInvokerAndUpdateDocumentDetails(documentDetails),
    );
    await firstValueFrom(this.documentsService.getDocumentsDataType(documentDetails));
    this.showDocumentViewModal(documentDetails, invoker);
  }

  private async showDocumentViewModal(
    documentDetails: DocumentDetails,
    invoker: Invoker,
  ): Promise<void> {
    const event: OverlayEventDetail = await this.modalActionService.resolveDocumentModalView(
      documentDetails,
      invoker,
    );
    if (this.modalActionService.isActionTriggeringEvent(event)) {
      this.handleModalActionEvent(event);
    }
  }

  private async handleModalActionEvent(event: OverlayEventDetail): Promise<void> {
    const result: ModalResult = await this.modalActionService.handleInvokerMethodOfActionButton(
      event,
      DocumentTranslationKeys,
    );

    if (this.modalActionService.shouldReloadDataSet(result)) {
      this.documentListReload.emit();
    }
  }

  get isExtendedView(): boolean {
    return this.hasExtendedView && this.breakPointService.isAbove('sm');
  }

  get documentSize(): string {
    return this.fileSizePipe
      .transform(this.document.size, { roundingMethod: 'ceil', round: 0 })
      .toString();
  }
}
