import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Injector, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Patient } from 'projects/core/src/lib/models/patient.model';

@Pipe({
  name: 'ageCount',
  pure: false,
})
export class AgeCountPipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  constructor(
    private translateService: TranslateService,
    private injector: Injector,
  ) {
    this.asyncPipe = new AsyncPipe(injector.get(ChangeDetectorRef));
  }

  ngOnDestroy(): void {
    this.asyncPipe.ngOnDestroy();
  }

  transform(patient: Patient): string {
    const ageInYears = patient.getAgeInYears();
    if (ageInYears === 0) {
      return (
        patient.age +
        ' ' +
        (patient.getAgeInMonths() === 1
          ? this.asyncPipe.transform(this.translateService.get('general.month'))
          : this.asyncPipe.transform(this.translateService.get('general.months')))
      );
    } else {
      return (
        patient.age +
        ' ' +
        (ageInYears === 1
          ? this.asyncPipe.transform(this.translateService.get('general.year'))
          : this.asyncPipe.transform(this.translateService.get('general.years')))
      );
    }
  }
}
