<lib-modal-header [title]="title"></lib-modal-header>

<ion-content [scrollY]="false">
  <lib-generic-table-container
    class="stretch-container fill-height"
    [data]="data"
    [isRowDataLoading]="isRowDataLoading"
    [isCard]="false"
    (eventMessage)="resolveEventMessage($event)">
    @if (actionButton) {
      <lib-auto-layout actionButtonCollection class="toolbar-item card-layout" [gap]="[1]">
        <ion-button
          color="primary"
          data-api-id="order-type-list"
          class="ion-no-margin"
          (click)="dismissModalWithActionButtonEvent()">
          <fa-icon size="xs" slot="start" [icon]="actionButton.icon"></fa-icon>
          <span class="ion-hide-md-down">{{ actionButton.label.extended | translate }}</span>
          <span class="ion-hide-md-up">{{ actionButton.label.short | translate }}</span>
        </ion-button>
      </lib-auto-layout>
    }

    @if (actionButton) {
      <lib-auto-layout notificationBoxButtons class="toolbar-item card-layout" [gap]="[1]">
        <ion-button
          color="secondary"
          data-api-id="order-type-list"
          class="ion-no-margin"
          (click)="dismissModalWithActionButtonEvent()">
          <fa-icon size="xs" slot="start" [icon]="actionButton.icon"></fa-icon>
          <span>{{ actionButton.label.extended | translate }}</span>
        </ion-button>
      </lib-auto-layout>
    }
  </lib-generic-table-container>
</ion-content>
