import { APIError } from 'projects/core/src/lib/data/errors.data';
import {
  TableDataType,
  TableDefinition,
  TableHeaderItem,
  TableList,
  TableListItem,
  TableType,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { DataType, DynamicDataField, DynamicForm, FormatOptions } from '../models/form.model';
import { FormatOptionIdentifier, FormItem } from '../models/sdapi-form-object.model';
import { ObjectType } from '../models/sdapi-object.model';
import {
  TieConstraintPopupListObject,
  TieTableItemObject,
  TieTableObjectList,
  TieTableReport,
} from '../models/sdapi-table-object.model';
import { AttributeNameIdentifier, StatusInfoLabel } from '../models/shared.model';

export class TableMapper {
  public static mapTable(resource: TieTable, includeStatus: boolean = true): TableList {
    switch (resource.t) {
      case ObjectType.objectList:
        return this.mapTableFromObjectList(resource, includeStatus);
      case ObjectType.report:
        return this.mapTableFromReport(resource);
      default:
        throw new APIError('Resource type not supported in the TableResourceMapper.');
    }
  }

  public static mapTableFromObjectList(
    resource: TieTableObjectList,
    includeStatus: boolean = true,
  ): TableList {
    const tableHeader: TableHeaderItem[] = this.mapTableHeaderResource(resource, includeStatus);
    const tableRows: TableListItem[] = this.mapTableRowsObjectListResource(resource, includeStatus);
    const tableDefinition: TableDefinition = {
      title: resource.objName,
      tableType: TableType.generic,
    };

    return new TableList(tableHeader, tableRows, tableDefinition);
  }

  public static mapTableFromReport(resource: TieTableReport): TableList {
    const tableHeader: TableHeaderItem[] = this.mapTableHeaderResource(resource, false);
    const tableRows: TableListItem[] = this.mapTableRowsReportResource(resource);
    const tableDefinition: TableDefinition = {
      title: resource.header,
      tableType: TableType.generic,
    };

    return new TableList(tableHeader, tableRows, tableDefinition);
  }

  public static mapConstraintPopupObjectList(
    constraintObjectList: TieConstraintPopupListObject,
  ): TieTableObjectList {
    return constraintObjectList.objlist;
  }

  public static mapTableHeaderResource(
    resource: TieTable,
    includeStatus: boolean = true,
  ): TableHeaderItem[] {
    try {
      const tableHeader: TableHeaderItem[] = TableMapper.mapTableHeaderItemsFromResource(resource);
      if (includeStatus) {
        tableHeader.unshift({
          value: 'Status',
          type: TableDataType.text,
          identifier: new AttributeNameIdentifier('status'),
        });
      }

      return tableHeader;
    } catch (error) {
      throw new APIError('Mapping of table header failed in the TableResourceMapper.', error);
    }
  }

  private static mapTableHeaderItemsFromResource(resource: TieTable): TableHeaderItem[] {
    if (resource.showTypes?.BODY?.items) {
      return TableMapper.mapShowTypeItemsToHeaderItems(resource);
    } else {
      return TableMapper.mapAttributeNamesToHeaderItems(resource);
    }
  }

  private static mapShowTypeItemsToHeaderItems(resource: TieTable): TableHeaderItem[] {
    return [...resource.showTypes.BODY.items]
      .map(
        (item: FormItem, index: number): TableHeaderItem =>
          TableMapper.mapFormItemToHeaderItem(resource, item, index),
      )
      .filter((item: TableHeaderItem) => item !== null);
  }

  private static mapAttributeNamesToHeaderItems(resource: TieTable): TableHeaderItem[] {
    return [...resource.attrNames].map((header: string, i: number) => ({
      value: header,
      type: resource.attrTypes[i],
      format: new FormatOptions(resource.attrFormats[i] as FormatOptionIdentifier),
      identifier: TableMapper.resolveAttributeName(resource, i),
    }));
  }

  private static mapFormItemToHeaderItem(
    resource: TieTable,
    item: FormItem,
    index: number,
  ): TableHeaderItem | null {
    if (item.hidden) {
      return null;
    }

    return {
      value: item.displayName,
      type: resource.attrTypes[index],
      format: new FormatOptions(resource.attrFormats[index] as FormatOptionIdentifier),
      identifier: TableMapper.resolveAttributeName(resource, index),
    };
  }

  static resolveAttributeName(resource: TieTable, i: number): AttributeNameIdentifier {
    switch (resource.t) {
      case ObjectType.objectList:
        return this.resolveAttributeNameObjectList(resource, i);
      case ObjectType.report:
        return new AttributeNameIdentifier(resource.attrNames[i]);
      default:
        throw new APIError('Resource type not supported in the TableResourceMapper.');
    }
  }

  static resolveAttributeNameObjectList(
    resource: TieTableObjectList,
    i: number,
  ): AttributeNameIdentifier {
    const showTypeItems: FormItem[] = [...resource.showTypes.BODY.items];
    return new AttributeNameIdentifier(showTypeItems[i].attributeName);
  }

  public static mapTableRowsObjectListResource(
    resource: TieTableObjectList,
    includeStatus: boolean = true,
  ): TableListItem[] {
    try {
      const tableRows: TableListItem[] = [];
      const tableResourceList: TieTableItemObject[] = [...resource.items];
      for (const row of tableResourceList) {
        const columns: string[] = [...row.attrValues].filter((_, index: number) => {
          return !resource.showTypes.BODY.items[index].hidden;
        });
        if (includeStatus && row.statusInfo) {
          const label = new StatusInfoLabel(row.statusInfo.label);
          columns.unshift(label.normalizedValue);
        }
        tableRows.push({
          id: row.objId?.toString(),
          name: row.objName,
          columns,
          behaviorInvokers: row.onPreview.methods,
        });
      }
      return tableRows;
    } catch (error) {
      throw new APIError(
        'Mapping of object list table rows failed in the TableResourceMapper.',
        error,
      );
    }
  }

  public static mapTableRowsReportResource(resource: TieTableReport): TableListItem[] {
    try {
      const tableRows: TableListItem[] = [];
      const tableResourceList: TieTableItemObject[] = [...resource.items];
      for (const [id, row] of Object.entries(tableResourceList)) {
        const columns: string[] = [...row.attrValues];
        tableRows.push({
          id,
          columns,
        });
      }
      return tableRows;
    } catch (error) {
      throw new APIError('Mapping of report table rows failed in the TableResourceMapper.', error);
    }
  }

  public static addTableDefinitionDetailsToFormItems(form: DynamicForm): DynamicForm {
    form.body.forEach((tabGroup: DynamicDataField) => {
      tabGroup.fieldGroup.forEach((field: DynamicDataField) => {
        if (field.type === DataType.grid) {
          field.value.table.title = tabGroup.name;
          field.value.table.storageKey = `${tabGroup.name}ColumnPreference`;
          field.value.table.type = TableType.patientData;
        }
      });
    });
    return form;
  }
}

type TieTable = TieTableObjectList | TieTableReport;
