import { Component, Input } from '@angular/core';
import { PopupService } from 'projects/core/src/lib/services/popup.service';
import { PatientMenuKeys } from 'projects/shared/src/lib/components/patient-summary-browser/patient-summary-list/patient-menu.model';
import { MenuItem } from 'projects/shared/src/lib/models/menu.model';
import { PatientMenuService } from 'projects/shared/src/lib/services/patient-menu.service';

@Component({
  selector: 'lib-navigation-menu-popover',
  templateUrl: './navigation-menu-popover.component.html',
  styleUrl: './navigation-menu-popover.component.scss',
})
export class NavigationMenuPopoverComponent {
  @Input() menu: MenuItem[];
  @Input() patientId: string;

  constructor(
    private readonly patientMenuService: PatientMenuService,
    private readonly popupService: PopupService,
  ) {}

  async handleMenuItemSelection(item: MenuItem): Promise<void> {
    await this.popupService.dismissTopOverlayPopover();
    if (item.idSuffix === PatientMenuKeys.setShow) {
      await this.patientMenuService.openPatientAccessGrantingModal(this.patientId);
    }
  }
}
