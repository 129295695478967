import { DynamicForm } from './form.model';

export class Profile {
  profileID: string;
  userName: string;
  fullName: string;
  detailsForm: DynamicForm;

  constructor(profileData?: any) {
    if (profileData) {
      const { profileID, fullName, detailsForm, userName } = profileData;

      this.userName = userName;
      this.profileID = profileID;
      this.fullName = fullName;
      this.detailsForm = detailsForm;
    }
  }

  getInitials(): string {
    const [firstName, ...middleAndLastName] = this.fullName.split(' ');
    const lastName = middleAndLastName.pop();

    return `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`.toUpperCase();
  }
}

export enum UserSettingsItem {
  userSettings = 'User Settings',
}

export class ProfileSettings {
  userProfileData: UserProfileDataSettings;
  tableData: TableDataSettings;
  lastVisitedPatientsIdList: string[];

  constructor(
    userProfileData: UserProfileDataSettings = new UserProfileDataSettings(),
    tableData: TableDataSettings = new TableDataSettings(),
    lastVisitedPatientsIdList: string[] = [],
  ) {
    this.userProfileData = userProfileData;
    this.tableData = tableData;
    this.lastVisitedPatientsIdList = lastVisitedPatientsIdList;
  }
}

export class UserProfileDataSettings {
  tosRead: boolean;

  constructor(tosRead: boolean = false) {
    this.tosRead = tosRead;
  }
}

export class TableDataSettings {
  orderTable: TableSettings;
  patientDataTable: TableSettings;
  expensesTable: TableSettings;
  medicalCertificatesTable: TableSettings;
  treatmentsTable: TableSettings;
  genericTable: TableSettings;

  constructor(
    orderTable: TableSettings = new TableSettings(),
    patientDataTable: TableSettings = new TableSettings(),
    expensesTable: TableSettings = new TableSettings(),
    medicalCertificatesTable = new TableSettings(),
    treatmentsTable = new TableSettings(),
    genericTable = new TableSettings(),
  ) {
    this.orderTable = orderTable;
    this.patientDataTable = patientDataTable;
    this.expensesTable = expensesTable;
    this.medicalCertificatesTable = medicalCertificatesTable;
    this.treatmentsTable = treatmentsTable;
    this.genericTable = genericTable;
  }
}

export class TableSettings {
  columnPreferences: { [key: string]: string };

  constructor(columnPreferences: { [key: string]: any } = undefined) {
    this.columnPreferences = columnPreferences;
  }
}

export enum ProfileSettingsValueField {
  information = 'SYS_ATTRIBUTE_CLOB.0',
}

export enum ProfileFieldKey {
  username = 'SYS_P_USER.USER_NAME',
  fullName = 'SYS_P_USER.FULLNAME',
  HL7DoctorID = 'SYS_P_USER.HL7_DOCTOR_ID',
  userID = 'SYS_P_USER.P_USER_ID',
  ADName = 'SYS_P_USER.EXT_AUTH_NAME1',
  ADName2 = 'SYS_P_USER.EXT_AUTH_NAME2',
  email1 = 'SYS_P_USER.EMAIL1',
  mobile1 = 'SYS_P_USER.MOBILE1',
  email2 = 'SYS_P_USER.EMAIL2',
  mobile2 = 'SYS_P_USER.MOBILE2',
  email3 = 'SYS_P_USER.EMAIL3',
  mobile3 = 'SYS_P_USER.MOBILE3',
  position = 'SYS_ATTRIBUTE.T1',
  openMedicalID = 'SYS_P_USER.OPENMEDICAL_ID',
  department = 'SYS_ATTRIBUTE.T2',
  docboxID = 'SYS_P_USER.DOCBOX_ID',
  language = 'SYS_P_USER.LANG_CODE',
  userType = 'SYS_P_USER.USER_TYPE',
  EAN = 'SYS_P_USER.EAN',
  AsasID = 'SYS_P_USER.ASAS_ID',
  sysContact = 'SYS_CONTACT.*',
}

export enum SubscriptionFieldKey {
  serviceId = 'SYS_OBJECT_SUBSCRIPTION.SERVICE_ID',
  recipientNr = 'SYS_OBJECT_SUBSCRIPTION.RECIPIENT_NR',
  restriction = 'SYS_OBJECT_SUBSCRIPTION.RESTRICTION',
}

export const subscriptionFieldKeys: SubscriptionFieldKey[] = [
  SubscriptionFieldKey.serviceId,
  SubscriptionFieldKey.recipientNr,
  SubscriptionFieldKey.restriction,
];

export const patientProfileContactInfoFieldKeys: ProfileFieldKey[] = [
  ProfileFieldKey.fullName,
  ProfileFieldKey.email1,
  ProfileFieldKey.mobile1,
  ProfileFieldKey.email2,
  ProfileFieldKey.mobile2,
  ProfileFieldKey.email3,
  ProfileFieldKey.mobile3,
  ProfileFieldKey.sysContact,
];

export const patientProfileAccountInfoFieldKeys: ProfileFieldKey[] = [
  ProfileFieldKey.username,
  ProfileFieldKey.language,
];

export const doctorProfileContactInfoFieldKeys: ProfileFieldKey[] = [
  ProfileFieldKey.fullName,
  ProfileFieldKey.email1,
  ProfileFieldKey.mobile1,
  ProfileFieldKey.email2,
  ProfileFieldKey.mobile2,
  ProfileFieldKey.email3,
  ProfileFieldKey.mobile3,
  ProfileFieldKey.userID,
  ProfileFieldKey.sysContact,
];

export const doctorProfileAccountInfoFieldKeys: ProfileFieldKey[] = [
  ProfileFieldKey.username,
  ProfileFieldKey.language,
  ProfileFieldKey.userType,
  ProfileFieldKey.HL7DoctorID,
  ProfileFieldKey.ADName,
  ProfileFieldKey.ADName2,
  ProfileFieldKey.openMedicalID,
  ProfileFieldKey.docboxID,
  ProfileFieldKey.EAN,
  ProfileFieldKey.AsasID,
  ProfileFieldKey.position,
  ProfileFieldKey.department,
];

export enum ProfileSection {
  contactInfo,
  accountInfo,
  subscriptionInfo,
  furtherSubscriptionInfo,
}
