<lib-auto-layout direction="vertical" horizontal="stretch" [grow]="1" class="widget-card">
  <lib-auto-layout vertical="center" [gap]="[10]" [padding]="[15, 20, 10]">
    <fa-icon [icon]="icons.stethoscope"></fa-icon>
    <h2 data-api-id="treatment-list" [grow]="1" class="no-margin">
      {{ 'treatments.your-recent-treatments' | translate }}
    </h2>
  </lib-auto-layout>
  @if (isLoading) {
    <ng-container *ngTemplateOutlet="skeleton"></ng-container>
  } @else if (treatmentsList.length !== 0) {
    <lib-auto-layout
      direction="vertical"
      horizontal="stretch"
      [gap]="[1]"
      [grow]="1"
      [padding]="[1, 0]"
      id="treatment-list">
      @for (treatment of treatmentsList; track treatment) {
        <button
          class="treatment-item"
          (click)="navigateToTreatmentDetails(treatment.id)"
          [class.dateIsInPast]="treatment.dateIsInPast">
          <lib-auto-layout
            direction="horizontal"
            [gap]="[20]"
            [padding]="[9, 20]"
            vertical="center">
            <fa-icon
              [icon]="treatment.dateIsInPast ? icons.check : icons.calendar"
              class="treatment-item-icon"></fa-icon>
            <lib-auto-layout direction="vertical" [grow]="1" [gap]="[5]">
              <span class="treatment-title h3 trim-text-inline">{{ treatment.title }}</span>
              <span class="treatment-date trim-text-inline">
                {{
                  (treatment.date | localeDate: 'shortFullDate') || 'general.unknown' | translate
                }}
              </span>
            </lib-auto-layout>
            <fa-icon [icon]="icons.chevron"></fa-icon>
          </lib-auto-layout>
        </button>
      }
    </lib-auto-layout>
  } @else if (isDataAccessRestricted) {
    <lib-auto-layout direction="vertical" [grow]="1">
      <lib-notification-box-component
        [grow]="1"
        [icon]="'user-lock'"
        [padding]="[40]"
        [background]="'tertiary'"
        [message]="'shared.treatments.no-treatments-access' | translate">
      </lib-notification-box-component>
    </lib-auto-layout>
  } @else {
    <lib-auto-layout direction="vertical" [padding]="[1, 0]" [grow]="1" class="no-treatments">
      <lib-notification-box-component
        [grow]="1"
        [message]="'treatments.no-treatments-available' | translate"
        [padding]="[40]">
      </lib-notification-box-component>
    </lib-auto-layout>
  }
  @if (treatmentsList.length !== 0) {
    <lib-auto-layout horizontal="center" [padding]="[15]" id="button-section">
      <ion-button
        class="treatments-page-link p3"
        color="medium"
        fill="clear"
        expand="block"
        routerLink="/portal/treatments">
        {{ 'treatments.show-all-treatments' | translate }}
        <fa-icon slot="end" [icon]="icons.arrow"></fa-icon>
      </ion-button>
    </lib-auto-layout>
  }
</lib-auto-layout>

<ng-template #skeleton>
  <lib-auto-layout
    direction="vertical"
    horizontal="stretch"
    [gap]="[1]"
    [grow]="1"
    [padding]="[1, 0]"
    id="skeleton">
    @for (i of [1, 2, 3]; track i) {
      <lib-auto-layout
        direction="vertical"
        [padding]="[5, 20]"
        vertical="center"
        class="skeleton-item">
        <ion-skeleton-text class="rounded-small like-p" animated></ion-skeleton-text>
        <ion-skeleton-text class="date rounded-small like-p" animated></ion-skeleton-text>
      </lib-auto-layout>
    }
  </lib-auto-layout>
</ng-template>
