<ng-template #sectionTitle>
  @if (breakpointService.isAbove('sm')) {
    <lib-auto-layout direction="vertical" [padding]="[10, 20]">
      <h3 class="ion-no-margin">
        {{ 'shared.treatments.information' | translate }}
      </h3>
    </lib-auto-layout>
  }
</ng-template>

@if (!this.isLoading && treatment) {
  @if (hasInformation) {
    <cdk-virtual-scroll-viewport
      #scrollViewPort
      itemSize="58"
      [minBufferPx]="virtualScrollService.getBufferPx('min')"
      [maxBufferPx]="virtualScrollService.getBufferPx('max')"
      class="stretch-flex stretch-self">
      <ng-container *ngTemplateOutlet="sectionTitle"></ng-container>

      @if (treatment.details) {
        @for (detail of treatment.details | detailsToList: iconSet; track $index) {
          @if (detail.value) {
            <lib-information-item [item]="detail"></lib-information-item>
          }
        }
      }

      @if (showNotesButton) {
        <ion-button
          data-api-id="treatment-notes"
          (click)="openTreatmentNotes.emit()"
          color="tertiary"
          class="navigation p3"
          expand="block">
          {{ 'shared.treatments.open-notes' | translate }}
          <fa-icon slot="end" [icon]="icons.notes"></fa-icon>
        </ion-button>
      }

      @if (showToDocumentsButton) {
        <ion-button
          (click)="activateDocumentSection()"
          color="tertiary"
          class="navigation p3"
          expand="block">
          {{ 'shared.treatments.navigation-to-documents' | translate }}
          <fa-icon slot="end" [icon]="icons.chevron"></fa-icon>
        </ion-button>
      }

      @if (hasInformationDocuments) {
        <lib-auto-layout direction="vertical" [padding]="[10]">
          <lib-information-card
            [style]="'warning'"
            [title]="'shared.treatments.important-information' | translate"
            class="stretch-self">
            <div *cdkVirtualFor="let document of treatment.information" infoCardContent>
              <lib-information-document-row
                [document]="document"
                (documentListReload)="refreshTreatment.emit($event)">
              </lib-information-document-row>
            </div>
          </lib-information-card>
        </lib-auto-layout>
      }

      @if (hasDoctorContacts) {
        <lib-doctor-contacts [doctorContacts]="treatment.doctorContacts"></lib-doctor-contacts>
      }
    </cdk-virtual-scroll-viewport>
  } @else {
    <ng-container *ngTemplateOutlet="sectionTitle"></ng-container>

    <lib-notification-box-component
      [grow]="1"
      [message]="'shared.treatments.no-information' | translate">
    </lib-notification-box-component>

    @if (treatment.hasDocumentUpload) {
      <div class="stretch-container gap"></div>
    }
  }
} @else {
  <ng-container *ngTemplateOutlet="sectionTitle"></ng-container>
  @for (item of maxRowAmountOfInfoItems; track $index) {
    <ion-skeleton-text class="rounded-small like-h3" animated></ion-skeleton-text>
  }

  <lib-documents-skeleton
    class="skeleton-animation"
    [lines]="maxRowAmountOfInfoDocuments"
    [hasExtendedView]="false">
  </lib-documents-skeleton>
}
