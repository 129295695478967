import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AlertBehaviourPresets } from 'projects/core/src/lib/data/alert-behavior.data';
import { AlertMessagesData } from 'projects/core/src/lib/data/alert-messages.data';
import { AlertHandlerActions } from 'projects/core/src/lib/services/alert-handler.service';
import { AlertService } from 'projects/core/src/lib/services/alert.service';
import { CustomHttpHeaders } from 'projects/core/src/lib/services/custom-http-headers';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const AlertOnHttpErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const alertService: AlertService = inject(AlertService);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (
        req.headers.get(CustomHttpHeaders.XNoAlertInterceptor) !== 'true' &&
        error.status !== 401 &&
        error.status !== 404
      ) {
        alertService.presentAlert(
          AlertMessagesData.httpRequestError,
          AlertHandlerActions.confirmable,
          AlertBehaviourPresets.persistentAndModalDestructive,
        );
      }
      return throwError(() => error);
    }),
  );
};
