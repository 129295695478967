@if (tableData) {
  <div class="table-wrapper">
    <drag-scroll #dragScroll [class.dragging]="isDragging" [drag-disabled]="isDragDisabled">
      <table>
        <thead>
          <tr>
            <th class="fixed p" (mousedown)="disableDrag()" (mouseup)="enableDrag()">
              <div class="stretch-container toolbar-item card-layout">
                <ion-button
                  [color]="tableData.hasFiltersActive ? 'primary' : 'tertiary'"
                  [id]="tableName + 'laboratory-filter-button'"
                  class="stretch-self tertiary"
                  [attr.aria-label]="'shared.tables.options' | translate"
                  (click)="openFilterOverlay()"
                  #trigger="cdkOverlayOrigin"
                  cdkOverlayOrigin
                  (mouseenter)="showInfoOverlay(tableName)"
                  (mouseleave)="dismissInfoOverlay($event, connectedOverlay)">
                  <fa-icon icon="columns"></fa-icon>
                </ion-button>
                <ng-template
                  cdkConnectedOverlay
                  #connectedOverlay="cdkConnectedOverlay"
                  [cdkConnectedOverlayOrigin]="trigger"
                  [cdkConnectedOverlayOpen]="isOverlayOpen(tableName)"
                  (attach)="handleOverlayLayout(connectedOverlay)">
                  <div class="popover-wrapper filter-info" #overlayContainer>
                    <span class="p3">{{
                      'shared.laboratory-results.filter-options' | translate
                    }}</span>
                  </div>
                </ng-template>
              </div>
            </th>
            @for (column of tableData.fixedColumns; track column.name) {
              <th class="fixed p" (mousedown)="disableDrag()" (mouseup)="enableDrag()">
                <span>
                  <strong>{{ escapeNewLine(column.name) }}</strong>
                </span>
              </th>
            }
            @for (column of tableData.columns; track column.name) {
              <th class="p">
                <strong>{{ escapeNewLine(column.name) }}</strong>
              </th>
            }
          </tr>
        </thead>
        @if (tableData.rows.length && tableData.hasVisibleRows) {
          <tbody>
            @for (row of tableData.rows; let rowIndex = $index; track rowIndex) {
              @if (row.visible) {
                <tr>
                  <td class="fixed p" (mousedown)="disableDrag()" (mouseup)="enableDrag()">
                    <div class="stretch-container toolbar-item card-layout">
                      <button
                        type="button"
                        [disabled]="isRowEmpty(row)"
                        [attr.aria-label]="'shared.laboratory-results.open-graph-view' | translate"
                        [attr.title]="'shared.laboratory-results.open-graph-view' | translate"
                        class="graph-indicator tertiary"
                        (click)="openGraphModal(tableData.columns, row.cells, row.fixedCells)">
                        <fa-icon icon="chart-line" size="sm"></fa-icon>
                      </button>
                    </div>
                  </td>
                  @for (cell of row.fixedCells; let columnIndex = $index; track columnIndex) {
                    <td class="fixed p" (mousedown)="disableDrag()" (mouseup)="enableDrag()">
                      <span
                        [id]="constructPopupId('fixed-value', rowIndex, columnIndex)"
                        (mousedown)="recordValueSelection()"
                        (click)="
                          showCopyValuePopup(cell.value, 'fixed-value', rowIndex, columnIndex)
                        ">
                        {{ cell.value }}
                      </span>
                    </td>
                  }
                  @for (cell of row.cells; let columnIndex = $index; track columnIndex) {
                    <td class="p" [class.noValue]="!cell.value" [ngStyle]="getCellStyles(cell)">
                      <div class="cell-container">
                        @if (cell.notes?.length) {
                          <div class="icon-section">
                            <div
                              class="info-icon-container"
                              [ngStyle]="getInfoIconContainerStyles(cell)"
                              cdkOverlayOrigin
                              #trigger="cdkOverlayOrigin"
                              (click)="showNotes(cell.notes)"
                              (mouseenter)="showInfoOverlay(rowIndex, columnIndex)"
                              (mouseleave)="dismissInfoOverlay($event, connectedOverlay)">
                              <fa-icon
                                icon="question"
                                size="2xs"
                                [ngStyle]="getInfoIconStyles(cell)"></fa-icon>
                            </div>

                            <ng-template
                              cdkConnectedOverlay
                              #connectedOverlay="cdkConnectedOverlay"
                              [cdkConnectedOverlayOrigin]="trigger"
                              [cdkConnectedOverlayOpen]="isOverlayOpen(rowIndex, columnIndex)"
                              (attach)="handleOverlayLayout(connectedOverlay)">
                              <div class="popover-wrapper" #overlayContainer>
                                <div
                                  class="popover-container"
                                  [innerHTML]="cell.notes[0] | safeHtml"></div>
                                @if (showMoreNoteDetails) {
                                  <div class="note-details">
                                    <div
                                      class="info-icon-container"
                                      [ngStyle]="getInfoIconContainerStyles(cell, true)">
                                      <fa-icon icon="question" size="2xs"></fa-icon>
                                    </div>
                                    <span>{{
                                      'shared.laboratory-results.more-note-details' | translate
                                    }}</span>
                                  </div>
                                }
                              </div>
                            </ng-template>
                          </div>
                        }
                        <div class="cell-data" [ngStyle]="getDataCellStyles(cell)">
                          @if (cell.flag?.icon) {
                            <div
                              class="indicator-container"
                              [ngStyle]="getIndicatorContainerStyles(cell)">
                              <fa-icon [icon]="cell.flag.icon" size="2xs"></fa-icon>
                            </div>
                          }
                          <span
                            [id]="constructPopupId('value', rowIndex, columnIndex)"
                            (mousedown)="recordValueSelection()"
                            (click)="
                              showCopyValuePopup(cell.value, 'value', rowIndex, columnIndex)
                            ">
                            {{ cell.value }}
                          </span>
                        </div>
                      </div>
                    </td>
                  }
                </tr>
              }
            }
          </tbody>
        }
      </table>
    </drag-scroll>

    @if (!tableData.hasVisibleRows) {
      <lib-auto-layout direction="vertical" [padding]="[1, 0]" [grow]="1" class="notification-box">
        <lib-notification-box-component
          [grow]="1"
          [message]="'shared.laboratory-results.no-data-available' | translate">
        </lib-notification-box-component>
      </lib-auto-layout>
    }
  </div>
}
