import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import {
  EventMessage,
  TableConfiguration,
  TableRowState,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { Dimensions } from 'projects/theme/src/lib/directives/on-dimension-change.directive';

@Component({
  selector: 'lib-generic-table-container',
  templateUrl: './generic-table-container.component.html',
  styleUrl: './generic-table-container.component.scss',
})
export class GenericTableContainerComponent implements OnChanges {
  @ViewChild('tableContainer') tableContainer: ElementRef;

  @Output() eventMessage: EventEmitter<EventMessage> = new EventEmitter();

  @Input({ required: true }) data: TableConfiguration;
  @Input() isDataLoading: boolean;
  @Input() isRowDataLoading: boolean;
  @Input() isCard = true;

  isRowStateInitialized = false;
  rowState: TableRowState = new TableRowState();
  skeletonItemList: number[];

  ngOnChanges(): void {
    if (this.data.table && this.tableContainer && !this.isRowStateInitialized) {
      this.updateRowState(this.tableContainer.nativeElement.offsetWidth);
      this.isRowStateInitialized = true;
    }
  }

  onTableDimensionChange(containerDimensions: Dimensions): void {
    this.updateRowState(containerDimensions.width);
  }

  get shouldShowTableListBrowser(): boolean {
    return !this.isLoading && this.data.table?.sortedRows.length && this.isRowStateInitialized;
  }

  get shouldShowNotificationBox(): boolean {
    return !this.isLoading && !this.data.table?.sortedRows.length;
  }

  get isLoading(): boolean {
    return this.isRowDataLoading || this.isDataLoading;
  }

  get isSearchActive(): boolean {
    return !!this.data.searchParams.criterions;
  }

  private get tableContainerWidth(): number {
    return this.tableContainer?.nativeElement?.offsetWidth;
  }

  private updateRowState(containerWidth: number): void {
    this.rowState.setView(this.data, containerWidth);
    this.rowState.setColumnNumberToDisplay(this.data, containerWidth);
    this.skeletonItemList = this.getSkeletonItemCountAsArray();
  }

  private getSkeletonItemCountAsArray(): number[] {
    return this.tableContainerWidth < this.data.expandedViewThreshold
      ? this.data.skeletonRowSet?.mobile
      : this.data.skeletonRowSet?.desktop;
  }
}
