import { Component, OnInit } from '@angular/core';
import { faArrowRight, faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MedicalCertificatesMapper } from 'projects/core/src/lib/mappers/medical-certificates.mapper';
import { TableList } from 'projects/core/src/lib/models/dynamic-table.model';
import { MedicalCertificatesWidgetRow } from 'projects/core/src/lib/models/medical-certificates.model';
import { MedicalCertificatesService } from 'projects/core/src/lib/services/medical-certificates.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-medical-certificates-widget',
  templateUrl: './medical-certificates.component.html',
})
@UntilDestroy()
export class MedicalCertificatesWidgetComponent implements OnInit {
  readonly desktopView: boolean = false;
  medicalCertificates: MedicalCertificatesWidgetRow[] = [];
  skeletonItemsNumber = 3;
  isLoading = true;

  icons = {
    arrow: faArrowRight,
    notesMedical: faNotesMedical,
  };

  constructor(private readonly medicalCertificatesService: MedicalCertificatesService) {}

  async ngOnInit(): Promise<void> {
    await this.initializeMedicalCertificatesList();
  }

  public async initializeMedicalCertificatesList(): Promise<void> {
    try {
      this.isLoading = true;
      const medicalDocumentsTableList: TableList = await firstValueFrom(
        this.medicalCertificatesService.getAllCertificates(),
      );
      const medicalDocumentsWidgetRows: MedicalCertificatesWidgetRow[] =
        MedicalCertificatesMapper.mapMedicalCertificatesTableListToMedicalCertificatesWidgetRows(
          medicalDocumentsTableList,
        );
      this.medicalCertificates = medicalDocumentsWidgetRows
        .filter((expense: MedicalCertificatesWidgetRow) => expense.dateFrom)
        .sort(
          (a: MedicalCertificatesWidgetRow, b: MedicalCertificatesWidgetRow) =>
            new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime(),
        )
        .slice(0, 5);
    } catch (error) {
      this.medicalCertificates = [];
      console.error('Error getExpenses in mp-expenses', error);
    } finally {
      this.isLoading = false;
    }
  }
}
