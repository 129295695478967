<ion-item
  [class.hide-item-icon]="!hasNavigationIcon"
  button="true"
  lines="none"
  (click)="viewExpense(expense)">
  <div class="list-row" [ngClass]="isExtendedView ? 'extended' : 'condensed'">
    <div class="thumbnail-item">
      <fa-icon [icon]="icons.handHoldingDollar"></fa-icon>
    </div>

    @if (!isExtendedView) {
      <lib-auto-layout
        [padding]="[7, 0, 7, 10]"
        direction="vertical"
        vertical="start"
        horizontal="center"
        class="stretch-self trim-text-inline">
        <lib-auto-layout
          [gap]="[10]"
          direction="horizontal"
          horizontal="space-between"
          vertical="center"
          class="stretch-self trim-text-inline">
          <h3 class="ion-no-margin stretch-self trim-text-inline">{{ expense.name }}</h3>

          <span class="detail">
            {{
              expense.amount ? expense.amount : ('shared.documents.no-specification' | translate)
            }}
            {{ expense.currency }}
          </span>
        </lib-auto-layout>
        <lib-auto-layout
          [gap]="[10]"
          direction="horizontal"
          horizontal="space-between"
          vertical="center"
          class="stretch-self trim-text-inline">
          <span class="detail trim-text-inline">
            {{ expense.date | localeDate: 'shortFullDate' }}</span
          >
          <span class="detail">
            {{ expense.status ? expense.status : '' }}
          </span>
        </lib-auto-layout>
      </lib-auto-layout>
    }

    @if (isExtendedView) {
      <div class="details">
        <div class="name-item trim-text-inline">
          <ion-text class="name">{{ expense.name }}</ion-text>
        </div>

        <div class="date-item ion-text-end">
          <ion-text class="detail">
            {{ expense.date | localeDate: 'shortFullDate' }}
          </ion-text>
        </div>

        <div slot="end" class="size-item ion-text-end" data-api-id="document-details">
          <ion-text class="detail">
            {{
              expense.amount ? expense.amount : ('shared.documents.no-specification' | translate)
            }}
            {{ expense.currency }}
          </ion-text>
        </div>
      </div>
    }
  </div>
</ion-item>
