import { Injectable } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { EChartsOption } from 'echarts';
import { LaboratoryResultsMapper } from 'projects/core/src/lib/mappers/laboratory-results.mapper';
import {
  Invoker,
  InvokerBody,
  InvokerMethods,
} from 'projects/core/src/lib/models/invoker-body.model';
import {
  LaboratoryResults,
  laboratoryResultsFilterSegments,
  LaboratoryResultTable,
  PatientObservationResultViewResource,
} from 'projects/core/src/lib/models/laboratory-results.model';
import { FormItem, TieFormObject } from 'projects/core/src/lib/models/sdapi-form-object.model';
import { PatientService } from 'projects/core/src/lib/services/patient.service';
import { SDAPIService } from 'projects/core/src/lib/services/sdapi.service';
import { LaboratoryFilterPopupComponent } from 'projects/shared/src/lib/components/laboratory-results/laboratory-results-table/filter-popup/laboratory-filter-popup.component';
import { LocaleDatePipe } from 'projects/theme/src/lib/pipes/locale-date.pipe';
import { firstValueFrom, from, map, mergeMap, Observable, throwError } from 'rxjs';
import { GraphDataSet, GraphXAxisDetails } from '../models/graph.model';

@Injectable()
export class LaboratoryResultsService {
  constructor(
    private readonly sdapiService: SDAPIService,
    private readonly patientService: PatientService,
    private readonly modalController: ModalController,
    private readonly popoverController: PopoverController,
    private readonly localeDatePipe: LocaleDatePipe,
  ) {}

  async hasAccessToPatientsLaboratoryResults(): Promise<boolean> {
    try {
      const dossier: TieFormObject = await firstValueFrom(this.getPatientDossier());
      return !!dossier.showTypes.BUTTON_PROJECT.items.find(
        (projectItem) =>
          projectItem.invokerRequest.methodName === InvokerMethods.patientObservationResultsView,
      );
    } catch (error) {
      console.warn(`Error while checking access to patient's laboratory results: `, error);
      return false;
    }
  }

  getLaboratoryResults(): Observable<LaboratoryResults> {
    return from(this.getLaboratoryInvoker()).pipe(
      mergeMap((laboratoryInvoker: InvokerBody) =>
        this.sdapiService.invokeMethod<PatientObservationResultViewResource>(laboratoryInvoker),
      ),
      map((results: any) => LaboratoryResultsMapper.mapResponse(results)),
    );
  }

  public async presentFilterModalSheet(
    tableData: LaboratoryResultTable,
  ): Promise<HTMLIonModalElement> {
    return await this.modalController.create({
      component: LaboratoryFilterPopupComponent,
      componentProps: {
        isModalSheet: true,
        segmentCollection: laboratoryResultsFilterSegments,
        tableData,
      },
      cssClass: 'filtering-and-sorting-modal',
      breakpoints: [0, 0.4, 1],
      initialBreakpoint: 0.4,
    });
  }

  public async presentFilterPopover(
    tableData: LaboratoryResultTable,
    tableName: string,
  ): Promise<HTMLIonPopoverElement> {
    return await this.popoverController.create({
      component: LaboratoryFilterPopupComponent,
      componentProps: { segmentCollection: laboratoryResultsFilterSegments, tableData },
      trigger: tableName + 'laboratory-filter-button',
      reference: 'trigger',
      alignment: 'start',
      animated: true,
      cssClass: 'filtering-and-sorting-popover',
      side: 'bottom',
      arrow: false,
    });
  }

  dismissActiveModalSheet(isDesktop: boolean, modal: HTMLIonModalElement): void {
    if (isDesktop && modal) {
      void this.modalController?.dismiss(null, null, modal.id);
    }
  }

  dismissActivePopover(isMobile: boolean, popover: HTMLIonPopoverElement): void {
    if (isMobile && popover) {
      void this.popoverController?.dismiss(null, null, popover.id);
    }
  }

  async constructChartOptions(
    dataSet: GraphDataSet,
    valueTranslation: string,
  ): Promise<EChartsOption> {
    return {
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => this.constructTooltipMessage(dataSet, params, valueTranslation),
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          formatter: (value: string) => `${this.localeDatePipe.transform(value, 'dd.MM.yyyy')}`,
        },
        data: dataSet.xAxis.map((value: GraphXAxisDetails) => value.value),
        deduplication: true,
        axisTick: {
          alignWithLabel: true,
        },
      },
      yAxis: {
        type: 'value',
        name: dataSet.yAxisConfiguration.name,
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize: 14,
          padding: [0, 0, 20, 0],
          fontWeight: 'bold',
          fontStyle: 'italic',
        },
      },
      series: [
        {
          data: dataSet.yAxis.map((value: number, index: number) => ({
            value,
            itemStyle: {
              color: this.resolveDataPointColor(dataSet, value),
            },
            index,
          })),
          type: 'line',
          markArea: dataSet.yAxisConfiguration.range
            ? {
                itemStyle: { color: 'rgba(0, 255, 0, 0.3)' },
                data: [
                  [
                    {
                      yAxis: dataSet.yAxisConfiguration.range?.min,
                    },
                    {
                      yAxis: dataSet.yAxisConfiguration.range?.max,
                    },
                  ],
                ],
              }
            : null,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          labelFormatter: (value: number) =>
            `${this.localeDatePipe.transform(dataSet.xAxis[value].value, 'dd MMM YYYY')}`,
        },
      ],
    };
  }

  private resolveDataPointColor(dataSet: GraphDataSet, value: number): string {
    if (
      value > dataSet.yAxisConfiguration.range?.max ||
      value < dataSet.yAxisConfiguration.range?.min
    ) {
      return 'red';
    }
    return 'green';
  }

  private constructTooltipMessage(
    dataSet: GraphDataSet,
    params: any,
    valueTranslation: string,
  ): string {
    const axisData: any = params[0].data;
    const tooltipMessage: string = dataSet.xAxis[axisData.index].tooltip;
    return `${tooltipMessage}<br/>${valueTranslation}: ${axisData.value}`;
  }

  private async getLaboratoryInvoker(): Promise<InvokerBody | undefined> {
    const dossier: TieFormObject = await firstValueFrom(this.getPatientDossier());
    return dossier.showTypes.BUTTON_PROJECT.items.find(
      (projectItem: FormItem) =>
        projectItem.invokerRequest.methodName === InvokerMethods.patientObservationResultsView,
    )?.invokerRequest;
  }

  private getPatientDossier(): Observable<TieFormObject> {
    return from(this.patientService.getCurrentPatientId()).pipe(
      mergeMap((patientId: string) =>
        this.sdapiService.getInvokerListByMethodName(patientId, InvokerMethods.projectEdit),
      ),
      mergeMap((invokerList: Invoker[]) => {
        if (invokerList?.length > 0) {
          return this.sdapiService.invokeMethod<TieFormObject>(invokerList[0].invoker);
        } else {
          return throwError(() => new Error(`No projectEdit method found on current patient`));
        }
      }),
    );
  }
}
