<div class="list-container stretch-flex stretch-container fill-height">
  <div class="item-divider"></div>

  <cdk-virtual-scroll-viewport
    #scrollViewPort
    itemSize="58"
    [minBufferPx]="virtualScrollService.getBufferPx('min')"
    [maxBufferPx]="virtualScrollService.getBufferPx('max')"
    class="stretch-flex">
    <div
      class="ion-hide-md-down list-head header scrollbar"
      [style.top]="tableOrganizerService.getInverseViewportTopPosition(viewPort)">
      <div id="left-aligned-item-container">
        <div id="thumbnail-spacer"></div>
        <div id="header-title">
          <ion-button
            class="no-margin"
            size="small"
            color="light"
            [disabled]="isSearchActive"
            (click)="setSort('name')">
            <h3 class="uppercase trim-text-inline">
              {{ 'shared.documents.description' | translate }}
            </h3>
            @if (!isSearchActive) {
              @if (checkSortParams('name', true)) {
                <fa-icon slot="end" [icon]="icons.asc"></fa-icon>
              }
              @if (checkSortParams('name', false)) {
                <fa-icon slot="end" [icon]="icons.desc"></fa-icon>
              }
            }
          </ion-button>
        </div>
      </div>

      <div id="right-aligned-item-container">
        <div id="header-date">
          <ion-button
            class="no-margin"
            size="small"
            color="light"
            [disabled]="isSearchActive"
            (click)="setSort('date')">
            <h3 class="uppercase trim-text-inline">{{ 'shared.documents.date' | translate }}</h3>
            @if (!isSearchActive) {
              @if (checkSortParams('date', true)) {
                <fa-icon slot="end" [icon]="icons.asc"></fa-icon>
              }
              @if (checkSortParams('date', false)) {
                <fa-icon slot="end" [icon]="icons.desc"></fa-icon>
              }
            }
          </ion-button>
        </div>

        <div id="header-filesize">
          <ion-button
            class="no-margin ion-float-right"
            size="small"
            color="light"
            [disabled]="isSearchActive"
            (click)="setSort('size')">
            <h3 class="uppercase trim-text-inline">
              {{ 'shared.documents.file-size' | translate }}
            </h3>
            @if (!isSearchActive) {
              @if (checkSortParams('size', true)) {
                <fa-icon slot="end" [icon]="icons.asc"></fa-icon>
              }
              @if (checkSortParams('size', false)) {
                <fa-icon slot="end" [icon]="icons.desc"></fa-icon>
              }
            }
          </ion-button>
        </div>
        <div id="navigation-icon-spacer"></div>
      </div>
    </div>
    <div *cdkVirtualFor="let document of documentsList">
      <lib-document-row
        [document]="document"
        (documentListReload)="documentListReload.emit($event)">
      </lib-document-row>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
