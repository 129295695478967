import { Component, Input, OnInit } from '@angular/core';
import {
  faCalendar,
  faCalendarPlus,
  faChevronLeft,
  faChevronRight,
  faHospital,
  faPencil,
  faStethoscope,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { APIError } from 'projects/core/src/lib/data/errors.data';
import { DynamicFormMapper } from 'projects/core/src/lib/mappers/dynamic-form.mapper';
import { FormMapper } from 'projects/core/src/lib/mappers/form.mapper';
import {
  BookingQueue,
  Step,
  StepTypes,
} from 'projects/core/src/lib/models/appointment-booking.model';
import { TableList, TableListItem } from 'projects/core/src/lib/models/dynamic-table.model';
import { DataType, DynamicDataField, DynamicForm } from 'projects/core/src/lib/models/form.model';
import { Invoker, InvokerMethods } from 'projects/core/src/lib/models/invoker-body.model';
import { AppointmentBookingService } from 'projects/core/src/lib/services/appointment-booking.service';
import { AuthRedirectService } from 'projects/core/src/lib/services/auth-redirect.service';
import { StorageService } from 'projects/core/src/lib/services/storage.service';
import { combineLatest, firstValueFrom, take } from 'rxjs';
import { AppointmentBookingData } from '../storage.model';

@UntilDestroy()
@Component({
  selector: 'lib-public-booking-modal',
  templateUrl: './public-booking-modal.component.html',
  styleUrls: ['./public-booking-modal.component.scss'],
})
export class PublicBookingModalComponent implements OnInit {
  @Input() form: DynamicForm;
  @Input() showCancelButton: boolean = true;

  private bookingTranslations: string[];

  readonly ICONS = {
    title: faCalendarPlus,
    edit: faPencil,
    back: faChevronLeft,
    next: faChevronRight,
  };

  sharedData: SharedBookingData = new SharedBookingData();
  bookingQueue: BookingQueue<SharedBookingData>;

  constructor(
    private bookingService: AppointmentBookingService,
    private translateService: TranslateService,
    private dynamicFormMapper: DynamicFormMapper,
    private authRedirectService: AuthRedirectService,
    private storageService: StorageService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.bookingTranslations = await firstValueFrom(
      this.translateService.get('shared.appointment-booking'),
    );
    this.bookingQueue = new BookingQueue<SharedBookingData>(
      [
        {
          title: this.form.body?.[0]?.name || this.bookingTranslations['department'],
          icon: faHospital,
          type: StepTypes.form,
          dynamicForm: this.form,
          trigger: () => this.typeSelectionGroupTrigger(),
          groupIndex: 0,
          keepEditable: false,
        },
        {
          title: this.form.body?.[1]?.name || this.bookingTranslations['appointment-type'],
          icon: faStethoscope,
          type: StepTypes.form,
          dynamicForm: this.form,
          trigger: () => this.typeSelectionGroupTrigger(),
          groupIndex: 1,
          keepEditable: false,
        },
        {
          title: this.bookingTranslations['date-selection'],
          icon: faCalendar,
          type: StepTypes.dynamicDate,
          groupIndex: 0,
          resultGroupIndex: 1,
          dynamicForm: this.sharedData.bookingForm,
          trigger: () => this.resolveAppointmentForm(this.sharedData),
          keepEditable: true,
        },
        {
          title: this.bookingTranslations['registration-login'],
          icon: faUser,
          type: StepTypes.information,
          info: this.bookingTranslations['please-register-or-login'],
          keepEditable: true,
        },
      ],
      this.sharedData,
    );
    this.skipStaticFormStep(this.bookingQueue);
  }

  private typeSelectionGroupTrigger(): void {
    this.deleteBookingForm();
    this.skipStaticFormStep(this.bookingQueue);
  }

  private skipStaticFormStep(bookingQueue: BookingQueue<any>): void {
    if (!this.stepHasEditableFields(bookingQueue.currentStep)) {
      bookingQueue.next();
    }
  }

  private stepHasEditableFields(step: Step): boolean {
    return !!step.dynamicForm?.body[step.groupIndex].fieldGroup.find(
      (field) => field.value.options?.length !== 1,
    );
  }

  stepIsEditable(step: Step): boolean {
    return step.keepEditable || this.stepHasEditableFields(step);
  }

  deleteBookingForm(): void {
    this.sharedData.bookingForm = undefined;
    this.sharedData.availableAppointmentSlots = undefined;
  }

  redirectToLogin() {
    this.bookingDataToLocalStorage().then(() => {
      this.authRedirectService.redirectToLogin('/portal/appointments');
    });
  }

  redirectToRegistration() {
    this.bookingDataToLocalStorage().then(() => {
      this.authRedirectService.redirectToRegistration('/portal/appointments');
    });
  }

  private bookingDataToLocalStorage(): Promise<void> {
    const typeFormData = this.form.body
      .flatMap((group) => group.fieldGroup)
      .map((field) => ({
        identifier: field.identifier,
        value: field.value.value,
        options: field.value.options,
      }));

    const createId = this.sharedData.bookingForm.body
      .flatMap((group) => group.fieldGroup)
      .find((field) => field.type === DataType.popup).value.value;

    if (!typeFormData || !createId) {
      throw new Error('No FormData or createId found!');
    }
    return this.storageService.setObject<AppointmentBookingData>('bookingData', {
      typeFormData,
      createId,
    });
  }

  private getPopupInvokerField(dynamicForm: DynamicForm): DynamicDataField | undefined {
    return dynamicForm?.body
      .flatMap((group) => group.fieldGroup)
      .find((item) => item.type === DataType.popup);
  }

  get requiredFulfilled(): boolean {
    const formIsProcessing =
      this.form?.isProcessing || this.bookingQueue?.currentStep?.dynamicForm?.isProcessing;
    if (formIsProcessing) {
      return false;
    }
    const queue = this.bookingQueue;
    const step = this.bookingQueue.currentStep;

    if (step.type === StepTypes.dynamicDate) {
      return !!this.getPopupInvokerField(queue.sharedData.bookingForm)?.hasValue;
    } else if (step.groupIndex !== undefined) {
      return queue.currentFormGroup?.fieldGroup.every(
        (field: DynamicDataField) => !field.required || field.hasValue,
      );
    } else {
      return true;
    }
  }

  injectForm(): void {
    this.bookingQueue.currentStep.dynamicForm = this.bookingQueue.sharedData.bookingForm;
  }

  resolveAppointmentForm(sharedData: SharedBookingData) {
    delete this.bookingQueue.currentStep.hasIntermediateStep;
    delete this.bookingQueue.currentStep.intermediateStepForm;

    this.bookingService
      .getAppointmentBookingForm(this.form)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: (form) => {
          sharedData.bookingForm = form;
          this.injectForm();
          this.decideBookingForm(form);
        },
        error: (error) => {
          throw new APIError('Failed to create Appointment.', error);
        },
      });
  }

  async decideBookingForm(form: DynamicForm) {
    delete this.bookingQueue.currentStep.hasIntermediateStep;
    let invoker = this.getPopupInvokerField(form)?.value.invoker;
    if (!invoker) {
      throw new APIError('No PopupInvoker found!');
    }

    if (invoker.invoker.methodName === InvokerMethods.objectFindInObjectList) {
      this.bookingQueue.currentStep.hasIntermediateStep = true;
      invoker = await this.waitForAppointmentSlotInvoker(invoker);
    }

    await this.getAppointmentSlotsFromInvoker(invoker);
  }

  async waitForAppointmentSlotInvoker(invoker: Invoker): Promise<Invoker> {
    this.bookingQueue.currentStep.intermediateStepForm = await firstValueFrom(
      this.bookingService.getAppointmentSlotsIntermediateForm(invoker, true),
    );

    return new Promise<Invoker>((resolve) => {
      const intermediateForm = this.bookingQueue.currentStep.intermediateStepForm;
      const viewGroup = 0;

      const fieldObserver = intermediateForm.body[viewGroup].fieldGroup
        .filter((field) => field.required)
        .map((field) => field.value.getValueObserver());

      const subscription = combineLatest(fieldObserver).subscribe((results) => {
        if (results.every((value) => !!value?.length)) {
          const searchInvoker = this.dynamicFormMapper.getSearchInvoker(intermediateForm);
          searchInvoker.invoker = FormMapper.insertDynamicFormItemsToInvokerBody(
            intermediateForm.body,
            searchInvoker,
          );
          resolve(searchInvoker);
          this.bookingQueue.currentStep.intermediateStepForm = undefined;
          subscription.unsubscribe();
        }
      });
    });
  }

  get appointmentChosen(): boolean {
    return !!this.bookingQueue.sharedData.bookingForm?.body
      .flatMap((group) => group.fieldGroup)
      .find((item) => item.type === DataType.popup)?.value.value;
  }

  async getAppointmentSlotsFromInvoker(invoker: Invoker) {
    this.bookingQueue.sharedData.availableAppointmentSlots =
      await this.getSelectionByPopupInvoker(invoker);
  }

  async getSelectionByPopupInvoker(invoker: Invoker): Promise<AppointmentSlot[]> {
    const appointmentsTable: TableList = await firstValueFrom(
      this.bookingService.getAppointmentSlots(invoker, true),
    );
    return appointmentsTable.initialRows.map((row: TableListItem) => ({
      id: row.id,
      time: row.columns[2],
    }));
  }
}

export class SharedBookingData {
  availableAppointmentSlots: AppointmentSlot[] = [];
  bookingForm: DynamicForm | undefined = undefined;
}

export class AppointmentSlot {
  id: string;
  time: string;
}
