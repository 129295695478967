import { InvokerBody } from './invoker-body.model';

export type ExpensesWidgetRow = {
  name: string;
  amount: string;
  date: string;
  currency: string;
  status: string;
  behaviourInvokers?: InvokerBody[];
  id: string;
};

export enum ExpenseTypeIdentifier {
  name = 'sys_object.descr',
  amount = 'sys_attribute.f1',
  status = 'status',
  currency = 'obj_name',
  lastUpdate = 'sys_object.last_update',
}
