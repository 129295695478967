import { SelectionObject } from 'projects/core/src/lib/models/form.model';

export const filterOptions = (
  options: SelectionObject[],
  searchString: string,
  maxOptions: number = 0,
): SelectionObject[] => {
  return options
    .slice()
    .filter(
      ({ value }) =>
        searchString === '' || value.toLowerCase().includes(searchString.toLowerCase()),
    )
    .sort((a, b) => {
      if (!searchString) {
        return 0;
      }
      const aIndex = a.value.toLowerCase().indexOf(searchString.toLowerCase());
      const bIndex = b.value.toLowerCase().indexOf(searchString.toLowerCase());
      /* if in both elements the searchString occurs at the first position, determine order by alphabetical sort of the elements */
      if (aIndex === bIndex) {
        return a.value.toLowerCase().localeCompare(b.value.toLowerCase());
      }
      /* if the searchString does not contain a, b is sorted up OR both are there and b appears before a, b is sorted up */
      if (aIndex === -1 || (bIndex !== -1 && aIndex > bIndex)) {
        return 1;
      }
      /* in all other cases a is sorted up */
      return -1;
    })
    .filter((_, index) => !maxOptions || index < maxOptions);
};
