import { Clipboard } from '@angular/cdk/clipboard';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { LaboratoryResultsMapper } from 'projects/core/src/lib/mappers/laboratory-results.mapper';
import { GraphDataSet } from 'projects/core/src/lib/models/graph.model';
import {
  LaboratoryResultTable,
  LaboratoryResultTableCell,
  LaboratoryResultTableColumn,
  LaboratoryResultTableRow,
} from 'projects/core/src/lib/models/laboratory-results.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { LaboratoryResultsService } from 'projects/core/src/lib/services/laboratory-results.service';
import { OverlayEventRole, PopupService } from 'projects/core/src/lib/services/popup.service';
import { ValueCopyPopupContentComponent } from 'projects/shared/src/lib/components/popups/value-copy-popup/value-copy-popup-content.component';
import { take } from 'rxjs';

@Component({
  selector: 'lib-laboratory-results-table',
  templateUrl: './laboratory-results-table.component.html',
  styleUrl: './laboratory-results-table.component.scss',
})
export class LaboratoryResultsTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('overlayContainer') overlayContainer: ElementRef;
  @ViewChild('dragScroll', { static: false }) dragScrollElement: DragScrollComponent;

  @Input({ required: true }) tableData!: LaboratoryResultTable;
  @Input({ required: true }) tableName: string;

  showMoreNoteDetails: boolean = false;
  isDragDisabled: boolean = false;
  isDragging: boolean = false;

  private activeRowID: number | string;
  private activeColumnID: number | string;
  private valueSelected: boolean = false;
  private copyValuePopup: HTMLIonPopoverElement;

  constructor(
    private readonly popupService: PopupService,
    private readonly ngZone: NgZone,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly breakpointService: BreakpointService,
    private readonly laboratoryResultsService: LaboratoryResultsService,
    private readonly destroyRef: DestroyRef,
    private readonly clipboard: Clipboard,
    private readonly popoverController: PopoverController,
    private readonly breakpoint: BreakpointService,
  ) {}

  private modal: HTMLIonModalElement;
  private popover: HTMLIonPopoverElement;

  ngOnInit(): void {
    this.tableData.sortColumns(
      this.tableData.sortParams.identifier,
      this.tableData.sortParams.direction,
    );
  }

  ngAfterViewInit(): void {
    this.handleTableDragStartSubscription();
    this.handleTableDragEndSubscription();
  }

  ngOnDestroy(): void {
    this.dismissActiveModalSheetOnMobile();
    this.dismissActivePopoverOnDesktop();
  }

  private dismissActiveModalSheetOnMobile(): void {
    this.laboratoryResultsService.dismissActiveModalSheet(
      this.breakpointService.isAbove('sm'),
      this.modal,
    );
  }

  private dismissActivePopoverOnDesktop(): void {
    this.laboratoryResultsService.dismissActivePopover(
      this.breakpointService.isBelow('md'),
      this.popover,
    );
  }

  escapeNewLine(value: string) {
    return value.replace(/\\n/g, '\n');
  }

  getInfoIconContainerStyles(cell: LaboratoryResultTableCell, isPopupLayout?: boolean): object {
    if (!cell.flag?.color || isPopupLayout) {
      return { border: '1px solid var(--color-greyish-100)' };
    }
    return {};
  }

  getInfoIconStyles(cell: LaboratoryResultTableCell): object {
    if (cell.flag?.color) {
      return {
        color: `color-mix(in oklab, black 10%, ${cell.flag.color})`,
      };
    }
    return {};
  }

  getIndicatorContainerStyles(cell: LaboratoryResultTableCell): object {
    if (cell.flag?.color) {
      return {
        color: 'var(--color-white)',
        background: `color-mix(in oklab, black 10%, ${cell.flag.color})`,
      };
    }
    return {};
  }

  getCellStyles(cell: LaboratoryResultTableCell): object {
    if (cell.flag?.color) {
      return {
        color: `color-mix(in oklab, black 40%, ${cell.flag.color})`,
        background: `color-mix(in oklab, var(--color-white) 80%, ${cell.flag.color})`,
        borderLeft: '1px solid var(--color-greyish-4)',
      };
    }
    return {};
  }

  getDataCellStyles(cell: LaboratoryResultTableCell): object {
    if (cell.notes?.length && cell.value) {
      return { borderLeft: '1px solid var(--color-greyish-4)' };
    }
    return {};
  }

  handleOverlayLayout(connectedOverlay: CdkConnectedOverlay): void {
    this.ngZone.onStable.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const popoverElement: HTMLElement = this.getPopoverElement(connectedOverlay);
      if (popoverElement) {
        this.adjustNoteDetailsView(popoverElement);
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  async showNotes(notes: string[]): Promise<void> {
    this.clearActiveInfoOverlayState();
    await this.popupService.showLaboratoryNotesPopover(notes);
  }

  isOverlayOpen(rowID: number | string, columnID?: number | string): boolean {
    return this.activeRowID === rowID && this.activeColumnID === columnID;
  }

  showInfoOverlay(rowID: number | string, columnID?: number | string): void {
    this.activeRowID = rowID;
    this.activeColumnID = columnID;
  }

  dismissInfoOverlay(event: any, connectedOverlay: CdkConnectedOverlay): void {
    if (this.overlayContains(connectedOverlay, this.overlayContainer, event)) {
      return;
    }

    this.clearActiveInfoOverlayState();
  }

  private clearActiveInfoOverlayState(): void {
    this.activeRowID = undefined;
    this.activeColumnID = undefined;
  }

  private overlayContains(
    connectedOverlay: CdkConnectedOverlay,
    overlayContainer: ElementRef,
    event: any,
  ): boolean {
    const overlayElement: HTMLElement = connectedOverlay.overlayRef.overlayElement;
    return (
      overlayElement.contains(event['target']) ||
      overlayContainer?.nativeElement.contains(event['target'])
    );
  }

  private getPopoverElement(connectedOverlay: CdkConnectedOverlay): HTMLElement | null {
    const overlayElement: HTMLElement = connectedOverlay.overlayRef.overlayElement;
    return overlayElement.querySelector('.popover-wrapper');
  }

  private adjustNoteDetailsView(popoverElement: HTMLElement): void {
    const contentHeight: number = popoverElement.offsetHeight;
    if (contentHeight >= 250) {
      this.showMoreNoteDetails = true;
      popoverElement.classList.add('fade-effect');
    } else {
      this.showMoreNoteDetails = false;
      popoverElement.classList.remove('fade-effect');
    }
  }

  isRowEmpty(row: LaboratoryResultTableRow): boolean {
    return row.cells.every(
      (cell: LaboratoryResultTableCell) => !cell.value || isNaN(Number(cell.value)),
    );
  }

  async openGraphModal(
    header: LaboratoryResultTableColumn[],
    dataCells: LaboratoryResultTableCell[],
    fixedCells: LaboratoryResultTableCell[],
  ): Promise<void> {
    const title: string = LaboratoryResultsMapper.constructModalTitle(fixedCells);
    const dataSet: GraphDataSet = LaboratoryResultsMapper.mapTableDataRowForGraphView(
      header,
      dataCells,
      this.tableData.fixedColumns,
      fixedCells,
    );
    await this.popupService.showGraphModal(dataSet, title);
  }

  async openFilterOverlay(): Promise<void> {
    this.clearActiveInfoOverlayState();
    if (this.breakpointService.isAbove('sm')) {
      await this.presentPopover(this.tableData, this.tableName);
    } else {
      await this.presentModalSheet(this.tableData);
    }
  }

  private async presentModalSheet(tableData: LaboratoryResultTable): Promise<void> {
    this.modal = await this.laboratoryResultsService.presentFilterModalSheet(tableData);
    this.modal.keepContentsMounted = true;
    await this.modal.present();
  }

  private async presentPopover(tableData: LaboratoryResultTable, tableName: string): Promise<void> {
    this.popover = await this.laboratoryResultsService.presentFilterPopover(tableData, tableName);
    this.popover.keepContentsMounted = true;
    await this.popover.present();
  }

  private handleTableDragStartSubscription(): void {
    this.dragScrollElement?.dragStart
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async () => {
        this.isDragging = true;
        this.valueSelected = false;
      });
  }

  private handleTableDragEndSubscription(): void {
    this.dragScrollElement?.dragEnd
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async () => {
        this.isDragging = false;
      });
  }

  disableDrag(): void {
    if (this.breakpoint.isAbove('xl')) {
      this.isDragDisabled = true;
    }
  }

  enableDrag(): void {
    this.isDragDisabled = false;
  }

  async showCopyValuePopup(
    value: string,
    idPrefix: string,
    rowIndex: number,
    columnIndex: number,
  ): Promise<void> {
    if (this.valueSelected) {
      this.copyValuePopup = await this.popoverController.create({
        component: ValueCopyPopupContentComponent,
        trigger: this.constructPopupId(idPrefix, rowIndex, columnIndex),
        arrow: true,
        alignment: 'center',
        side: 'bottom',
        showBackdrop: false,
        cssClass: 'value-copy-popup',
      });

      await this.copyValuePopup.present();

      const event: OverlayEventDetail = await this.copyValuePopup.onDidDismiss();
      if (event.role === OverlayEventRole.save) {
        this.clipboard.copy(value);
      }
    }
  }

  constructPopupId(idPrefix: string, rowIndex: number, columnIndex: number): string {
    return `${idPrefix}-${rowIndex}-${columnIndex}`;
  }

  recordValueSelection() {
    this.valueSelected = true;
  }
}
