import { IconDefinition } from '@fortawesome/angular-fontawesome/types';
import {
  faCalendarAlt,
  faCalendarCheck,
  faCalendarMinus,
  faCalendarPlus,
  faClinicMedical,
  faClock,
  faDatabase,
  faDollarSign,
  faFileMedical,
  faFileMedicalAlt,
  faHospital,
  faIdBadge,
  faMapMarkerAlt,
  faMoneyBillWave,
  faNotesMedical,
  faProcedures,
  faUser,
  faUserCheck,
  faUserFriends,
  faUserInjured,
  faUserMinus,
} from '@fortawesome/free-solid-svg-icons';
import { tieFileKey } from 'projects/theme/src/lib/icons/tie/file-key';
import { tieFileNumber } from 'projects/theme/src/lib/icons/tie/file-number';
import { AttributeDetail } from './attribute.model';
import { DocumentDetails } from './documents.model';
import { Invoker } from './invoker-body.model';
import { StatusType } from './required-actions.model';
import { AttributeShowsItem } from './rest2-object.model';

export class TreatmentDetails {
  id: number;
  title?: string;
  date?: Date;
  doctor?: string;
  location?: string;
  status?: StatusInfo;
  listLink?: string;
  documents?: DocumentDetails[];
  information?: DocumentDetails[];
  details?: AttributeDetail[];
  hasDocumentUpload?: boolean;
  hasNotesFeature?: boolean;
  hasShareWithDoctorActivity?: boolean;
  doctorContacts: TreatmentDoctorContact[];

  get dateIsInPast(): boolean {
    return this.date ? new Date(this.date) < new Date() : false;
  }

  get dateIsInFuture(): boolean {
    return this.date ? new Date(this.date) > new Date() : false;
  }
}

export class TreatmentDoctorContact {
  role: AttributeDetail;
  title: AttributeDetail;
  firstName: AttributeDetail;
  lastName: AttributeDetail;
  address: AttributeDetail;
  zip: AttributeDetail;
  city: AttributeDetail;
  country: AttributeDetail;
  phone: AttributeDetail;
  email: AttributeDetail;
}

export type StatusInfo = {
  color: string;
  label: string;
  tooltip: string;
  type: StatusType;
};

export enum TreatmentSegment {
  DOCUMENTS,
  INFORMATION,
}

export class AutoImportObject {
  title: string;
  data: UploadFormDataField[];
  file: File;
  fileName: string;
  attributes?: AutoImportObjectAttributes = new AutoImportObjectAttributes();
  invoker: Invoker[];
}

export class AutoImportObjectAttributes {
  [key: string]: any;
}

export class UploadFormDataField extends AttributeShowsItem {
  readonly originalValue: string;
  value: string;
  key: string;
  name: string;
}

export class TreatmentAttributeNames {
  public static readonly title = 'case';
  public static readonly date = 'SYS_VISIT.ADMISSION_DATE';
  public static readonly doctor = 'SYS_ATTRIBUTE.T1';
  public static readonly location = 'SYS_ATTRIBUTE.T3';
}

export enum TreatmentDetailsAttributeIdentifier {
  visitId = 'SYS_VISIT.VISIT_ID',
  fid = 'SYS_VISIT.FID',
  pid = 'SYS_VISIT.PID',
  visitType = 'SYS_VISIT.VISIT_TYPE',
  admissionDate = 'SYS_VISIT.ADMISSION_DATE',
  dischargeDate = 'SYS_VISIT.DISCHARGE_DATE',
  financialClass = 'SYS_VISIT.FINANCIAL_CLASS',
  pointOfCare = 'SYS_VISIT.POINT_OF_CARE',
  facility = 'SYS_VISIT.FACILITY',
  dischargeReason = 'SYS_VISIT.DISCHARGE_REASON',
  admissionType = 'SYS_VISIT.ADMISSION_TYPE',
  admissionReason = 'SYS_VISIT.ADMISSION_REASON',
  admissionReason2 = 'SYS_VISIT.ADMISSION_REASON2',
  dischargeType = 'SYS_VISIT.DISCHARGE_TYPE',
  referralType = 'SYS_VISIT.REFERRAL_TYPE',
  dischargeToLocation = 'SYS_VISIT.DISCHARGE_TO_LOCATION',
  sourceSystem = 'SYS_VISIT.SOURCE_SYSTEM',
  patientId = 'SYS_VISIT.PATIENT_ID',
  chargePriceIndicator = 'SYS_VISIT.CHARGE_PRICE_INDICATOR',
  admitReason = 'SYS_VISIT.ADMIT_REASON',
  expectedAdmitDate = 'SYS_VISIT.EXPECTED_ADMIT_DATE',
  expectedDischargeDate = 'SYS_VISIT.EXPECTED_DISCHARGE_DATE',
  estimatedLengthOfStay = 'SYS_VISIT.ESTIMATED_LENGTH_OF_STAY',
}

const identifier: typeof TreatmentDetailsAttributeIdentifier = TreatmentDetailsAttributeIdentifier;

export const TreatmentDetailsAttributeIdentifierValues: TreatmentDetailsAttributeIdentifier[] = [
  identifier.visitId,
  identifier.fid,
  identifier.pid,
  identifier.visitType,
  identifier.admissionDate,
  identifier.dischargeDate,
  identifier.financialClass,
  identifier.pointOfCare,
  identifier.facility,
  identifier.dischargeReason,
  identifier.admissionType,
  identifier.admissionReason,
  identifier.admissionReason2,
  identifier.dischargeType,
  identifier.referralType,
  identifier.dischargeToLocation,
  identifier.sourceSystem,
  identifier.patientId,
  identifier.chargePriceIndicator,
  identifier.admitReason,
  identifier.expectedAdmitDate,
  identifier.expectedDischargeDate,
  identifier.estimatedLengthOfStay,
];

export const TreatmentDetailsAttributeIcons = new Map<string, IconDefinition>([
  [identifier.visitId, tieFileKey],
  [identifier.fid, tieFileNumber],
  [identifier.pid, faIdBadge],
  [identifier.visitType, faFileMedicalAlt],
  [identifier.admissionDate, faCalendarPlus],
  [identifier.dischargeDate, faCalendarMinus],
  [identifier.financialClass, faMoneyBillWave],
  [identifier.pointOfCare, faClinicMedical],
  [identifier.facility, faHospital],
  [identifier.dischargeReason, faNotesMedical],
  [identifier.admissionType, faUserCheck],
  [identifier.admissionReason, faUserInjured],
  [identifier.admissionReason2, faProcedures],
  [identifier.dischargeType, faUserMinus],
  [identifier.referralType, faUserFriends],
  [identifier.dischargeToLocation, faMapMarkerAlt],
  [identifier.sourceSystem, faDatabase],
  [identifier.patientId, faUser],
  [identifier.chargePriceIndicator, faDollarSign],
  [identifier.admitReason, faFileMedical],
  [identifier.expectedAdmitDate, faCalendarAlt],
  [identifier.expectedDischargeDate, faCalendarCheck],
  [identifier.estimatedLengthOfStay, faClock],
]);

export class TreatmentInformationAttributeNames {
  public static readonly title = 'SYS_OBJECT.OBJ_NAME';
  public static readonly creationDate = 'SYS_OBJECT.CREATION_DATE';
  public static readonly fileSize = 'SYS_OBJECT.OBJ_SIZE';
  public static readonly documentType = 'SYS_OBJCLASS.OBJCLASS_NAME';
  public static readonly mimeType = 'mime_type';
}

export class TreatmentDoctorContactAttributeNames {
  public static readonly role = 'SYS_VISIT_DOCTOR.ROLE';
  public static readonly title = 'SYS_CONTACT.TITLE';
  public static readonly firstName = 'SYS_CONTACT.GIVEN_NAME';
  public static readonly lastName = 'SYS_CONTACT.FAMILY_NAME';
  public static readonly address = 'SYS_CONTACT.ADDRESS1';
  public static readonly zip = 'SYS_CONTACT.ZIP_CODE';
  public static readonly city = 'SYS_CONTACT.CITY';
  public static readonly country = 'SYS_CONTACT.COUNTRY';
  public static readonly phone = 'SYS_CONTACT.PHONE1';
  public static readonly email = 'SYS_CONTACT.EMAIL1';
}
