import { Injectable } from '@angular/core';
import { FileSizePipe } from 'projects/theme/src/lib/pipes/file-size.pipe';
import { LocaleDatePipe } from 'projects/theme/src/lib/pipes/locale-date.pipe';

@Injectable()
export class SearchFormattingService {
  constructor(
    private readonly localeDatePipe: LocaleDatePipe,
    private readonly fileSizePipe: FileSizePipe,
  ) {}

  public formattedField(name: string, value: any | any[]): any | any[] {
    if (Array.isArray(value)) {
      return value.map((value: any) => this.formatFieldValue(name, value));
    }

    return this.formatFieldValue(name, value);
  }

  public formatDate(date: Date | string): string {
    return this.localeDatePipe.transform(
      date,
      'EEEE MM dd MMMM YYYY dd MM MMMM YYYY MM dd YYYY dd MM YYYY MM dd YY dd MM YY',
    );
  }

  public static isEmptyValue(value: any): boolean {
    return value === null || value === undefined || value === '';
  }

  public static generateSearchableTermSuffixes(term: any, minTermLength: number): any {
    if (typeof term !== 'string' || term.length < minTermLength) {
      return SearchFormattingService.normalizeStringValue(term);
    }

    const tokens: string[] = [];
    const normalizedTerm: string = SearchFormattingService.normalizeStringValue(term);

    for (let i = 0; i <= normalizedTerm.length - minTermLength; i++) {
      tokens.push(normalizedTerm.substring(i));
    }

    return tokens;
  }

  public static normalizeStringValue(value: string): string {
    return value?.toLowerCase();
  }

  private formatFieldValue(name: string, value: any): string {
    if (SearchFormattingService.isEmptyValue(value)) {
      return value;
    }

    if (value instanceof Date || SearchFormattingService.isValidDateString(value)) {
      return this.formatDate(value);
    }

    if (SearchFormattingService.isFileSizeField(name, value)) {
      return this.fileSizePipe.transform(value, { roundingMethod: 'ceil', round: 0 }) as string;
    }

    return value;
  }

  private static isFileSizeField(fieldName: string, fieldValue: any): boolean {
    return fieldName === 'size' && typeof fieldValue === 'number';
  }

  private static isValidDateString(value: string): boolean {
    return typeof value === 'string' && this.isValidDate(value);
  }

  private static isValidDate(value: string): boolean {
    const date: Date = new Date(value);
    return !isNaN(date.getTime());
  }
}
