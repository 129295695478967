import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowRight,
  faCheck,
  faChevronRight,
  faCircleInfo,
  faStethoscope,
} from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AccessDeniedError } from 'projects/core/src/lib/data/errors.data';
import { SortParams } from 'projects/core/src/lib/models/shared.model';
import { TreatmentDetails } from 'projects/core/src/lib/models/treatment.model';
import { TreatmentsService } from 'projects/core/src/lib/services/treatment.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-treatments-widget',
  templateUrl: './treatments.component.html',
  styleUrls: ['./treatments.component.scss'],
})
@UntilDestroy()
export class TreatmentsWidgetComponent implements OnInit {
  treatmentsList: TreatmentDetails[] = [];
  isLoading: boolean = true;
  sortParams = new SortParams();
  isDataAccessRestricted: boolean = false;

  public icons = {
    info: faCircleInfo,
    stethoscope: faStethoscope,
    chevron: faChevronRight,
    arrow: faArrowRight,
    check: faCheck,
    calendar: faCalendar,
  };

  constructor(
    private readonly treatmentsService: TreatmentsService,
    private readonly router: Router,
  ) {
    this.sortParams.field = 'date';
  }

  async ngOnInit() {
    await this.initializeTreatments();
  }

  private filterTreatmentsListAccordingToFutureAndPastLimits(
    treatmentsList: TreatmentDetails[],
  ): TreatmentDetails[] {
    const futureLimit = 2;
    const pastLimit = 1;
    const treatmentsWithDate = treatmentsList.filter((treatment) => treatment.date);

    treatmentsWithDate.sort((a, b) => b.date.getTime() - a.date.getTime());

    const result = [];

    const treatmentsInFuture = treatmentsWithDate.filter((treatment) => treatment.dateIsInFuture);
    const treatmentsInPast = treatmentsWithDate.filter((treatment) => treatment.dateIsInPast);

    result.push(...treatmentsInFuture.splice(0, futureLimit));
    result.push(...treatmentsInPast.splice(0, pastLimit));
    if (result.length < 3) {
      result.push(...treatmentsInFuture.concat(treatmentsInPast).splice(0, 3 - result.length));
    }

    return result;
  }

  async initializeTreatments() {
    try {
      const treatmentsList: TreatmentDetails[] = await firstValueFrom(
        this.treatmentsService.getTreatmentList(),
      );
      this.treatmentsList = this.filterTreatmentsListAccordingToFutureAndPastLimits(treatmentsList);
    } catch (error) {
      this.isDataAccessRestricted = error instanceof AccessDeniedError;
    } finally {
      this.isLoading = false;
    }
  }

  async navigateToTreatmentDetails(id: number): Promise<void> {
    await this.router.navigate(['/portal/treatments', id]);
  }
}
