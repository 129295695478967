import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { inject } from '@angular/core';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';

export const LegalNoticeModuleGuard: CanActivateFn = async (): Promise<boolean | UrlTree> => {
  const clientConfigService: ClientConfigService = inject(ClientConfigService);
  const router: Router = inject(Router);
  const clientConfig: ClientConfig = clientConfigService.get();

  if (clientConfig.activeModules.legalNotice) {
    return true;
  } else {
    await router.navigateByUrl('/portal');
    return false;
  }
};
