@for (
  fixedColumn of tableData.fixedColumns;
  track fixedColumn.originalSortIndex;
  let columnIndex = $index
) {
  <lib-auto-layout
    direction="vertical"
    [gap]="[10]"
    [padding]="[0, 20, 35, 20]"
    horizontal="stretch">
    <lib-auto-layout [gap]="[10]">
      <fa-icon icon="filter" size="sm"></fa-icon>
      <h3 class="ion-no-margin">{{ fixedColumn.name }}</h3>
      <h3 class="ion-no-margin">({{ getFilterValueCount(columnIndex) }})</h3>
    </lib-auto-layout>

    @if (getFilterState(columnIndex).expanded) {
      <ion-button
        color="primary"
        expand="block"
        class="p3"
        (click)="resetFilterOptions(columnIndex)">
        {{ 'shared.laboratory-results.filter-deactivate' | translate }}
        <fa-icon icon="circle-xmark" size="sm" slot="end"></fa-icon>
      </ion-button>

      <lib-auto-layout direction="vertical" class="shape-list" horizontal="stretch" [grow]="1">
        @if (getFilterState(columnIndex).currentValues.size) {
          <lib-organizer-list-item>
            <ion-checkbox
              color="primary"
              (ionChange)="tableData.handleSelectAllStateChange(columnIndex, $event.detail.checked)"
              [checked]="getCheckboxStateForSelectAll(columnIndex)">
              <strong>{{ 'shared.laboratory-results.select-all' | translate }}</strong>
            </ion-checkbox>
          </lib-organizer-list-item>
        } @else {
          <lib-auto-layout direction="vertical" [padding]="[1, 0]" [grow]="1">
            <lib-notification-box-component
              [grow]="1"
              [message]="'shared.laboratory-results.no-filter-values-available' | translate">
            </lib-notification-box-component>
          </lib-auto-layout>
        }
        @for (value of getFilterState(columnIndex).currentValues; track $index) {
          <lib-organizer-list-item>
            <ion-checkbox
              color="primary"
              (ionChange)="
                tableData.toggleColumnFilterValue(columnIndex, value, $event.detail.checked)
              "
              [checked]="getCheckboxStateForFilterValue(columnIndex, value)">
              @if (value !== null) {
                {{ value }}
              } @else {
                {{ 'shared.laboratory-results.no-value' | translate }}
              }
            </ion-checkbox>
          </lib-organizer-list-item>
        }
      </lib-auto-layout>
    } @else {
      <ion-button
        color="tertiary"
        expand="block"
        class="p3"
        (click)="expandFilterOptions(columnIndex)">
        {{ 'shared.laboratory-results.filter-options' | translate }}
      </ion-button>
    }
  </lib-auto-layout>
}
