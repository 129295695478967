import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import MiniSearch from 'minisearch';
import { DocumentFilter } from 'projects/core/src/lib/models/documents.model';
import { RequiredActionsDetails } from 'projects/core/src/lib/models/required-actions.model';
import { SearchParams, SortDirection, SortParams } from 'projects/core/src/lib/models/shared.model';
import { ItemSearcherService } from 'projects/core/src/lib/services/item-searcher.service';
import { ItemSorterService } from 'projects/core/src/lib/services/item-sorter.service';
import { RequiredActionsService } from 'projects/core/src/lib/services/required-actions.service';

@Component({
  selector: 'lib-tasks-browser',
  templateUrl: './tasks-browser.component.html',
  styleUrls: ['./tasks-browser.component.scss'],
})
export class TasksBrowserComponent implements OnInit {
  requiredActions: RequiredActionsDetails[] = [];
  requiredActionsList: RequiredActionsDetails[] = [];
  viewList: RequiredActionsDetails[];

  filtersPreset: DocumentFilter[] = [];
  searchParams = new SearchParams();
  sortParams = new SortParams();
  searchHandler: MiniSearch<RequiredActionsDetails>;

  constructor(
    private readonly requiredActionsService: RequiredActionsService,
    private readonly destroyRef: DestroyRef,
    private readonly searchService: ItemSearcherService,
  ) {
    this.initializeFilterAndSortParameters();
  }

  get isLoading(): boolean {
    return this.requiredActionsService.isLoading;
  }

  async ngOnInit() {
    await this.startRequiredActionsSubscription();
  }

  startRequiredActionsSubscription(): Promise<void> {
    return new Promise((resolve) => {
      this.requiredActionsService.requiredActions$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((requiredActions: RequiredActionsDetails[]) => {
          this.requiredActions = requiredActions;
          this.refreshViewList();
          resolve();
        });
    });
  }

  refreshViewList(): void {
    if (this.isLoading || !this.requiredActions) {
      return;
    }
    if (this.searchParams.criterions) {
      this.viewList = this.getSearchResults(this.requiredActions);
    } else {
      this.viewList = ItemSorterService.sort(this.requiredActions, this.sortParams);
    }
  }

  private getSearchResults(items: RequiredActionsDetails[]): RequiredActionsDetails[] {
    return ItemSearcherService.searchItems<RequiredActionsDetails>(
      this.searchHandler,
      items,
      this.searchParams,
    );
  }

  get hasActions(): boolean {
    return !!this.viewList?.length;
  }

  get noResultMessage(): string {
    const hasSearchCriterions = this.searchParams?.criterions?.length > 0;
    if (hasSearchCriterions) {
      return 'shared.tasks-browser.no-tasks-found-for-your-search';
    } else {
      return 'shared.tasks-browser.no-tasks-found';
    }
  }

  private initializeFilterAndSortParameters(): void {
    this.searchParams.fields = ['title', 'endDate.value', 'task.value'];
    this.searchHandler = this.searchService.configureMiniSearch<RequiredActionsDetails>(
      this.searchParams,
    );
    this.sortParams.field = 'endDate';
    this.sortParams.direction = SortDirection.ASC;
  }
}
