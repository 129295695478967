import { Invoker, InvokerBody, InvokerTypes } from '../models/invoker-body.model';
import { SubscriptionFieldKey } from '../models/profile.model';
import {
  AttributeValueItem,
  FormItem,
  FormItemBooleanString,
  FormItemType,
  TieFormObject,
} from '../models/sdapi-form-object.model';
import { AttributeValue } from './../models/sdapi-form-object.model';
import { FormMapper } from './form.mapper';

export type ProfileSubscriptions = {
  activityURL: string;
  profileSubscriptionElements: ProfileSubscriptionElement[];
};

export type ProfileSubscriptionElement = {
  displayValue: string;
  internalValue: string;
  active?: boolean;
};

export class SubscriptionResourceMapper {
  public static mapResource(form: TieFormObject): ProfileSubscriptionElement[] {
    const possibleSubscriptionOptions: AttributeValue[] =
      this.getPossibleOptionsToRestrictShownOptionsForUsersAccessRights(form);
    const profileSubscriptionElements: ProfileSubscriptionElement[] =
      possibleSubscriptionOptions.map((option: AttributeValue) => ({
        displayValue: option.displayValue,
        internalValue: option.internalValue,
        active: false,
      }));
    return this.setActiveStatus(profileSubscriptionElements, form);
  }

  private static getPossibleOptionsToRestrictShownOptionsForUsersAccessRights(
    form: TieFormObject,
  ): AttributeValue[] {
    const gridElementColumnTypes: FormItem[] = this.getGridElementColumnTypes(form);
    const serviceIdElement = gridElementColumnTypes.find((ct) =>
      ct.attributeName.startsWith(SubscriptionFieldKey.serviceId),
    );
    return serviceIdElement?.options ?? [];
  }

  private static getIndexForSubscriptionDeactivated(form: TieFormObject): number {
    const gridElementColumnTypes: FormItem[] = this.getGridElementColumnTypes(form);
    return gridElementColumnTypes.findIndex((ct) =>
      ct.attributeName.startsWith(SubscriptionFieldKey.restriction),
    );
  }

  private static getIndexOfSubscriptionId(form: TieFormObject): number {
    const gridElementColumnTypes: FormItem[] = this.getGridElementColumnTypes(form);
    return gridElementColumnTypes.findIndex((ct) =>
      ct.attributeName.startsWith(SubscriptionFieldKey.serviceId),
    );
  }

  private static setActiveStatus(
    possibleSubscriptionOptions: ProfileSubscriptionElement[],
    form: TieFormObject,
  ): ProfileSubscriptionElement[] {
    const indexOfSubscriptionDeactivated = this.getIndexForSubscriptionDeactivated(form);
    const indexOfSubscriptionId = this.getIndexOfSubscriptionId(form);

    const attributeValuesArray: AttributeValueItem[] = this.getAttributeValuesGridItems(form);

    return possibleSubscriptionOptions.map((option: ProfileSubscriptionElement) => {
      const correspondingAttributeValueItem: AttributeValueItem = attributeValuesArray.find(
        (item) => item.attrValues[indexOfSubscriptionId].internalValue === option.internalValue,
      );
      option.active =
        correspondingAttributeValueItem !== undefined &&
        correspondingAttributeValueItem?.attrValues[indexOfSubscriptionDeactivated]
          .internalValue !== FormItemBooleanString.true;
      return option;
    });
  }

  private static getGridElementShowType(form: TieFormObject): FormItem {
    const gridElement = form.showTypes.BODY?.items.find((ct) => ct.t === FormItemType.grid);
    return structuredClone(gridElement);
  }

  private static getGridElementColumnTypes(form: TieFormObject): FormItem[] {
    const gridElement = this.getGridElementShowType(form);
    return structuredClone(gridElement.columnTypes) ?? [];
  }

  private static getGridElementAttributeName(form: TieFormObject): string {
    const gridElement: FormItem = form.showTypes.BODY?.items.find(
      (ct) => ct.t === FormItemType.grid,
    );
    return gridElement.attributeName;
  }

  private static getAttributeValuesGridItems(form: TieFormObject): AttributeValueItem[] {
    const attributeValues = form.attributeValues;
    if (!attributeValues) {
      return [];
    }
    const attributeName = this.getGridElementAttributeName(form);
    if (!attributeName) {
      return [];
    }
    return attributeValues[attributeName]?.items ?? [];
  }

  public static mapProfileSubscriptionElementsToInvoker(
    invoker: Invoker,
    subscriptionManagementForm: TieFormObject,
    profileSubscriptionElements: ProfileSubscriptionElement[],
  ): Invoker {
    const saveInvoker: Invoker = FormMapper.resolveStepInvoker(
      [InvokerTypes.SAVE],
      invoker.activityURL,
      subscriptionManagementForm,
    )[0];

    this.injectProfileSubscriptionElementsToInvoker(
      saveInvoker.invoker,
      profileSubscriptionElements,
      subscriptionManagementForm,
    );

    return saveInvoker;
  }

  public static getGridIndexToUpdate(
    gridItems: AttributeValueItem[],
    indexForSubscriptionId: number,
    profileSubscriptionElement: ProfileSubscriptionElement,
  ): number {
    return gridItems.findIndex(
      (gridItem) =>
        gridItem.attrValues[indexForSubscriptionId].internalValue ===
        profileSubscriptionElement.internalValue,
    );
  }

  public static injectNewGridElementForAccordingSubscription(
    gridItems: AttributeValueItem[],
    profileSubscriptionElement: ProfileSubscriptionElement,
    form: TieFormObject,
  ) {
    const gridElement = this.getGridElementShowType(form);
    gridItems.unshift(gridElement.defaultItem);
    const indexOfSubscriptionId = this.getIndexOfSubscriptionId(form);
    gridItems[0].attrValues[indexOfSubscriptionId].internalValue =
      profileSubscriptionElement.internalValue;
  }

  public static updateGridElementAccordingToSubscription(
    gridItems: AttributeValueItem[],
    gridItemToUpdateIndex: number,
    indexForSubscriptionDeactivated: number,
    profileSubscriptionElement: ProfileSubscriptionElement,
  ) {
    gridItems[gridItemToUpdateIndex].attrValues[indexForSubscriptionDeactivated].internalValue =
      profileSubscriptionElement.active ? FormItemBooleanString.false : FormItemBooleanString.true;
    gridItems[gridItemToUpdateIndex].attrValues[indexForSubscriptionDeactivated].displayValue =
      profileSubscriptionElement.active ? FormItemBooleanString.false : FormItemBooleanString.true;
  }

  public static injectProfileSubscriptionElementsToInvoker(
    invoker: InvokerBody,
    profileSubscriptionElements: ProfileSubscriptionElement[],
    form: TieFormObject,
  ): void {
    const parameters = invoker.parameters;
    const gridAttributeName = this.getGridElementAttributeName(form);
    const gridItems = parameters[gridAttributeName]?.items;
    const indexForSubscriptionDeactivated = this.getIndexForSubscriptionDeactivated(form);
    const indexForSubscriptionId: number = this.getIndexOfSubscriptionId(form);

    for (const profileSubscriptionElement of profileSubscriptionElements) {
      let gridItemToUpdateIndex = this.getGridIndexToUpdate(
        gridItems,
        indexForSubscriptionId,
        profileSubscriptionElement,
      );

      const gridElementDoesNotExist = gridItemToUpdateIndex === -1;
      if (gridElementDoesNotExist) {
        gridItemToUpdateIndex = 0;
        this.injectNewGridElementForAccordingSubscription(
          gridItems,
          profileSubscriptionElement,
          form,
        );
      }
      this.updateGridElementAccordingToSubscription(
        gridItems,
        gridItemToUpdateIndex,
        indexForSubscriptionDeactivated,
        profileSubscriptionElement,
      );
    }
  }
}
