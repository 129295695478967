import { Component, Input } from '@angular/core';
import { LaboratoryResults } from 'projects/core/src/lib/models/laboratory-results.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';

@Component({
  selector: 'lib-laboratory-results',
  templateUrl: './laboratory-results.component.html',
  styleUrl: './laboratory-results.component.scss',
})
export class LaboratoryResultsComponent {
  @Input() laboratoryResults: LaboratoryResults;

  selectedGroup: number = 0;
  isMenuPopupOpen: boolean = false;

  constructor(private readonly breakpointService: BreakpointService) {}

  get laboratoryResultGroupCount(): number {
    return this.laboratoryResults?.groups?.length;
  }

  get showSegmentNavigation(): boolean {
    const isMobileView: boolean = this.breakpointService.isBelow('md');
    return (isMobileView && this.laboratoryResultGroupCount < 3) || !isMobileView;
  }
}
