import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ActionSheetButton, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import {
  ButtonActionType,
  DownloadDetails,
  DynamicButton,
  PrintDetails,
} from '../models/dynamic-button.model';
import { LabelData } from '../models/form.model';
import { DomSanitizerService } from './dom-sanitizer.service';
import { PDFService } from './pdf.service';

@Injectable()
export class LabelService {
  public printTrigger: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private pdfService: PDFService,
    private domSanitizerService: DomSanitizerService,
    private translateService: TranslateService,
    private platform: Platform,
  ) {}

  public async constructDownloadAndPrintButtons(labelData: LabelData): Promise<DynamicButton[]> {
    const fileName: string = await this.generateLabelFilename(labelData);
    const dataUrl: SafeResourceUrl = await this.generateSanitizedLabelUrl(labelData);
    const labelPrintButton: DynamicButton = await this.constructLabelPrintButton(fileName, dataUrl);
    const labelDownloadButton: DynamicButton = await this.constructLabelDownloadButton(
      fileName,
      dataUrl,
    );
    return [labelPrintButton, labelDownloadButton];
  }

  public isPrintCapableDevice(): boolean {
    return !this.platform.platforms().includes('android');
  }

  public printLabel(iframe: ElementRef): void {
    iframe?.nativeElement.focus();
    iframe?.nativeElement.contentWindow.print();
  }

  private async generateLabelFilename(labelData: LabelData): Promise<string> {
    const translations: string = await firstValueFrom(
      this.translateService.get('core.label-print-pdf'),
    );
    const orderNumber: string = labelData.orderNumber || translations['undefined-data-fallback'];
    return `${orderNumber}_${labelData.patientsFamilyName}`.match(/\w/g)?.join('') + '.pdf';
  }

  private async generateSanitizedLabelUrl(labelData: LabelData): Promise<SafeResourceUrl> {
    const labelBlob: Blob = await this.pdfService.get10cmLabel({ ...labelData });
    const url: string = URL.createObjectURL(labelBlob);
    return this.domSanitizerService.sanitizeResourceUrl(url);
  }

  private async constructLabelPrintButton(
    fileName: string,
    safeUrl: SafeResourceUrl,
  ): Promise<DynamicButton> {
    const printButtonTranslation: string = await firstValueFrom(
      this.translateService.get('shared.label-print.print-labels'),
    );
    const printDetails: PrintDetails = new PrintDetails(fileName, safeUrl);
    return new DynamicButton({ extended: printButtonTranslation }, null, printDetails);
  }

  private async constructLabelDownloadButton(
    fileName: string,
    dataUrl: SafeResourceUrl,
  ): Promise<DynamicButton> {
    const downloadButtonTranslation: string = await firstValueFrom(
      this.translateService.get('shared.documents.download'),
    );
    const href: string = this.domSanitizerService.sanitizeSafeResourceUrlForDownload(dataUrl);
    const downloadDetails: DownloadDetails = new DownloadDetails(fileName, href);
    return new DynamicButton({ extended: downloadButtonTranslation }, null, downloadDetails);
  }

  public constructLabelPrintActionSheetButton(button: DynamicButton): ActionSheetButton | null {
    if (button.hasPrintActivity && this.isPrintCapableDevice()) {
      return {
        text: button.label.extended,
        data: button.printDetails,
        role: ButtonActionType.print,
        htmlAttributes: {
          color: 'tertiary',
          'aria-label': button.label.extended,
        },
        icon: 'print-outline',
      };
    }
    return null;
  }
}
