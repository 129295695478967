import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IconDefinition,
  faCircleArrowDown,
  faCircleArrowUp,
  faCircleChevronLeft,
  faCircleChevronRight,
  faColumns,
} from '@fortawesome/free-solid-svg-icons';
import {
  TableDataType,
  TableHeaderItem,
  TableList,
  TableRowState,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { AttributeFieldType } from 'projects/core/src/lib/models/sdapi-form-object.model';
import { SortDirection } from 'projects/core/src/lib/models/shared.model';
import { TableDataService } from 'projects/core/src/lib/services/table-data.service';

@Component({
  selector: 'lib-generic-table-header',
  templateUrl: './generic-table-header.component.html',
  styleUrl: './generic-table-header.component.scss',
})
export class GenericTableHeaderComponent {
  @Output() eventMessage = new EventEmitter();

  @Input() table: TableList;
  @Input() rowState: TableRowState;
  @Input() isSearchActive: boolean;

  isColumnIndicatorExpanded = false;

  readonly icons = {
    asc: faCircleArrowDown,
    desc: faCircleArrowUp,
    columns: faColumns,
    chevronLeft: faCircleChevronLeft,
    chevronRight: faCircleChevronRight,
  };

  constructor(private tableDataService: TableDataService) {}

  get displayHeader(): TableHeaderItem[] {
    return TableDataService.getVisibleTableItemList<TableHeaderItem>(
      this.table.sortedHeader,
      this.rowState.columnNumber.current,
    );
  }

  getSortIconByType(type: TableDataType | AttributeFieldType, direction: boolean): IconDefinition {
    if (type === TableDataType.date || type === AttributeFieldType.date) {
      direction = !direction;
    }
    return direction ? this.icons.asc : this.icons.desc;
  }

  updateSortParamsAndSortVertically(headerLabel: TableHeaderItem, index: number): void {
    this.table.setSortParams(headerLabel, index);
    this.table.sortColumnsVertically();
  }

  checkSortParams(headerLabel: TableHeaderItem, isAsc: boolean): boolean {
    const direction: SortDirection = isAsc ? SortDirection.ASC : SortDirection.DESC;
    return (
      this.table.sortParams.identifier === headerLabel.identifier.normalizedValue &&
      this.table.sortParams.direction === direction
    );
  }

  updateColumnIndicatorState(value: boolean): void {
    this.isColumnIndicatorExpanded = value;
  }

  shiftColumnsToRight(): void {
    this.table.shiftViewedItemsToRightByOne();
    this.tableDataService.saveColumnPreferences(this.table.sortedHeader, this.table);
  }

  shiftColumnsToLeft(): void {
    this.table.shiftViewedItemsToLeftByOne();
    this.tableDataService.saveColumnPreferences(this.table.sortedHeader, this.table);
  }

  get hiddenHeaderItemCount(): number {
    return TableDataService.getHiddenHeaderItemCount(this.table, this.rowState);
  }

  get hasHiddenHeaderItems(): boolean {
    return this.hiddenHeaderItemCount > 0;
  }
}
