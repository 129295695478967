import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import MiniSearch from 'minisearch';
import {
  EventMessage,
  EventMessageType,
  SearchLayoutType,
  TableConfiguration,
  TableListItem,
  TableRowState,
} from 'projects/core/src/lib/models/dynamic-table.model';
import { ItemSearcherService } from 'projects/core/src/lib/services/item-searcher.service';
import { TableOrganizerService } from 'projects/core/src/lib/services/table-organizer.service';

@Component({
  selector: 'lib-generic-table-toolbar',
  templateUrl: './generic-table-toolbar.component.html',
  styleUrl: './generic-table-toolbar.component.scss',
})
export class GenericTableToolbarComponent implements OnInit {
  @Output() eventMessage: EventEmitter<EventMessage> = new EventEmitter();

  @Input({ required: true }) data: TableConfiguration;
  @Input({ required: true }) rowState: TableRowState;
  @Input() isLoading: boolean;
  @Input() isCard = true;

  searchHandler: MiniSearch<TableListItem>;

  constructor(
    private tableOrganizerService: TableOrganizerService,
    private searchService: ItemSearcherService,
  ) {}

  ngOnInit() {
    this.searchHandler = this.searchService.configureMiniSearch<TableListItem>(
      this.data.searchParams,
    );
  }

  updateTableAfterSearch(): void {
    this.tableOrganizerService.updateTableAfterSearch(
      this.searchHandler,
      this.data.searchParams,
      this.data.table,
    );

    this.eventMessage.emit({ type: EventMessageType.RESORTING, data: false });
  }

  showLoading(): void {
    this.eventMessage.emit({ type: EventMessageType.RESORTING, data: true });
  }

  get searchLayoutType(): SearchLayoutType {
    return this.isCard ? 'card' : 'list';
  }

  get isSearchActive(): boolean {
    return !!this.data.searchParams.criterions;
  }
}
