import { FormIdentifier } from './form.model';

export class SortParams {
  field: string;
  direction: SortDirection = SortDirection.DESC;
  identifier?: string;
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export class SearchParams {
  fields: string[];
  criterions: string;
}

export class FilterParams {
  field: string;
  filters: string[] = [];
}

export class AttributeNameIdentifier {
  readonly originalValue: string;
  readonly normalizedValue: string;

  constructor(identifier: string) {
    this.originalValue = identifier;
    this.normalizedValue = this.normalizeValue(identifier);
  }

  public isEqualTo(comparisonIdentifier: AttributeNameIdentifier): boolean {
    if (comparisonIdentifier.normalizedValue.endsWith('*')) {
      return this.normalizedValue.startsWith(comparisonIdentifier.normalizedValue.replace('*', ''));
    }
    return this.normalizedValue === comparisonIdentifier.normalizedValue;
  }

  public get isFloat(): boolean {
    return this.normalizedValue.startsWith('sys_attribute.f');
  }

  private normalizeValue(identifier: string): string {
    let normalizedValue: string = identifier;
    normalizedValue = identifier?.includes('[') ? normalizedValue.split('[')[0] : normalizedValue;
    normalizedValue = normalizedValue?.toLocaleLowerCase();

    return normalizedValue;
  }
}

export class SystemAttributeName {
  readonly originalValue: FormIdentifier;
  readonly normalizedValue: string;

  constructor(identifier: FormIdentifier) {
    this.originalValue = identifier;
    this.normalizedValue = this.normalizeValue(identifier);
  }

  public isEqualTo(comparisonIdentifier: SystemAttributeName): boolean {
    return this.normalizedValue === comparisonIdentifier.normalizedValue;
  }

  private normalizeValue(identifier: FormIdentifier): string {
    return identifier?.toLocaleLowerCase();
  }
}

export class StatusInfoLabel {
  readonly originalValue: string;

  constructor(label: string) {
    this.originalValue = label;
  }

  get normalizedValue(): string {
    return this.originalValue?.split(/[:\(]/)[0];
  }
}

export class RetryOptions {
  public readonly retryCount: number;
  public readonly delayInMilliseconds: number;

  constructor(retryCount: number, delayInMilliseconds: number) {
    this.retryCount = retryCount;
    this.delayInMilliseconds = delayInMilliseconds;
  }
}
