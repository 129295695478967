import { Component, Input } from '@angular/core';
import {
  FilterSegmentCollection,
  TableFilterSection,
} from 'projects/core/src/lib/models/dynamic-table.model';

@Component({
  selector: 'lib-data-organizer-toolbox',
  templateUrl: './data-organizer-toolbox.component.html',
  styleUrls: ['./data-organizer-toolbox.scss'],
})
export class DataOrganizerToolboxComponent {
  @Input() set filterSegmentCollection(collection: FilterSegmentCollection[]) {
    this.segmentCollection = collection;
    this.activeFilterSection = collection[0].value;
  }

  segmentCollection: FilterSegmentCollection[];
  activeFilterSection: TableFilterSection = TableFilterSection.horizontalSorting;

  readonly filterSectionType: typeof TableFilterSection = TableFilterSection;
}
