import { Injectable } from '@angular/core';
import {
  faCalendar,
  faFile,
  faFlaskVial,
  faFolderTree,
  faHandHoldingMedical,
  faHandshake,
  faNotesMedical,
  faStethoscope,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import {
  DynamicForm,
  DynamicFormConfiguration,
  DynamicFormType,
} from 'projects/core/src/lib/models/form.model';
import {
  SetShowTranslationKeys,
  TranslationOptions,
} from 'projects/core/src/lib/models/modal-action.model';
import { AppointmentsService } from 'projects/core/src/lib/services/appointments.service';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { DocumentsService } from 'projects/core/src/lib/services/documents.service';
import { LaboratoryResultsService } from 'projects/core/src/lib/services/laboratory-results.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { OrdersService } from 'projects/core/src/lib/services/orders.service';
import { PatientService } from 'projects/core/src/lib/services/patient.service';
import { PopupService } from 'projects/core/src/lib/services/popup.service';
import { TreatmentsService } from 'projects/core/src/lib/services/treatment.service';
import {
  PatientMenuItem,
  PatientMenuKeys,
} from 'projects/shared/src/lib/components/patient-summary-browser/patient-summary-list/patient-menu.model';
import { MenuItem } from 'projects/shared/src/lib/models/menu.model';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class PatientMenuService {
  constructor(
    private documentsService: DocumentsService,
    private treatmentsService: TreatmentsService,
    private appointmentsService: AppointmentsService,
    private ordersService: OrdersService,
    private translateService: TranslateService,
    private clientService: ClientConfigService,
    private patientService: PatientService,
    private loadingService: LoadingService,
    private popupService: PopupService,
    private laboratoryResultsService: LaboratoryResultsService,
  ) {}

  public async constructPatientMenu(patientId: string, hideDetails?: boolean): Promise<MenuItem[]> {
    const translations: any = await this.getMenuItemTranslations();
    const menu: MenuItem[] = [];
    const menuConfiguration: PatientMenuItem[] = await this.menuConfiguration(patientId);

    for (const item of menuConfiguration) {
      const includeItemDetails: boolean = !hideDetails && !!item.getDetails;
      if (item.active) {
        const menuItem: MenuItem = {
          icon: item.icon as IconDefinition,
          title: translations[item.key],
          idSuffix: item.key,
          url: item.url,
          visibility: true,
          details: includeItemDetails ? await item.getDetails() : null,
        };
        menu.push(menuItem);
      }
    }

    return menu;
  }

  private async menuConfiguration(patientId: string): Promise<PatientMenuItem[]> {
    return [
      {
        key: PatientMenuKeys.appointments,
        icon: faCalendar,
        url: `/portal/patients/${patientId}/${PatientMenuKeys.appointments}`,
        active: await this.appointmentsService.hasAccessToPatientsAppointments(),
        getDetails: () => this.getAppointmentMenuDetails(),
      },
      {
        key: PatientMenuKeys.treatments,
        icon: faStethoscope,
        url: `/portal/patients/${patientId}/${PatientMenuKeys.treatments}`,
        active: this.checkActiveModule(PatientMenuKeys.treatments),
        getDetails: () => this.getTreatmentMenuDetails(),
      },
      {
        key: PatientMenuKeys.documents,
        icon: faFile,
        url: `/portal/patients/${patientId}/${PatientMenuKeys.documents}`,
        active: this.checkActiveModule(PatientMenuKeys.documents),
        getDetails: () => this.getDocumentMenuDetails(),
      },
      {
        key: PatientMenuKeys.orders,
        icon: faNotesMedical,
        url: `/portal/patients/${patientId}/${PatientMenuKeys.orders}`,
        active: this.checkActiveModule(PatientMenuKeys.orders),
        getDetails: () => this.getOrderMenuDetails(),
      },
      {
        key: PatientMenuKeys.aftercare,
        icon: faHandHoldingMedical,
        url: `/portal/patients/${patientId}/${PatientMenuKeys.aftercare}`,
        active: this.checkActiveModule(PatientMenuKeys.aftercare),
      },
      {
        key: PatientMenuKeys.iheViewer,
        icon: faFolderTree,
        url: `/portal/patients/${patientId}/${PatientMenuKeys.iheViewer}`,
        active: this.checkActiveModule(PatientMenuKeys.iheViewer),
      },
      {
        key: PatientMenuKeys.setShow,
        icon: faHandshake,
        url: null,
        active: await this.hasSetShowPermission(patientId),
      },
      {
        key: PatientMenuKeys.laboratory,
        icon: faFlaskVial,
        url: `/portal/patients/${patientId}/${PatientMenuKeys.laboratory}`,
        active: await this.laboratoryResultsService.hasAccessToPatientsLaboratoryResults(),
      },
    ];
  }

  public async constructPatientDataMenuItem(patientId: string): Promise<MenuItem> {
    const translations: any = await this.getMenuItemTranslations();
    return {
      icon: faFile,
      title: translations[PatientMenuKeys.patientData],
      idSuffix: PatientMenuKeys.patientData,
      url: `/portal/patients/${patientId}/${PatientMenuKeys.patientData}`,
      visibility: true,
    };
  }

  public checkActiveModule(key: string): boolean {
    return this.clientService.get().activeModules[key];
  }

  public getSkeletonMenuItems(): number[] {
    return Object.values(PatientMenuKeys)
      .filter((key: PatientMenuKeys) => this.checkActiveModule(key))
      .map((_, i) => i);
  }

  public hideActiveMenuItem(menu: MenuItem[], itemValue: string): void {
    menu.forEach((item: MenuItem) => {
      if (item.idSuffix === itemValue) {
        item.visibility = false;
      }
    });
  }

  public async openPatientAccessGrantingModal(patientID: string): Promise<void> {
    await this.loadingService.load();
    const accessGrantingForm: DynamicForm = await firstValueFrom(
      this.patientService.getSetShowForm(patientID),
    );
    await this.popupService.showDynamicFormModal(
      new DynamicFormConfiguration({
        dynamicForm: accessGrantingForm,
        type: DynamicFormType.MULTICHOSEN,
        translationOptions: this.translationOptionsForAccessGranting,
      }),
    );
  }

  private async hasSetShowPermission(patientId: string): Promise<boolean> {
    return await firstValueFrom(this.patientService.checkSetShow(patientId));
  }

  private async getMenuItemTranslations(): Promise<any> {
    return await firstValueFrom(
      this.translateService.get('shared.patient-summary-list.menu-item-labels'),
    );
  }

  private async getTreatmentMenuDetails(): Promise<string> {
    return await firstValueFrom(
      this.translateService.get('shared.patients.treatments-count', {
        count: await this.treatmentsService.getTreatmentCount(),
      }),
    );
  }

  private async getDocumentMenuDetails(): Promise<string> {
    return await firstValueFrom(
      this.translateService.get('shared.patients.documents-count', {
        count: await this.documentsService.getDocumentCount(),
      }),
    );
  }

  private async getOrderMenuDetails(): Promise<string> {
    return await firstValueFrom(
      this.translateService.get('shared.patients.orders-count', {
        count: await this.ordersService.getOrderCountOfSpecificPatient(),
      }),
    );
  }

  private async getAppointmentMenuDetails(): Promise<string> {
    return await firstValueFrom(
      this.translateService.get('shared.patients.past-and-upcoming-appointments', {
        upcomingCount: await this.appointmentsService.getUpcomingAppointmentCount(),
        pastCount: await this.appointmentsService.getPastAppointmentCount(),
      }),
    );
  }

  private get translationOptionsForAccessGranting(): TranslationOptions {
    return {
      keys: SetShowTranslationKeys,
      successMessageKey: 'save-completion',
      actionInProgressKey: 'saving-in-progress',
    };
  }
}
