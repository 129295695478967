import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ActionSheetButton, ActionSheetController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import {
  ButtonActionType,
  DynamicButtonSetConfiguration,
} from 'projects/core/src/lib/models/dynamic-button.model';
import { Invoker } from 'projects/core/src/lib/models/invoker-body.model';
import { DownloadService } from 'projects/core/src/lib/services/download.service';
import { DynamicButtonsService } from 'projects/core/src/lib/services/dynamic-buttons.service';
import { LabelService } from 'projects/core/src/lib/services/label.service';

@Component({
  selector: 'lib-dynamic-action-sheet',
  templateUrl: './dynamic-action-sheet.component.html',
})
export class DynamicActionSheetComponent implements OnDestroy {
  @ViewChild('iframe') iframe: ElementRef;

  @Input() config: DynamicButtonSetConfiguration;

  @Output() actionTrigger = new EventEmitter<Invoker>();

  actionSheetElement: HTMLIonActionSheetElement;

  constructor(
    public labelService: LabelService,
    private actionSheetController: ActionSheetController,
    private dynamicButtonsService: DynamicButtonsService,
    private downloadService: DownloadService,
  ) {}

  async ngOnDestroy(): Promise<void> {
    await this.actionSheetElement?.dismiss();
  }

  async openActionSheet(): Promise<void> {
    const buttons: ActionSheetButton[] = await this.getActionSheetButtons();
    this.actionSheetElement = await this.actionSheetController.create({
      cssClass: 'action-sheet',
      buttons,
      mode: 'md',
      htmlAttributes: {
        'aria-label': this.config.triggerButton.label,
      },
    });
    await this.actionSheetElement.present();
    const dismissEvent: OverlayEventDetail = await this.actionSheetElement.onDidDismiss();
    this.handleActionSheetButtonAction(dismissEvent);
  }

  public handleActionSheetButtonAction(event: OverlayEventDetail): void {
    switch (event.role) {
      case ButtonActionType.invokerAction:
        this.actionTrigger.emit(event.data);
        break;
      case ButtonActionType.download:
        void this.downloadService.handleDownload(event.data.fileName, event.data.href);
        break;
      case ButtonActionType.print:
        this.labelService.printLabel(this.iframe);
        break;
      case ButtonActionType.cancel:
        break;
      default:
        console.warn(`Error in DynamicActionSheetComponent. Unknown event role: ${event.role}`);
        break;
    }
  }

  private async getActionSheetButtons(): Promise<ActionSheetButton[]> {
    const dynamicButtons: ActionSheetButton[] =
      this.dynamicButtonsService.constructActionSheetButtons(this.config);
    const backButton: ActionSheetButton = await this.dynamicButtonsService.constructBackButton();
    return [...dynamicButtons, backButton];
  }
}
