import { Component, Input } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { Patient } from 'projects/core/src/lib/models/patient.model';
import { NavigationMenuPopoverComponent } from 'projects/shared/src/lib/components/navigation-menu-popover/navigation-menu-popover.component';
import { MenuItem } from 'projects/shared/src/lib/models/menu.model';
import { PatientMenuService } from 'projects/shared/src/lib/services/patient-menu.service';

@Component({
  selector: 'lib-patient-details-header',
  templateUrl: './patient-details-header.component.html',
  styleUrl: './patient-details-header.component.scss',
})
export class PatientDetailsHeaderComponent {
  @Input() set viewPatient(patient: Patient) {
    this.patient = patient;
    if (patient) {
      this.initializePatientMenu();
    }
  }
  menu: MenuItem[];
  patient: Patient;
  popoverTriggerId: string;
  activeMenuItem: string;

  constructor(
    private popoverController: PopoverController,
    private patientMenuService: PatientMenuService,
    private route: ActivatedRoute,
  ) {}

  async showPatientMenuPopover(): Promise<void> {
    const popover: HTMLIonPopoverElement = await this.popoverController.create({
      component: NavigationMenuPopoverComponent,
      componentProps: { patientId: this.patient.patientID, menu: this.menu },
      trigger: this.popoverTriggerId,
      reference: 'trigger',
      alignment: 'start',
      animated: true,
      side: 'bottom',
      arrow: false,
    });
    popover.keepContentsMounted = true;
    await popover.present();
  }

  private initializePatientMenu(): void {
    this.extractActiveMenuItemKey();
    this.generatePopoverTriggerId();
    this.constructPatientMenu();
  }

  private extractActiveMenuItemKey(): void {
    const urlSegments: UrlSegment[] = this.route.snapshot.parent?.url;
    if (urlSegments?.length > 0) {
      this.activeMenuItem = urlSegments[urlSegments.length - 1].path;
    }
  }

  private async constructPatientMenu(): Promise<void> {
    this.menu = await this.patientMenuService.constructPatientMenu(this.patient.patientID, true);
    this.menu.unshift(
      await this.patientMenuService.constructPatientDataMenuItem(this.patient.patientID),
    );
    this.patientMenuService.hideActiveMenuItem(this.menu, this.activeMenuItem);
  }

  private generatePopoverTriggerId(): void {
    this.popoverTriggerId = 'menu-trigger-' + this.activeMenuItem;
  }
}
