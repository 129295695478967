import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DownloadDetails,
  DynamicButtonSetConfiguration,
} from 'projects/core/src/lib/models/dynamic-button.model';
import { Invoker } from 'projects/core/src/lib/models/invoker-body.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { DownloadService } from 'projects/core/src/lib/services/download.service';
import { LabelService } from 'projects/core/src/lib/services/label.service';

@Component({
  selector: 'lib-action-buttons-container',
  templateUrl: './action-buttons-container.component.html',
  styleUrls: ['./action-buttons-container.component.scss'],
})
export class ActionButtonsContainerComponent {
  @Input({ required: true }) config: DynamicButtonSetConfiguration;
  @Output() actionTrigger: EventEmitter<Invoker> = new EventEmitter<Invoker>();

  constructor(
    public breakPoint: BreakpointService,
    public labelService: LabelService,
    public downloadService: DownloadService,
  ) {}

  shouldGroupButtonsInActionSheet(): boolean {
    return this.breakPoint.isBelow('md') && this.config.actionButtons.length > 2;
  }

  async downloadFile(downloadFile: DownloadDetails): Promise<void> {
    await this.downloadService.handleDownload(downloadFile.fileName, downloadFile.href);
  }
}
