import { Component, OnInit } from '@angular/core';
import { faArrowRight, faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ExpensesMapper } from 'projects/core/src/lib/mappers/expenses.mapper';
import { TableList } from 'projects/core/src/lib/models/dynamic-table.model';
import { ExpensesWidgetRow } from 'projects/core/src/lib/models/expense.model';
import { ExpensesService } from 'projects/core/src/lib/services/expenses.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-expenses-widget',
  templateUrl: './expenses.component.html',
})
@UntilDestroy()
export class ExpensesWidgetComponent implements OnInit {
  readonly desktopView: boolean = false;
  expenses: ExpensesWidgetRow[] = [];
  skeletonItemsNumber = 3;
  isLoading = true;

  icons = {
    arrow: faArrowRight,
    handHoldingDollar: faHandHoldingDollar,
  };

  constructor(private readonly expensesService: ExpensesService) {}

  async ngOnInit(): Promise<void> {
    await this.initializeExpensesList();
  }

  public async initializeExpensesList(): Promise<void> {
    try {
      this.isLoading = true;
      const expensesTableList: TableList = await firstValueFrom(
        this.expensesService.getAllExpenses(),
      );
      const expensesWidgetRows: ExpensesWidgetRow[] =
        ExpensesMapper.mapExpensesTableListToExpensesWidgetRows(expensesTableList);
      this.expenses = expensesWidgetRows
        .filter((expense: ExpensesWidgetRow) => expense.date)
        .sort(
          (a: ExpensesWidgetRow, b: ExpensesWidgetRow) =>
            new Date(b.date).getTime() - new Date(a.date).getTime(),
        )
        .slice(0, 5);
    } catch (error) {
      this.expenses = [];
      console.error('Error getExpenses in mp-expenses', error);
    } finally {
      this.isLoading = false;
    }
  }
}
