import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { faCircleArrowDown, faCircleArrowUp } from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DocumentDetails } from 'projects/core/src/lib/models/documents.model';
import { SortDirection, SortParams } from 'projects/core/src/lib/models/shared.model';
import { TableOrganizerService } from 'projects/core/src/lib/services/table-organizer.service';
import { VirtualScrollService } from 'projects/core/src/lib/services/virtual-scroll.service';

@Component({
  selector: 'lib-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss'],
})
@UntilDestroy()
export class DocumentsListComponent {
  @ViewChild('scrollViewPort') viewPort: CdkVirtualScrollViewport;

  @Input() documentsList: DocumentDetails[];
  @Input() sortParams: SortParams;
  @Input() isSearchActive: boolean;

  @Output() sortParamsUpdated: EventEmitter<void> = new EventEmitter<void>();
  @Output() documentListReload: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public virtualScrollService: VirtualScrollService,
    public tableOrganizerService: TableOrganizerService,
  ) {}

  icons = {
    asc: faCircleArrowDown,
    desc: faCircleArrowUp,
  };

  checkSortParams(field: string, isAsc: boolean): boolean {
    const direction = isAsc ? SortDirection.ASC : SortDirection.DESC;
    return this.sortParams.field === field && this.sortParams.direction === direction;
  }

  setSort(field: string): void {
    if (this.sortParams.field === field) {
      this.sortParams.direction =
        this.sortParams.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
    } else {
      this.sortParams.direction = SortDirection.ASC;
    }
    this.sortParams.field = field;
    this.sortParamsUpdated.emit();
  }
}
