import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { faArrowRightFromBracket, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import { APP_NAME } from 'projects/core/src/lib/injection-tokens';
import { AppName } from 'projects/core/src/lib/models/app.model';
import { Profile } from 'projects/core/src/lib/models/profile.model';
import { AuthService } from 'projects/core/src/lib/services/auth.service';
import { ProfileService } from 'projects/core/src/lib/services/profile.service';

@Component({
  selector: 'lib-referrer-header',
  templateUrl: './referrer-header.component.html',
  styleUrls: ['./referrer-header.component.scss'],
})
export class ReferrerHeaderComponent implements OnInit {
  profile: Profile;
  portalName: string;
  appNameKp = AppName.KP;

  readonly ICONS = {
    DOCTOR: faUserDoctor,
    LOGOUT: faArrowRightFromBracket,
  };

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private destroyRef: DestroyRef,
    @Inject(APP_NAME) private appName: AppName,
  ) {}

  async ngOnInit(): Promise<void> {
    this.profileService
      .getCurrentProfile()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((profile) => (this.profile = profile));
  }

  get portalNameKey(): string {
    return this.appName === AppName.KP ? 'general.clinic-portal' : 'general.zp-portal-title';
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }
}
