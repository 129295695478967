import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OverlayEventRole } from 'projects/core/src/lib/services/popup.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-value-copy-popup-content',
  templateUrl: './value-copy-popup-content.component.html',
  styleUrl: './value-copy-popup-content.component.scss',
})
export class ValueCopyPopupContentComponent implements OnInit {
  copyButtonText: string;

  constructor(
    private readonly popoverController: PopoverController,
    private readonly translateService: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.copyButtonText = await firstValueFrom(this.translateService.get('general.copy'));
  }

  async copyValue(): Promise<void> {
    await this.popoverController.dismiss({}, OverlayEventRole.save);
  }
}
