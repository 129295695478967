import { Component, Input, OnInit } from '@angular/core';
import {
  LaboratoryResultFilterState,
  LaboratoryResultTable,
} from 'projects/core/src/lib/models/laboratory-results.model';

@Component({
  selector: 'lib-laboratory-results-filtering',
  templateUrl: './laboratory-results-filtering.component.html',
  styleUrls: [
    './laboratory-results-filtering.component.scss',
    '../../../../data-organizer-toolbox/data-organizer-toolbox.scss',
  ],
})
export class LaboratoryResultsFilteringComponent implements OnInit {
  @Input({ required: true }) tableData: LaboratoryResultTable;

  ngOnInit() {
    if (this.tableData.filterStates.size === 0) {
      this.tableData.initializeFilterState();
    }
  }

  expandFilterOptions(index: number): void {
    this.getFilterState(index).expanded = true;
  }

  resetFilterOptions(index: number): void {
    this.getFilterState(index).expanded = false;
    this.tableData.resetFilters(index);
  }

  getCheckboxStateForSelectAll(index: number): boolean {
    return this.getFilterState(index).areAllCurrentValuesSelected;
  }

  getCheckboxStateForFilterValue(index: number, value: string): boolean {
    return this.getFilterState(index).filteredValues.has(value);
  }

  getFilterValueCount(index: number): number {
    return this.tableData.filterStates.get(index).currentValues.size;
  }

  getFilterState(columnIndex: number): LaboratoryResultFilterState {
    return this.tableData.filterStates.get(columnIndex);
  }
}
