import { Inject, Injectable } from '@angular/core';
import { APP_NAME } from 'projects/core/src/lib/injection-tokens';
import { AppName } from 'projects/core/src/lib/models/app.model';
import { ClientConfig } from 'projects/core/src/lib/models/client.model';
import { ClientConfigService } from 'projects/core/src/lib/services/client-config.service';
import { CustomMenuMapper } from 'projects/shared/src/lib/mappers/custom-menu.mapper';
import { ExternalPageMapper } from 'projects/shared/src/lib/mappers/external-page.mapper';
import { ExternalPageData } from 'projects/shared/src/lib/models/external-page.model';
import { MenuItem } from 'projects/shared/src/lib/models/menu.model';

@Injectable()
export class CustomMenuService {
  constructor(
    private clientService: ClientConfigService,
    @Inject(APP_NAME) private appName: AppName,
  ) {}

  public async generateCustomMenu(): Promise<MenuItem[]> {
    const clientConfig: ClientConfig = this.clientService.get();
    if (!clientConfig.customMenuItems || clientConfig.customMenuItems.length === 0) {
      return [];
    }

    return clientConfig.customMenuItems
      .filter((menuItem) => {
        if (this.appName === AppName.PP) {
          return menuItem.showInPP;
        } else {
          return menuItem.showInZP;
        }
      })
      .map((menuItem) => CustomMenuMapper.map(menuItem));
  }

  public async getExternalPageBySlug(slug: string): Promise<ExternalPageData> {
    const customMenuItems: MenuItem[] = await this.generateCustomMenu();
    return ExternalPageMapper.map(customMenuItems.find((menuItem) => menuItem.idSuffix === slug));
  }
}
