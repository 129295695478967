import { InvokerBody } from './invoker-body.model';
import { AttributeValueCollection, ShowType, ShowTypes } from './sdapi-form-object.model';

export class SDAPIObject<T> {
  t: T;
  [key: string]: any;
}

export class SDAPIResponseObject extends SDAPIObject<ObjectType.bulkServerResponse> {
  target: string;
  bulk: SDAPIObject<ObjectType>[];
}

export class ServerCheckerResult extends SDAPIObject<ObjectType.serverCheckerResult> {
  attributeValues?: AttributeValueCollection;
  showTypes?: ShowTypes;
  items: ServerCheckerMessages[];
}

export type ServerCheckerMessages = {
  type: 'ERROR' | 'WARN';
  msg: string;
  icon: any;
};

export class ObjectCreate extends SDAPIObject<ObjectType.objectCreate> {
  newObjId: number;
}

export class SAPISubMenuObject {
  icon: any;
  invokers: InvokerBody[];
  name: string;
}

export class SDAPIMenuObject extends SDAPIObject<ObjectType.menu> {
  objId?: number;
  activityId?: number;
  methodId?: number;
  behaviorInvoker?: InvokerBody;
  imperativeInvoker?: InvokerBody;
  invokers?: InvokerBody[];
  path?: InvokerPath;
  subMenus?: SAPISubMenuObject[];
}

export class InvokerPath {
  invokers: InvokerBody[];
  name: string;
  [key: string]: any;
}

export class BulkResponseObject {
  t: ObjectType.bulkServerResponse;
  target?: Target.self | Target.blank | Target.executer;
  message?: string;
  newObjId?: number;
  newParentId?: number;
  objId?: number;
  severity?: string;
  txnId?: string;
  type?: string;
  [key: string]: any;
}

export class ObjectRefresh extends SDAPIObject<ObjectType.refreshObj> {
  target: Target.self;
  objId: number;
}

export class URLView extends SDAPIObject<ObjectType.urlView> {
  target: Target.blank;
  url: string;
}

export class ReportObject extends SDAPIObject<ObjectType.report> {
  attrNames: string[];
  attrTypes: string[];
  attrFormats: string[];
  attributeProfileId: number;
  header: string;
  items: ReportItem[];
  showTypes: ShowType[];
  [key: string]: any;
}

export class ReportItem {
  group: string;
  attrValues: string[];
  italic: boolean;
}

export enum ObjectType {
  bulkServerResponse = 'BulkServerResponse',
  closeWindow = 'CloseWindow',
  form = 'Form',
  menu = 'Menu',
  objectCreate = 'ObjectCreate',
  objectList = 'Objlist',
  optionsUpdate = 'GetOptionsUpdateResponse',
  refreshList = 'RefreshList',
  refreshObj = 'RefreshObj',
  report = 'Report',
  statusMessage2 = 'StatusMessage2',
  urlView = 'URLView',
  objectCreateAutoImport = 'ObjectCreateAutoImport',
  serverCheckerResult = 'ServerCheckerResult',
}

export enum Target {
  blank = 'BLANK',
  self = 'SELF',
  executer = 'EXECUTER',
}
