import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { DynamicForm } from 'projects/core/src/lib/models/form.model';
import { Invoker } from 'projects/core/src/lib/models/invoker-body.model';
import { SDAPIResponseObject } from 'projects/core/src/lib/models/sdapi-object.model';
import { TreatmentDetails } from 'projects/core/src/lib/models/treatment.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { OverlayEventRole, PopupService } from 'projects/core/src/lib/services/popup.service';
import { UploadService } from 'projects/core/src/lib/services/upload.service';
import { VirtualScrollService } from 'projects/core/src/lib/services/virtual-scroll.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-document-section',
  templateUrl: './document-section.component.html',
  styleUrl: './document-section.component.scss',
})
export class DocumentSectionComponent {
  @Output() documentUploaded = new EventEmitter<SDAPIResponseObject>();
  @Output() documentListReload = new EventEmitter<void>();
  
  @Input() treatment: TreatmentDetails;
  @Input() isLoading: boolean;

  readonly icons = {
    upload: faCloudArrowUp,
  };

  constructor(
    public virtualScrollService: VirtualScrollService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private uploadService: UploadService,
    private popupService: PopupService,
    public breakpointService: BreakpointService,
  ) {}

  get maxRowAmountOfDocumentSection(): number {
    const offset: number = this.breakpointService.isAbove('sm') ? 260 : 410;
    return Math.floor((window.innerHeight - offset) / 58);
  }

  get shouldShowSectionTitle(): boolean {
    return (
      this.breakpointService.isAbove('sm') ||
      (!this.treatment?.details?.length &&
        !this.treatment?.hasNotesFeature &&
        !this.treatment?.information?.length)
    );
  }

  async loadAndOpenUploadModal(): Promise<void> {
    await this.loadingService.load(
      await firstValueFrom(
        this.translateService.get('shared.treatments.opening-document-upload-form'),
      ),
    );
    await this.retrieveAndShowUploadForm();
  }

  private async retrieveAndShowUploadForm(): Promise<void> {
    const uploadInvoker: Invoker = await firstValueFrom(
      this.uploadService.getUploadFormInvoker(this.treatment.id),
    );
    const uploadForm: DynamicForm = await firstValueFrom(
      this.uploadService.getDocumentUploadForm(uploadInvoker),
    );
    const modalEvent = await this.popupService.showUploadModal(uploadForm);
    if (modalEvent.role === OverlayEventRole.save) {
      this.documentUploaded.emit(modalEvent.data.response);
    }
  }
}
