import { InjectionToken } from '@angular/core';
import { Footer, Hero, Teaser, Testimonial } from './clinic-info.model';

export const CLIENT_CONFIG: InjectionToken<ClientConfig> = new InjectionToken<ClientConfig>(
  'Client Configuration',
);

export class ClientConfig {
  key = 'default';
  domains: string[] = [];
  activeModules: ActiveModules = new ActiveModules();
  termsOfServiceConfig: TermsOfServiceConfig = new TermsOfServiceConfig();
  authConfig: ClientAuthConfig = new ClientAuthConfig();
  apiConfig: ClientApiConfig = new ClientApiConfig();
  dataReloadConfig: DataReloadConfig = new DataReloadConfig();
  customMenuItems: CustomMenuItem[] = [];
  landingpageDataPP: LandingPageDataPP = new LandingPageDataPP();
  landingpageDataZP: LandingPageDataZP = new LandingPageDataZP();
  landingpageDataKP: LandingPageDataKP = new LandingPageDataKP();
  fakeAppointmentData: FakeAppointmentData = new FakeAppointmentData();
  infoTexts: CustomPageInfoTexts = new CustomPageInfoTexts();
  consolidatedAvb = false;
  appTimeZone = 'Europe/Zurich';

  /** @deprecated use {@link ActiveModules} instead */
  showTasks = true;
  /** @deprecated use {@link ActiveModules} instead */
  showAppointments = true;
  /** @deprecated use {@link ActiveModules} instead */
  showRequestNewAppointment = true;
  /** @deprecated use {@link ActiveModules} instead */
  showLandingpage = true;
  /** @deprecated use {@link ActiveModules} instead */
  showThemeEditor = false;

  /**
   * @deprecated use {@link ActiveModules} instead
   */
  adaptDeprecatedActiveModulesProperties() {
    this.activeModules.tasks = this.showTasks;
    this.activeModules.appointments = this.showAppointments;
    this.activeModules.requestAppointment = this.showRequestNewAppointment;
    this.activeModules.landingpage = this.showLandingpage;
    this.activeModules.themeEditor = this.showThemeEditor;
  }

  customizableStrings: I18nOverwrite[] = [];
}

export class I18nOverwrite {
  key: string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
}

export class ActiveModules {
  landingpage = true;
  publicAppointmentBooking = false;
  appointments = true;
  requestAppointment = true;
  tasks = true;
  treatments = true;
  lastActivity = true;
  documents = true;
  aftercare = false;
  orders = true;
  legalNotice = true;
  privacyPolicy = true;
  themeEditor = false;
  iheViewer: IheViewer = null;
  autoIdent = false;
}

export class IheViewer {
  iheConnectorApiBasePath = '/ihe-connector/api/viewer';
}

export class CustomPageInfoTexts {
  appointments: string;
  documents: string;
  profile: string;
  treatments: string;
  patientData: string;
}

export abstract class LandingPageData {
  hero: Hero;
  teaser: Teaser;
  testimonials: Testimonial[];
  footer: Footer;
}

export class LandingPageDataPP extends LandingPageData {}

export class LandingPageDataZP extends LandingPageData {}

export class LandingPageDataKP extends LandingPageData {}

export class FakeAppointmentData {
  doctor = 'Dr. Peter Zuweiser';
  clinic = 'Klinikum Soest';
  patient = 'Dennis Wiosna';
  title = 'Behandlungsbesprechung';
  phoneNumber = '+49 2921 5993700';
}

export class ClientAuthConfig {
  logoutByRedirect = true;
  logoutHttpMethod: HttpMethod = HttpMethod.PUT;
  customLogoutUrl = '/logout';
  type: ClientAuthType = ClientAuthType.TIE;
  redirectOn401 = true;
  changePasswordLink: string = null;
  authCockpitLink: string = null;
  authCockpitMode: ClientAuthCockpitMode = ClientAuthCockpitMode.NEW_TAB;
  passwordHintMessageActive = true;
  customHost: string;
  inactivityTimeout: number;
  authServicePath = `/auth-service/authenticate/start`;
}

export class TermsOfServiceConfig {
  active = false;
  pdfUrl: string = null; //@deprecated use pdfUrls instead for i18n
  pdfUrls: I18nString = null;

  getI18nPdfUrl(languageCode: string): string {
    if (this.pdfUrls) {
      return this.pdfUrls[languageCode] ?? this.pdfUrls.de;
    } else {
      return this.pdfUrl;
    }
  }
}

export enum ClientAuthType {
  F5 = 'F5',
  TIE = 'TIE',
}

export enum ClientAuthCockpitMode {
  SAME_PAGE = 'SAME_PAGE',
  NEW_TAB = 'NEW_TAB',
}

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export class ClientApiConfig {
  patientCreate: EndpointDefinition = new EndpointDefinition(31016331318, 31011909861);
}

export class EndpointDefinition {
  objectId: number;
  activityId?: number;
  templateId?: number;

  constructor(objectId: number, activityId: number, templateId?: number) {
    this.objectId = objectId;
    this.activityId = activityId;
    this.templateId = templateId;
  }
}

export interface CustomMenuItem {
  url: string;
  title: string;
  iconPack: 'fas' | 'far';
  iconName: string;
  showInPP: boolean;
  showInZP: boolean;
  target: 'iframe' | 'tab';
}

export interface I18nString {
  de: string;
  en: string;
  fr: string;
  it: string;
}

export class DataReloadConfig {
  reloadTillNewAppointmentPresent = false;
}
