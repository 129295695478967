@if (laboratoryResultGroupCount) {
  @if (laboratoryResultGroupCount > 1) {
    @if (showSegmentNavigation) {
      <ion-segment [(ngModel)]="selectedGroup">
        @for (group of laboratoryResults.groups; track group.name; let i = $index) {
          <ion-segment-button [value]="i">
            <ion-label class="p4">{{ group.name }}</ion-label>
          </ion-segment-button>
        }
      </ion-segment>
    } @else {
      <div class="menu-button">
        <ion-button
          id="popover-trigger-button"
          class="ion-no-margin"
          color="primary"
          (click)="isMenuPopupOpen = true">
          <fa-icon slot="end" icon="bars"></fa-icon>
          <span>{{ laboratoryResults.groups[selectedGroup].name }}</span>
        </ion-button>

        <ion-popover
          [isOpen]="isMenuPopupOpen"
          (ionPopoverWillDismiss)="isMenuPopupOpen = false"
          [dismissOnSelect]="true"
          trigger="popover-trigger-button"
          alignment="end"
          animated="true"
          arrow="false"
          side="bottom"
          cssClass="popover">
          <ng-template>
            <ion-list class="popover-item-list">
              @for (group of laboratoryResults.groups; track group.name; let i = $index) {
                <ion-item (click)="selectedGroup = i" class="p" lines="none">
                  <ion-label>{{ group.name }}</ion-label>
                </ion-item>
              }
            </ion-list>
          </ng-template>
        </ion-popover>
      </div>
    }
  }

  <div class="table-container">
    @for (group of laboratoryResults.groups; track group.name; let i = $index) {
      @if (i === selectedGroup) {
        <lib-laboratory-results-table [tableData]="group.tableData" [tableName]="group.name">
        </lib-laboratory-results-table>
      }
    }
  </div>
} @else {
  <div class="table-container">
    <lib-auto-layout direction="vertical" [padding]="[1, 0]" [grow]="1" class="notification-box">
      <lib-notification-box-component
        [grow]="1"
        [message]="'shared.laboratory-results.no-data-available' | translate">
      </lib-notification-box-component>
    </lib-auto-layout>
  </div>
}
