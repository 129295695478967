import { Component, Input } from '@angular/core';
import { IconDefinition, IconName } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'lib-information-card',
  templateUrl: './information-card.component.html',
  styleUrl: './information-card.component.scss',
})
export class InformationCardComponent {
  @Input() style: InfoCardStyle = 'info';
  @Input() title: string;
  @Input() icon: IconDefinition | IconName = 'circle-info';
}

export type InfoCardStyle = 'warning' | 'info';
