import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ProfileSubscriptionElement } from 'projects/core/src/lib/mappers/subscription.mapper';
import { SkeletonHeaderOffset } from 'projects/core/src/lib/models/dynamic-table.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { OverlayEventRole } from 'projects/core/src/lib/services/popup.service';
import { ProfileService } from 'projects/core/src/lib/services/profile.service';
import { firstValueFrom } from 'rxjs';
import { ModalHeaderStyle } from '../../modal-helper/header/header.component';

@Component({
  selector: 'lib-auto-ident-information',
  templateUrl: './further-subscriptions-update.component.html',
  styleUrls: ['./further-subscriptions-update.component.scss'],
})
export class FurtherSubscriptionsUpdateComponent implements OnInit {
  @HostBinding('class') class = 'small-modal';

  @Input() profileSubscriptionElements: ProfileSubscriptionElement[];

  readonly headerOffset: SkeletonHeaderOffset = { mobile: 210, desktop: 520 };
  readonly expandedViewThreshold: number = 600;
  readonly icons = { edit: faEdit, save: faSave };
  readonly modalHeaderStyle: string = ModalHeaderStyle.edit;

  isLoading = true;

  translations: any;

  constructor(
    private modalController: ModalController,
    public breakpoint: BreakpointService,
    private profileService: ProfileService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
  ) {}

  async ngOnInit() {
    this.translations = await firstValueFrom(
      this.translateService.get('shared.further-subscriptions'),
    );
  }

  async cancel(): Promise<void> {
    await this.modalController.dismiss(null, OverlayEventRole.cancel);
  }

  async saveFurtherSubscriptions(): Promise<void> {
    try {
      await this.loadingService.load(this.translations['further-subscriptions-being-saved']);
      await this.profileService.saveSubscriptionManagementForm(this.profileSubscriptionElements);
      await this.modalController.dismiss(null, OverlayEventRole.save);
      await this.loadingService.toast(this.translations['further-subscriptions-saved']);
    } finally {
      await this.loadingService.stop();
    }
  }
}
