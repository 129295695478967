import { Injectable } from '@angular/core';
import { SortDirection, SortParams } from '../models/shared.model';

@Injectable()
export class ItemSorterService {
  public static sort(data: any[], sortParams: SortParams): any[] {
    if (!sortParams.field) {
      return data;
    }
    const processedData = [...data];
    return processedData.sort((a, b) => {
      const valueA = ItemSorterService.getNestedValue(sortParams.field, a);
      const valueB = ItemSorterService.getNestedValue(sortParams.field, b);
      if (valueA === null || valueA === undefined) {
        return sortParams.direction === SortDirection.ASC ? -1 : 1;
      }
      if (valueB === null || valueB === undefined) {
        return sortParams.direction === SortDirection.ASC ? 1 : -1;
      }

      return this.compareValuesBasedOnSortingDirection(valueA, valueB, sortParams.direction);
    });
  }

  private static compareValuesBasedOnSortingDirection(
    valueA: any,
    valueB: any,
    direction: SortDirection,
  ): number {
    if (direction === SortDirection.ASC) {
      return valueA < valueB ? -1 : 1;
    } else {
      return valueB < valueA ? -1 : 1;
    }
  }

  private static getNestedValue(name: string, object: object): object {
    const pathArray: string[] = name.split(/\.|\[(\d+)\]/).filter(Boolean);

    return pathArray.reduce((currentObject: object, key: string) => {
      const isArrayIndex: boolean = !isNaN(Number(key));
      if (isArrayIndex) {
        return currentObject[Number(key)];
      } else {
        return currentObject[key];
      }
    }, object);
  }
}
