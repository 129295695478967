<ion-item
  class="item-line auto-ident-item"
  [class.successfully-verified]="autoIdentService.autoIdentSuccess"
  lines="none">
  @if (autoIdentService.autoIdentRequired) {
    <fa-icon slot="start" size="lg" [icon]="icons.userCircleQuestionmark"></fa-icon>
    <p class="p3">{{ 'shared.autoident.profile.account-not-verified' | translate }}</p>
    <ion-button
      (click)="openAutoIdentInformationModal()"
      color="primary"
      class="p3 ion-no-margin"
      fill="solid"
      color="black"
      slot="end"
      size="large">
      {{ 'shared.autoident.to-verification' | translate }}
      <fa-icon slot="end" size="sm" [icon]="icons.arrowRight"></fa-icon>
    </ion-button>
  } @else if (autoIdentService.autoIdentPending) {
    <fa-icon slot="start" size="lg" [icon]="icons.hourGlass"></fa-icon>
    <p class="p3">
      {{
        'shared.autoident.your-account-identification-is-pending-after-successful-verification-you-can-fully-use-your-account'
          | translate
      }}
    </p>
  } @else if (autoIdentService.autoIdentSuccess) {
    <fa-icon slot="start" size="lg" [icon]="icons.userCheck"></fa-icon>
    <p class="p3">
      {{ 'shared.autoident.your-profile-has-been-verified-successfully' | translate }}
    </p>
  }
</ion-item>
