<ion-card color="light" class="ion-no-margin">
  <ion-item lines="none" class="card-header">
    <ng-container *ngIf="!isLoading">
      <h2>{{ title }}</h2>
      @if (requestProfileChangeForm) {
        <ion-button color="light" slot="end" (click)="showRequestProfileChangeForm()">
          <span>{{ 'general.request-change' | translate }}</span>
          <fa-icon slot="end" [icon]="icon.edit"></fa-icon>
        </ion-button>
      } @else if (hasEditableFields) {
        <ion-button color="light" slot="end" (click)="openEditProfileModal()">
          <span>{{ 'general.edit' | translate }}</span>
          <fa-icon slot="end" [icon]="icon.edit"></fa-icon>
        </ion-button>
      }
    </ng-container>

    <ng-container *ngIf="isLoading">
      <ion-skeleton-text class="rounded-small like-h3" animated="true"></ion-skeleton-text>
      <ion-skeleton-text
        class="rounded-small like-h3"
        animated="true"
        slot="end"></ion-skeleton-text>
    </ng-container>
  </ion-item>

  <ng-content></ng-content>

  <ion-list *ngIf="isLoading" class="card-list">
    <ion-item *ngFor="let item of [0, 0, 0, 0, 0, 0]" lines="none" class="item-line">
      <ion-skeleton-text class="rounded-small" animated="true"></ion-skeleton-text>
      <ion-skeleton-text class="rounded-small" animated="true" slot="end"></ion-skeleton-text>
    </ion-item>
  </ion-list>
</ion-card>
