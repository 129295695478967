import { SDAPIResponseObject } from 'projects/core/src/lib/models/sdapi-object.model';
import { ItemSorterService } from '../services/item-sorter.service';
import { FilterSegmentCollection, TableFilterSection } from './dynamic-table.model';
import { SortDirection, SortParams } from './shared.model';

export class LaboratoryResults {
  chartVisible: boolean;
  groups: LaboratoryResultGroup[] = [];
}

export class LaboratoryResultGroup {
  name: string;
  tableData: LaboratoryResultTable;
}

export class LaboratoryResultTable {
  fixedColumns: LaboratoryResultTableColumn[];
  columns: LaboratoryResultTableColumn[];
  rows: LaboratoryResultTableRow[];
  sortParams: SortParams = new SortParams();
  filterStates: Map<number, LaboratoryResultFilterState> = new Map();

  constructor(
    fixedColumns: LaboratoryResultTableColumn[] = [],
    columns: LaboratoryResultTableColumn[] = [],
    rows: LaboratoryResultTableRow[] = [],
  ) {
    this.fixedColumns = fixedColumns;
    this.columns = columns;
    this.rows = rows;
    this.sortParams.direction = SortDirection.ASC;
    this.sortParams.identifier = '0';
  }

  get hasFiltersActive(): boolean {
    const filterStates: LaboratoryResultFilterState[] = Array.from(this.filterStates.values());
    return filterStates.some(
      (filterState: LaboratoryResultFilterState) => filterState.isFilterActive,
    );
  }

  get hasVisibleRows(): boolean {
    return this.rows.some((row: LaboratoryResultTableRow) => row.visible);
  }

  sortColumns(columnIndex: string, direction: SortDirection): void {
    this.sortParams = {
      field: `fixedCells[${columnIndex}].value`,
      direction,
      identifier: columnIndex,
    };
    this.rows = ItemSorterService.sort(this.rows, this.sortParams);
  }

  updateSortParams(columnIndex: string): void {
    this.sortParams.field = `fixedCells[${columnIndex}].value`;
    this.sortParams.identifier = columnIndex;
  }

  initializeFilterState(): void {
    this.fixedColumns.forEach((_column: LaboratoryResultTableColumn, columnIndex: number) => {
      const uniqueValues: Set<string> = this.retrieveUniqueFilterValuesForColumn(columnIndex);
      this.filterStates.set(columnIndex, this.createInitialFilterState(uniqueValues));
    });
  }

  toggleColumnFilterValue(columnIndex: number, value: string, isChecked: boolean): void {
    const filterState: LaboratoryResultFilterState = this.filterStates.get(columnIndex);
    this.updateSelectedFilterValue(filterState, value, isChecked);
    this.filterStates.set(columnIndex, filterState);
    this.updateRowVisibility();
    this.updateUniqueValuesOfInactiveFilterGroups();
  }

  handleSelectAllStateChange(columnIndex: number, isChecked: boolean): void {
    const filterState: LaboratoryResultFilterState = this.filterStates.get(columnIndex);
    this.toggleSelectAllState(filterState, isChecked);
    this.filterStates.set(columnIndex, filterState);
    this.updateRowVisibility();
    this.updateUniqueValuesOfInactiveFilterGroups();
  }

  private toggleSelectAllState(filterState: LaboratoryResultFilterState, isChecked: boolean): void {
    if (isChecked) {
      filterState.filteredValues = new Set(filterState.currentValues);
    } else {
      filterState.filteredValues.clear();
    }
  }

  resetFilters(columnIndex: number): void {
    const filterState: LaboratoryResultFilterState = this.filterStates.get(columnIndex);
    filterState.filteredValues = new Set(filterState.allValues);
    filterState.currentValues = new Set(filterState.allValues);
    this.filterStates.set(columnIndex, filterState);
    this.updateRowVisibility();
    this.updateUniqueValuesOfInactiveFilterGroups();
  }

  private retrieveUniqueFilterValuesForColumn(columnIndex: number): Set<string> {
    return new Set(
      this.rows
        .filter((row: LaboratoryResultTableRow) => row.visible)
        .map((row: LaboratoryResultTableRow) => {
          const value: string = row.fixedCells[columnIndex]?.value;
          return this.isEmptyCellValue(value) ? null : value;
        }),
    );
  }

  private isEmptyCellValue(value: string): boolean {
    return value === '' || value === null || value === undefined;
  }

  private hasEqualCellValues(cellValue: string, uniqueFilterValue: string): boolean {
    if (this.isEmptyCellValue(cellValue) && uniqueFilterValue === null) {
      return true;
    }
    return cellValue === uniqueFilterValue;
  }

  private createInitialFilterState(uniqueValues: Set<string>): LaboratoryResultFilterState {
    return new LaboratoryResultFilterState(
      new Set(uniqueValues),
      new Set(uniqueValues),
      new Set(uniqueValues),
    );
  }

  private updateSelectedFilterValue(
    filterState: LaboratoryResultFilterState,
    value: string,
    isChecked: boolean,
  ): void {
    if (isChecked) {
      filterState.filteredValues.add(value);
    } else {
      filterState.filteredValues.delete(value);
    }
  }

  private updateRowVisibility(): void {
    this.rows.forEach((row: LaboratoryResultTableRow) => {
      row.visible = Array.from(this.filterStates.entries()).every(([columnIndex, filterValue]) => {
        if (filterValue.areAllCurrentValuesSelected) {
          return true;
        }

        const cellValue: string = row.fixedCells[columnIndex]?.value;
        return Array.from(filterValue.filteredValues).some((value: string) =>
          this.hasEqualCellValues(cellValue, value),
        );
      });
    });
  }

  private updateUniqueValuesOfInactiveFilterGroups(): void {
    this.filterStates.forEach((filterState: LaboratoryResultFilterState, filterIndex: number) => {
      if (filterState.areAllCurrentValuesSelected) {
        const updatedUniqueValues = new Set(this.retrieveUniqueFilterValuesForColumn(filterIndex));
        filterState.currentValues = new Set(updatedUniqueValues);
        filterState.filteredValues = new Set(updatedUniqueValues);
        this.filterStates.set(filterIndex, filterState);
      }
    });
  }
}

export class LaboratoryResultFilterState {
  constructor(
    public allValues: Set<string>,
    public currentValues: Set<string>,
    public filteredValues: Set<string>,
    public expanded: boolean = false,
  ) {}

  get areAllCurrentValuesSelected(): boolean {
    return this.filteredValues.size === this.currentValues.size;
  }

  get isFilterActive(): boolean {
    return this.filteredValues.size !== this.allValues.size;
  }
}

export class LaboratoryResultTableColumn {
  name: string;
  type: ObservationResultColumnType;
  tooltip: string;
  fixed: boolean;
  originalSortIndex: number;
  observationDateTime: number;

  constructor(
    name: string,
    type: ObservationResultColumnType,
    tooltip: string,
    fixed: boolean,
    originalSortIndex: number,
    observationDateTime?: number,
  ) {
    this.name = name;
    this.type = type;
    this.tooltip = tooltip;
    this.fixed = fixed;
    this.originalSortIndex = originalSortIndex;
    this.observationDateTime = observationDateTime;
  }
}

export class LaboratoryResultTableRow {
  fixedCells: LaboratoryResultTableCell[] = [];
  cells: LaboratoryResultTableCell[] = [];
  visible: boolean = true;
}

export class LaboratoryResultTableCell {
  readonly value: string;
  readonly originalSortIndex: number;
  readonly flag: LaboratoryResultTableCellFlagDetails | null;
  readonly notes: string[] | null;

  constructor(
    value: string,
    originalSortIndex: number,
    flagDefinitions?: Map<string, ObservationResultFlagsResource>,
    flag?: string | null,
    notes?: string[] | null,
  ) {
    this.value = value;
    this.originalSortIndex = originalSortIndex;
    this.notes = notes;
    if (flag) {
      this.flag = this.resolveFlagDetails(flagDefinitions, flag);
    }
  }

  private resolveFlagDetails(
    flagDefinitions: Map<string, ObservationResultFlagsResource>,
    flag: string | null,
  ): LaboratoryResultTableCellFlagDetails | null {
    const flagResource: ObservationResultFlagsResource = flagDefinitions.get(flag);
    if (flagResource) {
      return { color: flagResource.color, icon: flagResource.icon };
    }
    return null;
  }
}

export interface LaboratoryResultTableCellFlagDetails {
  color: string;
  icon: string;
}

export class PatientObservationResultViewResource extends SDAPIResponseObject {
  observationResultView: ObservationResultViewResource;
}

export interface ObservationResultViewResource {
  chartVisible: boolean;
  sortAscending: boolean;
  groups: ObservationResultGroupsResource[];
  flags: ObservationResultFlagsResource[];
}

export interface ObservationResultFlagsResource {
  flag: string;
  color: string;
  icon: string;
}

export interface ObservationResultGroupsResource {
  groupName: string;
  descriptionColumns: ObservationResultDescriptionColumnsResource[];
  valueColumns: ObservationResultValueColumnsResource[];
}

export interface ObservationResultDescriptionColumnsResource {
  description: string;
  tooltip: string;
  type: ObservationResultColumnType;
  values: string[] | null;
}

export interface ObservationResultValueColumnsResource {
  description: string;
  tooltip: string;
  observationDateTime: number;
  notes: any;
  values: ObservationResultValueColumnsValueResource[];
}

export interface ObservationResultValueColumnsValueResource {
  value: string | null;
  flag: string | null;
  notes: string[] | null;
}

export enum ObservationResultColumnType {
  TEXT = 'TEXT',
  RANGE = 'RANGE',
  UNIT = 'UNIT',
}

export const laboratoryResultsFilterSegments: FilterSegmentCollection[] = [
  { value: TableFilterSection.verticalSorting, labelKey: 'shared.data-organizer.sort' },
  { value: TableFilterSection.filtering, labelKey: 'shared.data-organizer.filter' },
];
