import { APIError } from '../data/errors.data';
import { AttributeDetail } from '../models/attribute.model';
import { DocumentDetails } from '../models/documents.model';
import {
  TableHeaderItem,
  TableList,
  TableListItem,
  TableType,
} from '../models/dynamic-table.model';
import {
  TieTableObjectList,
  TieTableProcessor,
  TieTableRow,
} from '../models/sdapi-table-object.model';
import { AttributeNameIdentifier } from '../models/shared.model';
import {
  StatusInfo,
  TreatmentAttributeNames,
  TreatmentDetails,
  TreatmentDoctorContact,
  TreatmentDoctorContactAttributeNames,
  TreatmentInformationAttributeNames,
} from '../models/treatment.model';
import { TableMapper } from './table.mapper';

export class TreatmentResourceMapper {
  public static mapResourceDetails(resource: TieTableObjectList): TreatmentDetails[] {
    const treatmentList: TreatmentDetails[] = [];
    const table: TieTableProcessor = new TieTableProcessor(resource);
    const rows: TieTableRow[] = table.getRows();

    try {
      treatmentList.push(
        ...rows.map((row: TieTableRow) => TreatmentResourceMapper.mapTreatmentDetails(row)),
      );
    } catch (error) {
      throw new APIError(
        'Mapping of treatment details failed in the TreatmentResourceMapper',
        error,
      );
    }

    return treatmentList;
  }

  public static mapTreatmentInformationResource(resource: TieTableObjectList): DocumentDetails[] {
    const documentDetails: DocumentDetails[] = [];
    const table: TieTableProcessor = new TieTableProcessor(resource);
    const rows: TieTableRow[] = table.getRows();

    try {
      for (const row of rows) {
        const documentsDetails: DocumentDetails = {
          downloadPending: false,
          name: row.getOptionalAttributeValue(TreatmentInformationAttributeNames.title),
          date: row.getOptionalAttributeValue(TreatmentInformationAttributeNames.creationDate)
            ? new Date(
                row.getOptionalAttributeValue(TreatmentInformationAttributeNames.creationDate),
              )
            : undefined,
          id: row.objId,
          size: Number(row.getOptionalAttributeValue(TreatmentInformationAttributeNames.fileSize)),
          mimeType: row.getOptionalAttributeValue(TreatmentInformationAttributeNames.mimeType),
          behaviorInvokers: row.behaviorInvokers,
        };
        documentDetails.push(documentsDetails);
      }
    } catch (error) {
      throw new APIError(
        'Mapping of information documents of a treatment failed in the TreatmentResourceMapper',
        error,
      );
    }

    return documentDetails;
  }

  public static mapTreatmentsTable(resource: TieTableObjectList): TableList {
    let tableHeader: TableHeaderItem[] = [];
    let tableRows: TableListItem[] = [];

    try {
      tableHeader = TableMapper.mapTableHeaderResource(resource, false);
      tableRows = TableMapper.mapTableRowsObjectListResource(resource, false);
    } catch (error) {
      throw new APIError('Mapping of treatments failed in the TreatmentResourceMapper.', error);
    }

    return new TableList(tableHeader, tableRows, {
      title: resource.objName,
      storageKey: this.storageKeyColumnPreferences,
      tableType: TableType.treatments,
    });
  }

  public static mapTreatmentDoctorContactsResource(
    resource: TieTableObjectList,
  ): TreatmentDoctorContact[] {
    const doctorContacts: TreatmentDoctorContact[] = [];
    const table: TieTableProcessor = new TieTableProcessor(resource);
    const rows: TieTableRow[] = table.getRows();

    try {
      for (const row of rows) {
        const doctorContact: TreatmentDoctorContact =
          TreatmentResourceMapper.mapTreatmentDoctorContactResource(table, row);
        doctorContacts.push(doctorContact);
      }
    } catch (error) {
      throw new APIError(
        'Mapping of doctor contacts of a treatment failed in the TreatmentResourceMapper',
        error,
      );
    }

    return doctorContacts;
  }

  private static mapTreatmentDoctorContactResource(
    table: TieTableProcessor,
    row: TieTableRow,
  ): TreatmentDoctorContact {
    const doctorContact: TreatmentDoctorContact = new TreatmentDoctorContact();

    for (const key in TreatmentDoctorContactAttributeNames) {
      if (TreatmentDoctorContactAttributeNames.hasOwnProperty(key)) {
        const attributeName: string = TreatmentDoctorContactAttributeNames[key];
        doctorContact[key] = TreatmentResourceMapper.constructAttributeDetail(
          table,
          row,
          attributeName,
        );
      }
    }

    return doctorContact;
  }

  private static constructAttributeDetail(
    table: TieTableProcessor,
    row: TieTableRow,
    attributeName: string,
  ): AttributeDetail {
    const optionalValue: string = row.getOptionalAttributeValue(attributeName);
    const identifier: AttributeNameIdentifier = new AttributeNameIdentifier(attributeName);
    if (optionalValue) {
      return new AttributeDetail(null, table, identifier, optionalValue);
    }
    return null;
  }

  private static mapTreatmentDetails(row: TieTableRow): TreatmentDetails {
    const treatmentDetails = new TreatmentDetails();
    const treatmentDetailsValues = {
      id: row.objId,
      title: row.getOptionalAttributeValue(TreatmentAttributeNames.title),
      date: row.getOptionalAttributeValue(TreatmentAttributeNames.date)
        ? new Date(row.getOptionalAttributeValue(TreatmentAttributeNames.date))
        : undefined,
      doctor: row.getOptionalAttributeValue(TreatmentAttributeNames.doctor),
      location: row.getOptionalAttributeValue(TreatmentAttributeNames.location),
      status: row.statusInfo as StatusInfo,
    };
    return Object.assign(treatmentDetails, treatmentDetailsValues);
  }

  private static readonly storageKeyColumnPreferences: string = `treatmentsColumnPreference`;
}
