import { Component, Input } from '@angular/core';
import { Patient } from 'projects/core/src/lib/models/patient.model';

@Component({
  selector: 'lib-patient-data-view-wrapper',
  templateUrl: './patient-data-view-wrapper.component.html',
  styleUrls: ['./patient-data-view-wrapper.component.scss'],
})
export class PatientDataViewWrapperComponent {
  @Input() fullWidth = false;
  @Input() patient: Patient;
}
