export class About {
  appVersion?: string;
  healthEngineVersion?: HealthEngineVersion;
}

export type HealthEngineVersion = {
  majorVersion: number;
  minorVersion: number;
  patchLevel: number;
  preRelease: string;
  buildMetadata: string;
};
