<ion-card color="light" class="ion-no-margin" [ngClass]="style">
  <ion-card-header>
    <lib-auto-layout direction="horizontal" [padding]="[10]" [gap]="[10]" vertical="center">
      <fa-icon [icon]="icon" size="lg"></fa-icon>
      <h3 class="ion-no-margin">
        {{ title }}
      </h3>
    </lib-auto-layout>
  </ion-card-header>

  <ng-content select="[infoCardContent]"></ng-content>
</ion-card>
