import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AccessDeniedError } from '../data/errors.data';
import { LastActivitiesMapper } from '../mappers/last-activity.mapper';
import { InvokerMethods } from '../models/invoker-body.model';
import { LastActivity } from '../models/last-activities.model';
import { Patient } from '../models/patient.model';
import { ReportObject } from '../models/sdapi-object.model';
import { PatientService } from './patient.service';
import { SDAPIService } from './sdapi.service';

@Injectable()
export class LastActivitiesService {
  constructor(
    private readonly patientService: PatientService,
    private readonly sdapiService: SDAPIService,
  ) {}

  public getLastActivities(): Observable<LastActivity[]> {
    return this.patientService.getCurrentPatient().pipe(
      concatMap((patient: Patient | null) => {
        if (!patient) {
          return throwError(() => new AccessDeniedError());
        }
        return this.findLastActivitiesOfPatient(patient.patientID);
      }),
      map((response: ReportObject) => LastActivitiesMapper.mapResourceDetails(response)),
      catchError((error) => {
        console.error('Error fetching last activities:', error);
        return throwError(() => error);
      }),
    );
  }

  private findLastActivitiesOfPatient(patientID: string): Observable<ReportObject> {
    const dataMap: Map<string, string> = new Map([['TEMP.PATIENT_ID[BODY,1]', patientID]]);

    return this.sdapiService.findDataObjectWithMetaFinder<ReportObject>(
      'PP_LAST_ACTIVITIES_W_ID',
      InvokerMethods.finderReport,
      dataMap,
    );
  }
}
