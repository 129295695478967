import { Injectable } from '@angular/core';
import { firstValueFrom, interval, startWith, Subscription, timer } from 'rxjs';
import { Invoker, InvokerReferences } from '../models/invoker-body.model';
import { ClientConfigService } from './client-config.service';
import { ProfileService } from './profile.service';
import { SDAPIService } from './sdapi.service';

@Injectable({
  providedIn: 'root',
})
export class AutoIdentService {
  showAutoIdentUnblockTimerSubscription?: Subscription;
  autoIdentUpdateIntervalSubscription?: Subscription;

  autoIdentInvoker: Invoker;
  autoIdentRequired = false;
  autoIdentPending = false;
  autoIdentSuccess = false;
  autoIdentUpdatingDelay = false;

  autoIdentStatusBlockedAfterSubmit = false;

  constructor(
    private profileService: ProfileService,
    private sdapiService: SDAPIService,
    private clientConfigService: ClientConfigService,
  ) {
    this.startAutoIdentUpdateInterval();
  }

  async updateAutoIdentInvokerForUser(): Promise<void> {
    try {
      if (!this.clientConfigService.get()?.activeModules?.autoIdent) {
        return;
      }
      if (this.autoIdentUpdatingDelay) {
        return;
      }

      const userObjectId = await firstValueFrom(this.profileService.getUserObjId());
      const invokers = await firstValueFrom(
        this.sdapiService.extractInvokerFromUserMenuByReference(userObjectId.toString(), [
          InvokerReferences.portalIdentStart,
          InvokerReferences.portalIdentRestart,
          InvokerReferences.portalIdentPending,
        ]),
      );

      this.autoIdentInvoker = null;

      this.autoIdentRequired = false;
      this.autoIdentPending = false;
      this.autoIdentSuccess = false;

      if (!invokers?.length) {
        this.autoIdentSuccess = true;
        this.autoIdentStatusBlockedAfterSubmit = false;
        if (this.autoIdentUpdateIntervalSubscription) {
          this.autoIdentUpdateIntervalSubscription.unsubscribe();
        }
        return;
      }

      if (invokers?.length > 0) {
        this.autoIdentInvoker = invokers[0];
      }

      if (
        [InvokerReferences.portalIdentStart, InvokerReferences.portalIdentRestart].includes(
          this.autoIdentInvoker?.invoker?.reference,
        ) &&
        !this.autoIdentStatusBlockedAfterSubmit
      ) {
        this.autoIdentRequired = true;
      } else if (
        this.autoIdentInvoker?.invoker?.reference === InvokerReferences.portalIdentPending ||
        this.autoIdentStatusBlockedAfterSubmit
      ) {
        this.autoIdentPending = true;
        this.autoIdentStatusBlockedAfterSubmit = false;
      }
    } catch (error) {
      console.warn('Could not update autoIdentInvoker for user', error);
    }
  }

  startAutoIdentUpdateInterval(): void {
    this.autoIdentUpdateIntervalSubscription = interval(2 * 60 * 1000)
      .pipe(startWith(0))
      .subscribe(async () => {
        await this.updateAutoIdentInvokerForUser();
      });
  }

  async setAutoIdentStatusUpdating(seconds: number = 15): Promise<void> {
    this.autoIdentUpdatingDelay = true;
    timer(seconds * 1000).subscribe(async () => {
      this.autoIdentUpdatingDelay = false;
      await this.updateAutoIdentInvokerForUser();
    });
  }

  async blockShowingAutoIdentAndRefreshStatus(minutes: number = 2): Promise<void> {
    this.autoIdentStatusBlockedAfterSubmit = true;
    await this.updateAutoIdentInvokerForUser();
    this.showAutoIdentUnblockTimerSubscription = timer(minutes * 60 * 1000).subscribe(async () => {
      this.autoIdentStatusBlockedAfterSubmit = false;
      await this.updateAutoIdentInvokerForUser();
    });
  }
}
