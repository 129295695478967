export const loadPdfViewer = async (): Promise<void> => {
  try {
    if (window['pdfWorkerSrc']) {
      return;
    }

    const url = 'assets/js/pdf.worker.min.js';
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch script: ${response.statusText}`);
    }

    const scriptText = await response.text();
    const blob = new Blob([scriptText], { type: 'text/javascript' });
    const blobUrl = URL.createObjectURL(blob);

    (window as any).pdfWorkerSrc = blobUrl;

    if (window['pdfjsLib']) {
      window['pdfjsLib'].GlobalWorkerOptions.workerSrc = blobUrl;
    } else {
      console.error('pdfjsLib is not loaded. Make sure to load PDF.js library first.');
    }
  } catch (error) {
    console.error('Failed to load external script:', error);
  }
};
