<ion-card class="card-transparent ion-no-padding">
  <lib-auto-layout [gap]="showPatientCreateButton ? [20] : [0]" vertical="center">
    <lib-search-input
      [disabled]="!patients.length"
      [searchParams]="searchParams"
      [placeholder]="'shared.patients.searchbar-placeholder' | translate"
      (searchParamsChanged)="getPatientResults()"
      (inputChanged)="showLoading()"
      [grow]="1">
    </lib-search-input>

    <lib-auto-layout class="toolbar-item card-layout" [gap]="[1]">
      @if (showPatientCreateButton) {
        <ion-button
          class="ion-no-margin"
          color="primary"
          (click)="createPatient()"
          data-api-id="patient-creation">
          <span class="ion-hide-md-down">{{ 'shared.patients.new-patient' | translate }}</span>
          <span class="ion-hide-md-up">{{ 'general.new' | translate }}</span>
          <fa-icon slot="end" icon="user-plus"></fa-icon>
        </ion-button>
      }
    </lib-auto-layout>
  </lib-auto-layout>
</ion-card>

<lib-card-list type="list">
  @if (!isLoading) {
    @if (lastVisitedPatients.length && !searchParams.criterions) {
      <lib-section-divider
        id="recently-viewed-patients"
        [title]="'shared.patients.last-viewed-patients' | translate"
        [icon]="'clock-rotate-left'">
        <div section>
          @for (patient of lastVisitedPatients; track patient.patientID) {
            <lib-patient-result-row (click)="openPatient(patient)" [patient]="patient">
            </lib-patient-result-row>
          }
        </div>
      </lib-section-divider>
    }

    @if (patients.length) {
      <lib-section-divider [title]="'shared.patients.all-patients' | translate" [icon]="'users'">
        <div section>
          @for (patient of filteredPatients; track patient.patientID) {
            <lib-patient-result-row (click)="openPatient(patient)" [patient]="patient">
            </lib-patient-result-row>
          }
          @if (patients.length > displayedPatientsCount && !searchParams.criterions) {
            <ion-infinite-scroll (ionInfinite)="displayMorePatients($event)">
              <ng-container *ngTemplateOutlet="skeletonItem"></ng-container>
            </ion-infinite-scroll>
          }
        </div>
      </lib-section-divider>
    }

    @if (!patients.length || !filteredPatients.length) {
      <lib-auto-layout class="no-results" direction="vertical" [padding]="[1, 0]">
        <lib-notification-box-component
          [icon]="'address-card'"
          [message]="'shared.patients.no-patients-found' | translate">
        </lib-notification-box-component>
      </lib-auto-layout>
    }
  } @else {
    <div class="list-skeleton">
      @for (i of maxSkeletonAmountAsArray; track $index) {
        <ng-container *ngTemplateOutlet="skeletonItem"></ng-container>
      }
    </div>
  }
</lib-card-list>

<ng-template #skeletonItem>
  <div class="item-skeleton">
    <lib-auto-layout class="accordion-item-header" [gap]="[10]" direction="horizontal">
      <ion-skeleton-text class="rounded-small image" animated></ion-skeleton-text>
      <p class="stretch-flex">
        <ion-skeleton-text class="rounded-small like-h2" animated></ion-skeleton-text>
      </p>
      <p class="birthday stretch-flex ion-hide-md-down">
        <ion-skeleton-text class="rounded-small like-h2" animated></ion-skeleton-text>
      </p>
    </lib-auto-layout>
  </div>
</ng-template>
