import { HealthEngineVersion } from '../models/about.model';

export class EncoderUtils {
  private static isEncodingEnabled: boolean = false;
  private static readonly textEncoder = new TextEncoder();

  public static initialize(apiVersion: HealthEngineVersion): void {
    EncoderUtils.isEncodingEnabled =
      apiVersion.majorVersion > 24 ||
      (apiVersion.majorVersion === 24 && apiVersion.minorVersion >= 4);
  }

  private static bytesToBase64(bytes: Uint8Array): string {
    const binString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join('');
    return btoa(binString);
  }

  public static encodeBase64(data: string): string {
    if (!EncoderUtils.isEncodingEnabled || data === null || data === '') {
      return data;
    }
    return 'base64{' + this.bytesToBase64(this.textEncoder.encode(data)) + '}';
  }
}
