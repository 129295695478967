import { Component, HostBinding, Inject, Input, ViewChild } from '@angular/core';
import { faShuffle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { IonModal } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { APP_NAME } from 'projects/core/src/lib/injection-tokens';
import { AppName } from 'projects/core/src/lib/models/app.model';
import { Patient } from 'projects/core/src/lib/models/patient.model';
import { Profile } from 'projects/core/src/lib/models/profile.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { ProfileSettingsService } from 'projects/core/src/lib/services/profile-settings.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-context-switch',
  templateUrl: './context-switch.component.html',
  styleUrls: ['./context-switch.component.scss'],
})
export class ContextSwitchComponent {
  @ViewChild('patientSelectionModal') patientSelectionModal: IonModal;
  @Input() isMenuExpanded: boolean;
  @Input() patients: Patient[];
  @Input() currentPatient: Patient;
  @Input() currentDoctor: Profile;
  @Input({ required: true }) isLoading: boolean;

  @HostBinding('class.no-hover') noHoverClass = false;

  public icons = {
    changePatient: faShuffle,
    close: faXmark,
  };

  constructor(
    public readonly breakpoint: BreakpointService,
    private readonly loadingService: LoadingService,
    private readonly profileSettings: ProfileSettingsService,
    private readonly translateService: TranslateService,
    @Inject(APP_NAME) public readonly appName: AppName,
  ) {
    this.noHoverClass = appName == 'm-p';
  }

  public async openPatientSelectionModal(): Promise<void> {
    if (this.patients?.length > 1) {
      await this.patientSelectionModal.present();
    }
  }

  async closePatientSelectionModal(): Promise<void> {
    await this.patientSelectionModal.dismiss();
  }

  async switchPatientContext(patient: Patient): Promise<void> {
    this.profileSettings.switchPatient(patient.patientID);
    await this.closePatientSelectionModal();
    await this.loadingService.load(
      await firstValueFrom(
        this.translateService.get('theme.context-switch.changing-patient-to', {
          patientFullName: patient.getFullName(),
        }),
      ),
    );
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  get isExpandedContextSwitch(): boolean {
    return this.breakpoint.isAbove('xl') || this.isMenuExpanded;
  }
}
