import { Injectable } from '@angular/core';
import MiniSearch, { SearchOptions, SearchResult } from 'minisearch';
import { SearchParams } from '../models/shared.model';
import { SearchFormattingService } from './search-formatting.service';

@Injectable()
export class ItemSearcherService {
  private static readonly defaultFieldIdentifier: string = 'id';

  constructor(private readonly searchFormattingService: SearchFormattingService) {}

  public static searchItems<T>(
    searchHandler: MiniSearch<T>,
    initialList: T[],
    searchParams: SearchParams,
    idField: string = ItemSearcherService.defaultFieldIdentifier,
  ): T[] {
    const searchResult: SearchResult[] = ItemSearcherService.performSearchWithParameter<T>(
      searchHandler,
      initialList,
      searchParams,
    );
    return ItemSearcherService.mapSearchResultsToInitialList<T>(searchResult, initialList, idField);
  }

  public static performSearchWithParameter<T>(
    searchHandler: MiniSearch,
    initialList: T[],
    searchParams: SearchParams,
  ): SearchResult[] {
    searchHandler.removeAll();
    searchHandler.addAll(initialList);

    return searchHandler.search(searchParams.criterions, this.searchOptions);
  }

  public configureMiniSearch<T>(
    searchParams: SearchParams,
    idField: string = ItemSearcherService.defaultFieldIdentifier,
  ): MiniSearch<T> {
    return new MiniSearch({
      fields: searchParams.fields,
      storeFields: [idField],
      idField: idField,
      extractField: (item: T, fieldName: string) => this.extractFieldValue<T>(item, fieldName),
      processTerm: (term: string, fieldName?: string) => this.processTermValue(term, fieldName),
    });
  }

  private static get searchOptions(): SearchOptions {
    return {
      prefix: true,
      fuzzy: 0.1,
      combineWith: 'AND',
    };
  }

  private static mapSearchResultsToInitialList<T>(
    searchedList: SearchResult[],
    initialList: T[],
    idField: string = ItemSearcherService.defaultFieldIdentifier,
  ): T[] {
    return searchedList.map((searchedItem: SearchResult) =>
      initialList.find((initialItem: T) => initialItem[idField as keyof T] === searchedItem.id),
    );
  }

  private extractFieldValue<T>(item: T, name: string): any {
    const value: any = ItemSearcherService.getNestedPropertyValue<T>(item, name);

    if (
      SearchFormattingService.isEmptyValue(value) ||
      name === ItemSearcherService.defaultFieldIdentifier
    ) {
      return value;
    }

    return this.searchFormattingService.formattedField(name, value);
  }

  private processTermValue(term: string, fieldName: string): any {
    if (fieldName) {
      return SearchFormattingService.generateSearchableTermSuffixes(term, 2);
    }

    return SearchFormattingService.normalizeStringValue(term);
  }

  private static getNestedPropertyValue<T>(object: T, propertyPath: string): any {
    return propertyPath
      .split('.')
      .reduce(
        (currentObject: T, propertyKey: string) => currentObject && currentObject[propertyKey],
        object,
      );
  }
}
