import {
  LaboratoryResultGroup,
  LaboratoryResults,
  LaboratoryResultTable,
  LaboratoryResultTableCell,
  LaboratoryResultTableColumn,
  LaboratoryResultTableRow,
  ObservationResultColumnType,
  ObservationResultFlagsResource,
  ObservationResultGroupsResource,
  PatientObservationResultViewResource,
} from 'projects/core/src/lib/models/laboratory-results.model';
import { arrayMove } from 'projects/core/src/lib/utils/array.utils';
import {
  GraphAxisConfiguration,
  GraphDataSet,
  GraphXAxisDetails,
  YAxisRange,
} from '../models/graph.model';

export class LaboratoryResultsMapper {
  public static mapResponse(resource: PatientObservationResultViewResource): LaboratoryResults {
    const laboratoryResults: LaboratoryResults = new LaboratoryResults();
    const flagLookup: Map<string, ObservationResultFlagsResource> =
      this.constructCellFlagLookup(resource);
    laboratoryResults.chartVisible = resource.observationResultView.chartVisible;
    laboratoryResults.groups = resource.observationResultView.groups.map(
      (group: ObservationResultGroupsResource) => {
        const mappedGroup = new LaboratoryResultGroup();
        mappedGroup.name = group.groupName;
        mappedGroup.tableData = new LaboratoryResultTable();

        this.addTableEmptyRows(group, mappedGroup);
        this.fillFixedColumns(group, mappedGroup);
        this.fillValueColumns(group, mappedGroup, flagLookup);
        this.sortTableColumns(resource.observationResultView.sortAscending, mappedGroup);

        return mappedGroup;
      },
    );
    laboratoryResults.groups = this.filterEmptyGroups(laboratoryResults);

    return laboratoryResults;
  }

  private static fillFixedColumns(
    group: ObservationResultGroupsResource,
    mappedGroup: LaboratoryResultGroup,
  ): void {
    group.descriptionColumns.forEach((column, columnIndex) => {
      mappedGroup.tableData.fixedColumns.push(
        new LaboratoryResultTableColumn(
          column.description,
          column.type,
          column.tooltip,
          true,
          columnIndex,
        ),
      );
      column.values.forEach((value, valueIndex) => {
        mappedGroup.tableData.rows[valueIndex].fixedCells.push(
          new LaboratoryResultTableCell(value, columnIndex),
        );
      });
    });
  }

  private static fillValueColumns(
    group: ObservationResultGroupsResource,
    mappedGroup: LaboratoryResultGroup,
    flagLookup: Map<string, ObservationResultFlagsResource>,
  ): void {
    group.valueColumns.forEach((column, columnIndex) => {
      mappedGroup.tableData.columns.push(
        new LaboratoryResultTableColumn(
          column.description,
          ObservationResultColumnType.TEXT,
          column.tooltip,
          false,
          columnIndex,
          column.observationDateTime,
        ),
      );
      column.values.forEach((value, valueIndex) => {
        mappedGroup.tableData.rows[valueIndex].cells.push(
          new LaboratoryResultTableCell(
            value.value,
            columnIndex,
            flagLookup,
            value.flag,
            value.notes,
          ),
        );
      });
    });
  }

  private static constructCellFlagLookup(
    resource: PatientObservationResultViewResource,
  ): Map<string, ObservationResultFlagsResource> {
    const flagLookup = new Map<string, ObservationResultFlagsResource>();
    resource.observationResultView.flags.forEach((item: ObservationResultFlagsResource) => {
      flagLookup.set(item.flag, item);
    });

    return flagLookup;
  }

  private static addTableEmptyRows(
    group: ObservationResultGroupsResource,
    mappedGroup: LaboratoryResultGroup,
  ): void {
    if (group.descriptionColumns?.length > 0) {
      group.descriptionColumns[0].values.forEach(() =>
        mappedGroup.tableData.rows.push(new LaboratoryResultTableRow()),
      );
    } else if (group.valueColumns?.length > 0) {
      group.descriptionColumns[0].values.forEach(() =>
        mappedGroup.tableData.rows.push(new LaboratoryResultTableRow()),
      );
    }
  }

  private static sortTableColumns(
    sortAscending: boolean,
    mappedGroup: LaboratoryResultGroup,
  ): void {
    if (sortAscending) {
      mappedGroup.tableData.columns.sort((a, b) => (a.name > b.name ? 1 : -1));
      mappedGroup.tableData.rows.forEach((row) => {
        for (const cell of row.cells) {
          arrayMove(
            row.cells,
            row.cells.indexOf(cell),
            mappedGroup.tableData.columns.findIndex(
              (column) => column.originalSortIndex === cell.originalSortIndex,
            ),
          );
        }
      });
    } else {
      mappedGroup.tableData.columns.reverse();
      mappedGroup.tableData.rows.forEach((row) => row.cells.reverse());
    }
  }

  public static constructModalTitle(cells: LaboratoryResultTableCell[]): string {
    return cells
      .map((cell: LaboratoryResultTableCell) => cell.value)
      .filter((value: string) => value)
      .join(', ');
  }

  public static mapTableDataRowForGraphView(
    header: LaboratoryResultTableColumn[],
    dataCells: LaboratoryResultTableCell[],
    fixedColumns: LaboratoryResultTableColumn[],
    fixedCells: LaboratoryResultTableCell[],
  ): GraphDataSet {
    const reversedHeader: LaboratoryResultTableColumn[] = [...header].reverse();
    const reversedDataCells: LaboratoryResultTableCell[] = [...dataCells].reverse();
    const graphData: GraphDataSet = this.mapAndFilterGraphData(reversedHeader, reversedDataCells);

    return {
      xAxis: graphData.xAxis,
      yAxis: graphData.yAxis,
      yAxisConfiguration: this.constructYAxisConfiguration(fixedColumns, fixedCells),
    };
  }

  private static mapAndFilterGraphData(
    header: LaboratoryResultTableColumn[],
    dataCells: LaboratoryResultTableCell[],
  ): GraphDataSet {
    const xAxis: GraphXAxisDetails[] = [];
    const yAxis: number[] = [];

    header.forEach((cell: LaboratoryResultTableColumn, index: number) => {
      const xValue: GraphXAxisDetails = this.mapHeaderCellData(cell);
      const yValue: number = this.resolveDataCellValue(dataCells[index].value);

      if (yValue && !isNaN(yValue)) {
        xAxis.push(xValue);
        yAxis.push(yValue);
      }
    });

    return { xAxis, yAxis };
  }

  private static mapHeaderCellData(cell: LaboratoryResultTableColumn): GraphXAxisDetails {
    const formattedTooltip: string = cell?.tooltip.replace(/(\r\n|\n|\r)/g, '<br/>');
    return { value: new Date(cell.observationDateTime).toISOString(), tooltip: formattedTooltip };
  }

  private static resolveDataCellValue(value: string): number | null {
    if (value) {
      return this.extractNumberValueFromString(value);
    }

    return null;
  }

  private static extractNumberValueFromString(value: string): number {
    const regex: RegExp = /-?\d+(\.\d+)?/;
    const match: RegExpExecArray = regex.exec(value);

    return match ? parseFloat(match[0]) : NaN;
  }

  private static constructYAxisConfiguration(
    fixedColumns: LaboratoryResultTableColumn[],
    fixedCells: LaboratoryResultTableCell[],
  ): GraphAxisConfiguration {
    return {
      range: this.resolveYAxisRangeReferences(fixedColumns, fixedCells),
      name: this.resolveYAxisName(fixedColumns, fixedCells),
    };
  }

  private static resolveYAxisRangeReferences(
    fixedColumns: LaboratoryResultTableColumn[],
    fixedCells: LaboratoryResultTableCell[],
  ): YAxisRange | null {
    const columnIndex: number = this.findFixedColumnIndexByType(
      fixedColumns,
      ObservationResultColumnType.RANGE,
    );
    if (columnIndex !== null) {
      const rangeValuesArray: string[] = this.extractRangeValuesArray(fixedCells, columnIndex);
      return this.parseRangeValues(rangeValuesArray);
    }

    return null;
  }

  private static parseRangeValues(rangeValuesArray: string[]): YAxisRange | null {
    if (!rangeValuesArray) {
      return null;
    }
    const [min, max] = rangeValuesArray.map(parseFloat);

    return isNaN(min) || isNaN(max) ? null : { min, max };
  }

  private static extractRangeValuesArray(
    fixedCells: LaboratoryResultTableCell[],
    fixedColumnIndex: number,
  ): string[] | null {
    const rangeValues: string = fixedCells[fixedColumnIndex].value;
    if (!rangeValues) {
      return null;
    }
    const rangeValuesArray: string[] = rangeValues.split('-');

    return rangeValuesArray?.length === 2 ? rangeValuesArray : null;
  }

  private static resolveYAxisName(
    fixedColumns: LaboratoryResultTableColumn[],
    fixedCells: LaboratoryResultTableCell[],
  ): string | null {
    const columnIndex: number = this.findFixedColumnIndexByType(
      fixedColumns,
      ObservationResultColumnType.UNIT,
    );
    if (columnIndex === null) {
      return null;
    }
    return fixedCells[columnIndex].value;
  }

  private static findFixedColumnIndexByType(
    fixedColumns: LaboratoryResultTableColumn[],
    type: ObservationResultColumnType,
  ): number | null {
    const fixedColumnIndex: number = fixedColumns.findIndex(
      (fixedColumn: LaboratoryResultTableColumn) => fixedColumn.type === type,
    );
    if (fixedColumnIndex === -1) {
      return null;
    }

    return fixedColumnIndex;
  }

  private static filterEmptyGroups(laboratoryResults: LaboratoryResults): LaboratoryResultGroup[] {
    return laboratoryResults.groups.filter((group: LaboratoryResultGroup) =>
      this.hasGroupData(group),
    );
  }

  private static hasGroupData(group: LaboratoryResultGroup): boolean {
    return !!group?.tableData.rows?.length;
  }
}
