import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { OverlayEventDetail } from '@ionic/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { InvokerBody } from 'projects/core/src/lib/models/invoker-body.model';
import { MedicalCertificatesWidgetRow } from 'projects/core/src/lib/models/medical-certificates.model';
import {
  ExpenseTranslationKeys,
  ModalEvent,
  ModalResult,
} from 'projects/core/src/lib/models/modal-action.model';
import { BreakpointService } from 'projects/core/src/lib/services/breakpoint.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { MedicalCertificatesService } from 'projects/core/src/lib/services/medical-certificates.service';
import { ModalActionService } from 'projects/core/src/lib/services/modal-action.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-medical-certificate-row',
  templateUrl: './medical-certificate-row.component.html',
  styleUrls: ['./medical-certificate-row.component.scss'],
})
@UntilDestroy()
export class MedicalCertificateRowComponent implements OnInit {
  @Output() listReload = new EventEmitter<void>();

  @Input({ required: true }) medicalCertificate: MedicalCertificatesWidgetRow;
  @Input({ required: false }) hasExtendedView = true;
  @Input({ required: false }) hasNavigationIcon = true;

  icons = {
    notesMedical: faNotesMedical,
  };

  translations: any;

  constructor(
    private breakPointService: BreakpointService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private modalActionService: ModalActionService,
    private medicalCertificateService: MedicalCertificatesService,
  ) {}
  async ngOnInit(): Promise<void> {
    this.translations = await firstValueFrom(
      this.translateService.get('shared.medical-certificates'),
    );
  }

  async viewMedicalCertifciate(expense: MedicalCertificatesWidgetRow): Promise<void> {
    if (!expense.behaviourInvokers?.length) {
      return;
    }
    await this.loadAndShowMedicalCertificateDetails(expense.id, expense.behaviourInvokers);
  }

  private async loadAndShowMedicalCertificateDetails(
    expenseId: string,
    behaviorInvokers?: InvokerBody[],
  ): Promise<void> {
    await this.loadingService.load(this.translations['fetching-medical-certificate-details']);
    try {
      await this.showExpenseDetails(expenseId, behaviorInvokers);
    } catch (error) {
      console.error('Failed to show expense details', error);
      await this.loadingService.stop();
    }
  }

  private async showExpenseDetails(
    medicalCertificateId: string,
    behaviorInvokers?: InvokerBody[],
  ): Promise<void> {
    const result: OverlayEventDetail = await firstValueFrom(
      this.modalActionService.showDynamicViewWithActions(
        medicalCertificateId,
        behaviorInvokers,
        this.medicalCertificateService.previewFallbackMethods,
      ),
    );
    if (this.modalActionService.isActionTriggeringEvent(result)) {
      await this.handleModalActionEvent(result, medicalCertificateId, behaviorInvokers);
    }
  }

  private async handleModalActionEvent(
    event: OverlayEventDetail,
    medicalCertificateId: string,
    behaviorInvokers?: InvokerBody[],
  ): Promise<void> {
    const modalResult: ModalResult =
      await this.modalActionService.handleInvokerMethodOfActionButton(
        event,
        ExpenseTranslationKeys,
      );
    if (this.modalActionService.shouldReloadDataSet(modalResult)) {
      this.reloadList();
    }
    if (modalResult.event !== ModalEvent.stateChange) {
      await this.loadAndShowMedicalCertificateDetails(medicalCertificateId, behaviorInvokers);
    }
  }

  private reloadList(): void {
    this.listReload.emit();
  }

  get isExtendedView(): boolean {
    return this.hasExtendedView && this.breakPointService.isAbove('sm');
  }
}
