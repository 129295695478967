import { Component, Input } from '@angular/core';
import {
  LaboratoryResultTable,
  LaboratoryResultTableColumn,
} from 'projects/core/src/lib/models/laboratory-results.model';
import { SortDirection } from 'projects/core/src/lib/models/shared.model';

@Component({
  selector: 'lib-laboratory-results-vertical-sorting',
  templateUrl: './laboratory-results-vertical-sorting.component.html',
  styleUrls: [
    '../../../../data-organizer-toolbox/vertical-sorting-section/vertical-sorting-section.component.scss',
    '../../../../data-organizer-toolbox/data-organizer-toolbox.scss',
  ],
})
export class LaboratoryResultsVerticalSortingComponent {
  @Input({ required: true }) tableData: LaboratoryResultTable;

  readonly directionType: typeof SortDirection = SortDirection;

  setSortingMethod(direction: SortDirection): void {
    if (this.tableData.sortParams.direction !== direction) {
      this.tableData.sortParams.direction = direction;
      this.tableData.sortColumns(this.tableData.sortParams.identifier, direction);
    }
  }

  setSortingReference(item: LaboratoryResultTableColumn): void {
    const itemSortIndex: string = item.originalSortIndex.toString();
    if (this.tableData.sortParams.identifier !== itemSortIndex) {
      this.tableData.updateSortParams(itemSortIndex);
      this.tableData.sortColumns(itemSortIndex, this.tableData.sortParams.direction);
    }
  }

  getClassNameOfSortingMethod(direction: SortDirection): string {
    return this.tableData.sortParams.direction === direction ? 'active' : null;
  }

  getClassNameOfSortingReference(item: LaboratoryResultTableColumn): string {
    const isActiveReference: boolean =
      this.tableData.sortParams.identifier === item.originalSortIndex.toString();
    return isActiveReference ? 'active' : null;
  }
}
