<lib-auto-layout class="toolbar" [gap]="[20]" vertical="center" [class.card-item]="isCard">
  <lib-search-input
    [searchParams]="data.searchParams"
    (searchParamsChanged)="updateTableAfterSearch()"
    (inputChanged)="showLoading()"
    [disabled]="isLoading"
    [grow]="1"
    [type]="searchLayoutType">
    <lib-data-organizer-trigger-button
      filterButton
      [isSearchActive]="isSearchActive"
      [table]="data.table"
      [rowState]="rowState"
      [isLoading]="isLoading">
    </lib-data-organizer-trigger-button>
  </lib-search-input>

  <ng-content select="[toolbarButtonCollection]"></ng-content>
</lib-auto-layout>
